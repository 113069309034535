import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ButtonIcon from '../../../../../components/ButtonIcon';
import { apiRoutes } from '../../../../../constants/api-routes';
import { appPaths } from '../../../../../constants/app-paths';
import { colors } from '../../../../../constants/colors';
import {
  AutomationsService,
  ListAutomationTypeItem,
} from '../../../../../services/automations.service';
import { SourceIntegrationLabelsRoutesAndImages } from '../../../../../types/source-integration-labels-routes-and-images';
import ThreeDImgCard from '../3DImgCard';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useMemo, useState } from 'react';

const GridAutomations = () => {
  const excludedIntegrationKeys = new Set([
    'bling',
    'custom',
    'direct_message',
    'file_import',
    'google_tag_manager',
    'hubspot_crm',
    'unknown',
    'magazord',
    'magento1_ecommerce',
    'magento2_ecommerce',
  ]);

  const [selectedSourceIntegration, setSelectedSourceIntegration] =
    useState<string>('');
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: automationTypes } = useQuery(
    apiRoutes.listAutomationTypes(),
    async () => {
      const { data } = await AutomationsService.listAutomationTypes({
        justWithSourceIntegrations: true,
        withAutomations: true,
        orderBy: 'sourceIntegration',
        orderDirection: 'asc',
      });
      return data.filter(
        (automationType: ListAutomationTypeItem) =>
          automationType.sourceIntegration !== null,
      );
    },
  );
  const filteredAutomationTypes = useMemo(
    () =>
      automationTypes?.filter(
        (automationType) =>
          selectedSourceIntegration === '' ||
          automationType.sourceIntegration === selectedSourceIntegration,
      ),
    [selectedSourceIntegration, automationTypes],
  );

  const toggleAutomationTypeMutation = useMutation(
    async ({
      automationTypeId,
      automationId,
    }: {
      automationTypeId: string;
      automationId: string;
    }) => {
      const { data } = await AutomationsService.toggleAutomation(automationId);

      return { automationTypeId: automationTypeId, isActive: data.isActive };
    },
    {
      onSuccess: (data) => {
        toast({
          title: 'Automação atualizada com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        queryClient.setQueryData(
          apiRoutes.listAutomationTypes(),
          (old: any) => {
            return old?.map((automationType: any) => {
              if (automationType.id === data.automationTypeId) {
                automationType.automations[0].isActive = data.isActive;
              }
              return automationType;
            });
          },
        );
      },
    },
  );

  const getAutomationDetails = (automationType: ListAutomationTypeItem) => {
    return {
      hasAutomation: automationType.automations?.length > 0,
      isAutomationActive:
        automationType.automations?.filter((element) => element.isActive)
          .length > 0,
    };
  };

  const changeAutomationStatus = async (
    automationType: ListAutomationTypeItem,
  ) => {
    await toggleAutomationTypeMutation.mutateAsync({
      automationTypeId: automationType.id,
      automationId: automationType.automations[0].id,
    });
  };

  const handleCreateAutomation = (automationTypeId: string) => {
    navigate(
      appPaths.automations.backgroundAutomations.createAutomationByType(
        automationTypeId,
      ),
    );
  };

  const handleEditAutomation = (
    automationTypeId: string,
    automationId: string,
  ) => {
    navigate(
      appPaths.automations.backgroundAutomations.editAutomationByType(
        automationTypeId,
        automationId,
      ),
    );
  };

  const handleSourceIntegrationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSourceIntegration(event.target.value);
  };

  return (
    <>
      <Box mb={4}>
        <Box
          as="select"
          onChange={handleSourceIntegrationChange}
          border="1px"
          borderColor="gray.300"
          borderRadius="md"
          p={2}
          width="100%"
        >
          <option value="">Todos</option>
          {Object.entries(SourceIntegrationLabelsRoutesAndImages)
            .filter(([key]) => !excludedIntegrationKeys.has(key))
            .map(([key, value]) => (
              <option key={key} value={key}>
                {value.label}
              </option>
            ))}
        </Box>
      </Box>
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {filteredAutomationTypes?.map((automationType) => {
          const { hasAutomation, isAutomationActive } =
            getAutomationDetails(automationType);
          const sourceIntegration =
            SourceIntegrationLabelsRoutesAndImages[
              automationType.sourceIntegration ?? 'unknown'
            ];
          return (
            <GridItem
              key={automationType.id}
              p={5}
              shadow="md"
              borderWidth="1px"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box flex="1">
                  <Box fontWeight="bold">{automationType.name}</Box>
                  <Box>{sourceIntegration.label}</Box>
                </Box>
                <ThreeDImgCard
                  onClick={() =>
                    hasAutomation
                      ? handleEditAutomation(
                          automationType.id,
                          automationType.automations[0].id,
                        )
                      : handleCreateAutomation(automationType.id)
                  }
                  imgSource={`${process.env.PUBLIC_URL}/${sourceIntegration.image}`}
                />
              </Box>
              <Divider marginY={5} />
              {!hasAutomation ? (
                <Box textAlign={'left'}>
                  <Button
                    variant="primary"
                    onClick={handleCreateAutomation.bind(
                      null,
                      automationType.id,
                    )}
                  >
                    + criar
                  </Button>
                </Box>
              ) : (
                <Box
                  display="flex"
                  paddingTop={2}
                  justifyContent="center"
                  alignItems="center"
                  height="40px"
                >
                  <Box textAlign="center">
                    <ButtonIcon
                      tooltipLabel="Ver resultados"
                      icon={
                        <MdOutlineRemoveRedEye
                          color={colors.primaryLight}
                          size={20}
                        />
                      }
                      onClick={() =>
                        navigate(
                          appPaths.automations.backgroundAutomations.details(
                            automationType.automations[0].id,
                          ),
                        )
                      }
                    />
                  </Box>
                  <Box textAlign={'center'}>
                    <ButtonIcon
                      tooltipLabel="Editar"
                      icon={<FaEdit color={colors.primaryLight} size={18} />}
                      onClick={handleEditAutomation.bind(
                        null,
                        automationType.id,
                        automationType.automations[0].id,
                      )}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Switch
                      isChecked={isAutomationActive}
                      isDisabled={!hasAutomation}
                      colorScheme="green"
                      onChange={changeAutomationStatus.bind(
                        null,
                        automationType,
                      )}
                    />
                  </Box>
                </Box>
              )}
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};

export default GridAutomations;
