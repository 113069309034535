import { Box } from "@chakra-ui/react";
import { MouseEvent, useState } from "react";

interface ThreeDImgCardProps {
  imgSource: string;
  onClick?: () => void;
}

function ThreeDImgCard({ onClick, imgSource }: ThreeDImgCardProps) {
  const [transform, setTransform] = useState<string>("rotateX(0deg) rotateY(0deg)");

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = target;
    const x = e.clientX - offsetLeft - offsetWidth / 2;
    const y = e.clientY - offsetTop - offsetHeight / 2;

    const rotateX = -(y / offsetHeight) * 8; // Ajuste o fator para intensidade
    const rotateY = (x / offsetWidth) * 8;

    setTransform(`rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
  };

  const handleMouseLeave = () => {
    setTransform("rotateX(0deg) rotateY(0deg)");
  };

  return (
    <Box
      bg="gray.100"
      boxShadow="lg"
      borderRadius="lg"
      cursor="pointer"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        perspective: "1000px",
      }}
      _hover={{
        transform: "scale(1.10)",
        transition: "transform 0.2s",
      }}
    >
      <Box
        width="80px"
        height="80px"
        transform={transform}
        transformOrigin="center"
        transition="transform 0.1s"
        borderRadius="lg"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={imgSource}
          alt="Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            display: "block", // Garante que não haverá espaço adicional
          }}
        />
      </Box>
    </Box>
  );
}

export default ThreeDImgCard;