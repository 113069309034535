import { WhatsappValidationUtils } from './../utils/whatsapp-validation.utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationTabsEnum } from '../pages/InboxPage';
import {
  Conversation,
  ListConversationDetailedItem,
  SelectableListConversationDetailedItem,
} from '../types/Conversation';
import { ConversationCategoryDetailed } from '../types/ConversationCategory';
import { ConversationTicketStatus } from '../types/ConversationTicket';
import {
  Message,
  MessageStatus,
  MessageWithCardsIncludes,
} from '../types/Message';
import { RootState } from './store';
import { ConversationSector } from '../types/Prisma';

interface ConversationsIdsByAgentId {
  [key: string]: string[];
}

interface InboxState {
  conversationCategoryId: string | null;
  ticketStatus: ConversationTicketStatus;
  daysSinceLastMessage: number;
  hasUnreadMessages: boolean | null;
  activeTab: string;
  conversations: SelectableListConversationDetailedItem[];
  conversationCategories: ConversationCategoryDetailed[];
  conversationSectors: ConversationSector[];
  messagesByConversationId: Record<string, MessageWithCardsIncludes[]>;
  unreadConversationIds: Record<string, any>;
  openConversationIds: Record<string, any>;
  hasNoAgentConversationIds: Record<string, any>;
  conversationsIdsByAgentId: ConversationsIdsByAgentId;

  conversationIdToCategoryIdMap: Record<string, string | null>;
  byAgentId: string | null;
  hasNoAgentAssigned: boolean;

  isSidebarCollapsed: boolean;
}

const initialState: InboxState = {
  conversationCategoryId: null,
  ticketStatus: 'open',
  daysSinceLastMessage: 7,
  hasUnreadMessages: null,
  activeTab: ConversationTabsEnum.ALL_CONVERSATIONS,
  conversations: [],
  conversationCategories: [],
  conversationSectors: [],
  messagesByConversationId: {},

  unreadConversationIds: {},
  openConversationIds: {},
  hasNoAgentConversationIds: {},
  conversationsIdsByAgentId: {},
  conversationIdToCategoryIdMap: {},

  byAgentId: null,
  hasNoAgentAssigned: false,

  isSidebarCollapsed: false,
};

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    resetInboxState(state) {
      state.conversationCategoryId = null;
      state.ticketStatus = 'open';
      state.daysSinceLastMessage = 7;
      state.hasUnreadMessages = null;
      state.activeTab = ConversationTabsEnum.ALL_CONVERSATIONS;
      state.conversations = [];
      state.conversationCategories = [];
      state.conversationSectors = [];
      state.messagesByConversationId = {};
      state.unreadConversationIds = {};
      state.openConversationIds = {};
      state.hasNoAgentConversationIds = {};
      state.conversationsIdsByAgentId = {};
      state.conversationIdToCategoryIdMap = {};
      state.byAgentId = null;
      state.hasNoAgentAssigned = false;
    },
    readConversation(state, action: PayloadAction<{ conversationId: string }>) {
      const { conversationId } = action.payload;

      delete state.unreadConversationIds[conversationId];
    },
    closeConversationTickets(
      state,
      action: PayloadAction<{
        conversation: Pick<Conversation, 'id' | 'categoryId'>;
      }>,
    ) {
      const {
        conversation: { categoryId, id },
      } = action.payload;

      // update conversation state
      state.conversations = state.conversations.map(
        (conversation): ListConversationDetailedItem => {
          if (conversation.id === id) {
            delete state.openConversationIds[id];
            delete state.conversationIdToCategoryIdMap[id];
            if (conversation.ticketAgentId) {
              state.conversationsIdsByAgentId[conversation.ticketAgentId] = (
                state.conversationsIdsByAgentId[conversation.ticketAgentId] ??
                []
              ).filter((conversationId) => conversationId !== id);
            }
            if (state.hasNoAgentConversationIds[id]) {
              delete state.hasNoAgentConversationIds[id];
            }

            return {
              ...conversation,
              hasOpenTicket: false,
            };
          }
          return conversation;
        },
      );
    },
    updateConversation(
      state,
      action: PayloadAction<{
        currentConversation: Conversation;
        previoustConversation: Conversation;
      }>,
    ) {
      const { currentConversation, previoustConversation } = action.payload;
      state.conversations = state.conversations.map(
        (conversation): ListConversationDetailedItem => {
          if (conversation.id === currentConversation.id) {
            state.conversationIdToCategoryIdMap[currentConversation.id] =
              currentConversation.categoryId;
            return {
              ...conversation,
              recipientName: currentConversation.recipientName,
              categoryId: currentConversation.categoryId,
            };
          }
          return conversation;
        },
      );
    },
    insertConversation(
      state,
      action: PayloadAction<{
        conversation: ListConversationDetailedItem;
      }>,
    ) {
      const { conversation } = action.payload;

      state.conversations = state.conversations.map((item) => {
        if (item.id === conversation.id) {
          state.conversationIdToCategoryIdMap[conversation.id] =
            conversation.categoryId;
          state.openConversationIds[conversation.id] = true;

          if (state.byAgentId !== conversation.ticketAgentId) {
            return item;
          }

          return conversation;
        }
        return item;
      });

      state.conversations = [
        conversation,
        ...state.conversations.filter((item) => item.id !== conversation.id),
      ];

      state.openConversationIds[conversation.id] = true;

      state.conversations = Object.values(
        state.conversations.reduce(
          (acc, item) => {
            acc[item.id] = item;
            return acc;
          },
          {} as Record<string, ListConversationDetailedItem>,
        ),
      );

      if (!conversation.ticketAgentId) {
        state.hasNoAgentConversationIds[conversation.id] = true;
      }

      if (conversation.ticketAgentId) {
        if (state.conversationsIdsByAgentId[conversation.ticketAgentId]) {
          state.conversationsIdsByAgentId[conversation.ticketAgentId].push(
            conversation.id,
          );
        } else {
          state.conversationsIdsByAgentId[conversation.ticketAgentId] = [
            conversation.id,
          ];
        }
      }
    },
    updateMessageStatusByTempId(
      state,
      action: PayloadAction<{
        conversationId: string;
        tempId: string;
        status: MessageStatus;
      }>,
    ) {
      const { conversationId, tempId, status } = action.payload;
      const messages = state.messagesByConversationId[conversationId] || [];
      const message = messages.find((message) => message.tempId === tempId);
      if (message) {
        message.status = status;
      }
    },
    updateMessageByTempId(
      state,
      action: PayloadAction<{
        conversationId: string;
        tempId: string;
        message: Partial<Message>;
      }>,
    ) {
      const { conversationId, tempId, message } = action.payload;
      const messages = state.messagesByConversationId[conversationId] || [];
      state.messagesByConversationId[conversationId] = messages.map((msg) => {
        if (msg.tempId === tempId) {
          return {
            ...msg,
            ...message,
          };
        }
        return msg;
      });
    },
    updateMessageUploadProgressByTempId(
      state,
      action: PayloadAction<{
        conversationId: string;
        tempId: string;
        uploadProgress: number;
        status?: MessageStatus;
      }>,
    ) {
      const { conversationId, tempId, uploadProgress } = action.payload;
      const messages = state.messagesByConversationId[conversationId] || [];
      const message = messages.find((message) => message.tempId === tempId);
      if (message) {
        message.uploadProgress = uploadProgress;
        if (action.payload.status) {
          message.status = action.payload.status;
        }
      }
      state.messagesByConversationId[conversationId] = messages.map((msg) =>
        msg.tempId === tempId ? { ...msg, uploadProgress } : msg,
      );
    },
    addMessagesToConversation(
      state,
      action: PayloadAction<{
        conversationId: string;
        messages: MessageWithCardsIncludes[];
      }>,
    ) {
      const { conversationId, messages } = action.payload;
      // new message
      if (messages.length === 1) {
        if (messages[0].fromSystem === false && messages[0].status !== 'read') {
          state.unreadConversationIds[conversationId] = true;
        }
      }

      const messagesSortedDesc = [
        ...(state.messagesByConversationId[conversationId] || []),
        ...messages,
      ].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      const messagesById = messagesSortedDesc.reduce(
        (acc, message) => {
          acc[message.tempId || message.id] = message;
          return acc;
        },
        {} as Record<string, MessageWithCardsIncludes>,
      );
      state.messagesByConversationId[conversationId] =
        Object.values(messagesById);

      const conversation = state.conversations.find(
        (conversation) => conversation.id === conversationId,
      );
      if (!conversation) {
        return;
      }
      // update most recent message on conversation list
      const lastMessage = messagesSortedDesc[0];

      // if the last message is different from the one in the conversation list
      // update the conversation list order
      if (conversation.lastMessage?.id !== lastMessage.id) {
        conversation.lastMessage = {
          id: lastMessage.id,
          tempId: lastMessage.tempId,
          createdAt: lastMessage.createdAt,
          text: lastMessage.text,
          fromSystem: lastMessage.fromSystem,
          status: lastMessage.status,
        };

        state.conversations = state.conversations.filter(
          (conversation) => conversation.id !== conversationId,
        );
        state.conversations = [conversation, ...state.conversations];
      }
    },
    setConversations(
      state,
      action: PayloadAction<SelectableListConversationDetailedItem[]>,
    ) {
      state.conversations = action.payload;
    },
    addConversations(
      state,
      action: PayloadAction<SelectableListConversationDetailedItem[]>,
    ) {
      // remove duplicated by id
      const newConversations = action.payload.filter(
        (conversation) =>
          !state.conversations.find(
            (existingConversation) =>
              existingConversation.id === conversation.id,
          ),
      );
      state.conversations = [...state.conversations, ...newConversations];
    },
    setConversationCategories(
      state,
      action: PayloadAction<ConversationCategoryDetailed[]>,
    ) {
      state.conversationCategories = action.payload;
    },
    setConversationSectors(state, action: PayloadAction<ConversationSector[]>) {
      state.conversationSectors = action.payload;
    },
    setConversationCategoryId(state, action: PayloadAction<string | null>) {
      state.conversationCategoryId = action.payload;
    },
    setTicketStatus(state, action: PayloadAction<ConversationTicketStatus>) {
      state.ticketStatus = action.payload;
    },
    setDaysSinceLastMessage(state, action: PayloadAction<number>) {
      state.daysSinceLastMessage = action.payload;
    },
    setHasUnreadMessages(state, action: PayloadAction<boolean | null>) {
      state.hasUnreadMessages = action.payload;
    },
    setUnreadConverstionIds(state, action: PayloadAction<string[]>) {
      state.unreadConversationIds = action.payload.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
    },
    setOpenConversationIds(state, action: PayloadAction<string[]>) {
      state.openConversationIds = action.payload.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
    },
    setHasNoAgentConversationIds(state, action: PayloadAction<string[]>) {
      state.hasNoAgentConversationIds = action.payload.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
    },
    setConversationsIdsByAgentId(
      state,
      action: PayloadAction<{ id: string; agentId: string }[]>,
    ) {
      state.conversationsIdsByAgentId = action.payload.reduce(
        (acc, conversation) => {
          if (conversation.agentId) {
            if (acc[conversation.agentId]) {
              acc[conversation.agentId].push(conversation.id!);
            } else {
              acc[conversation.agentId] = [conversation.id!];
            }
          }
          return acc;
        },
        {} as ConversationsIdsByAgentId,
      );
    },
    setConversationIdToCategoryIdMap(
      state,
      action: PayloadAction<
        {
          id: string;
          categoryId: string;
        }[]
      >,
    ) {
      state.conversationIdToCategoryIdMap = action.payload.reduce(
        (acc, conversation) => {
          acc[conversation.id] = conversation.categoryId;
          return acc;
        },
        {} as Record<string, string>,
      );
    },
    changeActiveTab(
      state,
      action: PayloadAction<{
        tab:
          | 'not-read'
          | 'all-conversations'
          | 'agent-conversations'
          | 'no-assignment'
          | 'conversation-category';
        conversationCategoryId: string | null;
        agentId?: string;
        agentName?: string;
      }>,
    ) {
      const {
        tab,
        conversationCategoryId,
        agentId = null,
        agentName = null,
      } = action.payload;

      function resetFilters() {
        state.conversationCategoryId = null;
        state.hasUnreadMessages = null;
        state.byAgentId = null;
        state.hasNoAgentAssigned = false;
      }

      switch (tab) {
        case 'not-read':
          resetFilters();
          state.hasUnreadMessages = true;
          state.activeTab = ConversationTabsEnum.NOT_READ;
          break;
        case 'all-conversations':
          resetFilters();
          state.activeTab = ConversationTabsEnum.ALL_CONVERSATIONS;
          break;
        case 'agent-conversations':
          resetFilters();
          state.byAgentId = agentId;
          state.activeTab = agentName
            ? agentName
            : ConversationTabsEnum.MY_CONVERSATIONS;
          break;
        case 'no-assignment':
          resetFilters();
          state.hasNoAgentAssigned = true;
          state.activeTab = ConversationTabsEnum.NO_ASSIGNMENT;
          break;
        case 'conversation-category':
          resetFilters();
          state.conversationCategoryId = conversationCategoryId;

          const category = state.conversationCategories.find(
            (category) => category.id === conversationCategoryId,
          );
          state.activeTab =
            category?.name || ConversationTabsEnum.ALL_CONVERSATIONS;
          break;
      }
    },
    updateTicketAgentIdForConversation(
      state,
      action: PayloadAction<{
        conversationId: string;
        agentId: string;
        oldAgentId: string | null;
      }>,
    ) {
      const { conversationId, agentId, oldAgentId } = action.payload;
      if (state.hasNoAgentConversationIds[conversationId]) {
        delete state.hasNoAgentConversationIds[conversationId];
      }
      state.conversations = state.conversations.map(
        (conversation): ListConversationDetailedItem => {
          if (conversation.id === conversationId) {
            return {
              ...conversation,
              ticketAgentId: agentId,
            };
          }
          return conversation;
        },
      );
      if (oldAgentId && state.conversationsIdsByAgentId[oldAgentId]) {
        state.conversationsIdsByAgentId[oldAgentId] =
          state.conversationsIdsByAgentId[oldAgentId].filter(
            (id) => id !== conversationId,
          );
      }
      if (state.conversationsIdsByAgentId[agentId]) {
        state.conversationsIdsByAgentId[agentId].push(conversationId);
      } else {
        state.conversationsIdsByAgentId[agentId] = [conversationId];
      }
    },
    markConversationAsUnread(
      state,
      action: PayloadAction<{ conversationId: string }>,
    ) {
      const { conversationId } = action.payload;
      state.unreadConversationIds[conversationId] = true;
    },

    toggleSidebar: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
    setSidebarState: (state, action) => {
      state.isSidebarCollapsed = action.payload;
    },
  },
});

export const getConversationById = (
  state: RootState,
  conversationId: string,
) => {
  return state.inbox.conversations.find((conv) => conv.id === conversationId);
};

export const getMessagesByConversationId = (
  state: RootState,
  conversationId: string,
) => {
  return state.inbox.messagesByConversationId[conversationId] || [];
};

export const getMessageByTempIdAndConversationId = (
  state: RootState,
  conversationId: string,
  tempId: string,
) => {
  return getMessagesByConversationId(state, conversationId).find(
    (message) => message.tempId === tempId,
  );
};

export const isConversationSessionActive = (
  state: RootState,
  conversationId: string,
) => {
  const customerMessages = getMessagesByConversationId(
    state,
    conversationId,
  ).filter((message) => message.fromSystem === false);
  const lastReceivedMessage = customerMessages[0];

  if (!lastReceivedMessage) {
    return false;
  }
  return !WhatsappValidationUtils.isSessionExpired(
    new Date(lastReceivedMessage.createdAt),
  );
};

export const isUnreadConversation = (
  state: RootState,
  conversationId: string,
) => {
  return !!state.inbox.unreadConversationIds[conversationId];
};

export const selectSidebarState = (state: RootState) =>
  state.inbox.isSidebarCollapsed;

export const getTotalOpenConversationByCategoryId = (state: RootState) => {
  const res = Object.keys(state.inbox.openConversationIds).reduce(
    (acc, id) => {
      const categoryId = state.inbox.conversationIdToCategoryIdMap[id];
      if (!categoryId) {
        return acc;
      }
      if (acc[categoryId]) {
        acc[categoryId] += 1;
      } else {
        acc[categoryId] = 1;
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  return res;
};

export const {
  changeActiveTab,
  addMessagesToConversation,
  setConversationCategories,
  setConversationSectors,
  setConversations,
  updateMessageStatusByTempId,
  updateMessageUploadProgressByTempId,
  updateMessageByTempId,
  insertConversation,
  updateConversation,
  closeConversationTickets,
  readConversation,
  resetInboxState,
  addConversations,
  updateTicketAgentIdForConversation,
  markConversationAsUnread,

  // counters
  setUnreadConverstionIds,
  setOpenConversationIds,
  setConversationIdToCategoryIdMap,
  setHasNoAgentConversationIds,
  setConversationsIdsByAgentId,

  // filters
  setConversationCategoryId,
  setTicketStatus,
  setDaysSinceLastMessage,
  setHasUnreadMessages,

  //sidenavigation
  toggleSidebar,
  setSidebarState,
} = inboxSlice.actions;

export default inboxSlice.reducer;
