import { useToast } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { apiRoutes } from '../../../../constants/api-routes';
import { appPaths } from '../../../../constants/app-paths';
import { AutomationsService } from '../../../../services/automations.service';
import { Automation } from '../../../../types/Automation';
import AutomationByTypeForm from '../components/AutomationByTypeForm';
import { ListMessageTemplateItem, MessageTemplatesService } from "../../../../services/message-templates.service";
import LoadingScreen from "../../../../components/LoadingScreen";
import { AutomationAction } from "../../../../types/Prisma";
import { IntegrationsService } from '../../../../services/integrations.service';
import IntegrationActiveKeyBySourceIntegration from '../constants/integration-active-key-by-source-integration';
import TopIntegrationInactiveErrorMessage from '../components/TopIntegrationInactiveErrorMessage';
import GlassmorphisContainer from '../../../../components/GlassmorphismContainer';

export interface AutomationByTypeFormValues {
  name: string;
  isActive: boolean;
  messageTemplateId: string | null;
  flowId: string | null;
  dailyMessageLimitOnWhatsapp: number;
  action: AutomationAction;
}

export interface UpdateAutomationFromIntegrationDto {
  name: string;
  isActive: boolean;
  messageTemplateId: string;
  templateArgs?: Record<string, string | null | undefined>;
  isAutomationRepetitionAllowed: boolean;
  automationTypeId: string;
  dailyMessageLimitOnWhatsapp: number;
  flowId: string;
  action: string;
}

const EditBackgroundAutomationByTypePage = () => {
  const { automationId, automationTypeId } = useParams();
  const [automationValues, setAutomationValues] =
    useState<AutomationByTypeFormValues | null>(null);

  function mapAutomation(automation: Automation): AutomationByTypeFormValues {
    return {
      name: automation.name,
      isActive: automation.isActive,
      messageTemplateId: automation.messageTemplateId,
      dailyMessageLimitOnWhatsapp: 100,
      flowId: automation.flowId,
      action: automation.action,
    };
  }

  function processData(data: any) {
    if (data.templateArgs) {
      setTemplateArgs(data.templateArgs);
      mapAutomation;
    }

    return setAutomationValues(mapAutomation(data));
  }

  const { data: automationType, isFetching: isFetchingAutomationType } =
    useQuery(apiRoutes.showAutomationType(automationTypeId!), async () => {
      const { data } = await AutomationsService.showAutomationType(
        automationTypeId!,
      );
      return data;
    });

  const { data: automation, isFetching: isFetchingAutomation } = useQuery(
    apiRoutes.showAutomation(automationId!),
    async () => {
      const { data } = await AutomationsService.showAutomation(automationId!);
      return data;
    },
  );

  const { data: integrationStatus } = useQuery(
    apiRoutes.getIntegrationStatusSummary(),
    async () => {
      const { data } = await IntegrationsService.getIntegrationStatusSummary();
      return data;
    },
  );

  const { data: templates, isFetching: isFetchingMessageTemplates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => template.status === 'approved',
        );
      },
    },
  );
  const filteredTemplates = useMemo(() => {
    if (!templates || !automationType) return [];
    return templates.filter(
      (t) => t.type == automationType!.slug.toUpperCase(),
    );
  }, [automationType, templates]);

  const isIntegrationActive = useMemo(() => {
    if (!integrationStatus || !automationType) return false;
    const integrationActiveKey =
      IntegrationActiveKeyBySourceIntegration[
        automationType.sourceIntegration!
      ];
    return (integrationStatus as Record<string, boolean>)[integrationActiveKey];
  }, [integrationStatus, automationType]);

  const navigate = useNavigate();
  const toast = useToast();

  const updateAutomation = useMutation(
    (updateAutomationDto: UpdateAutomationFromIntegrationDto) =>
      AutomationsService.updateAutomationFromIntegration(
        automationId!,
        updateAutomationDto,
      ),
    {
      onSuccess: (res) => {
        toast({
          title: 'Automação atualizada com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      },
    },
  );

  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  async function onSubmit(data: any) {
    const { name, isActive, messageTemplateId, dailyMessageLimitOnWhatsapp, flowId, action } = data;
    await updateAutomation.mutateAsync({
      name,
      isActive,
      messageTemplateId,
      templateArgs,
      dailyMessageLimitOnWhatsapp,
      automationTypeId: automationTypeId!,
      isAutomationRepetitionAllowed: true,
      flowId,
      action,
    });
  }

  return (
    <LoadingScreen
      isLoading={
        isFetchingAutomationType ||
        isFetchingAutomation ||
        isFetchingMessageTemplates
      }
    >
      <>
        {automationType && !isIntegrationActive && (
          <TopIntegrationInactiveErrorMessage
            sourceIntegration={automationType?.sourceIntegration!}
          />
        )}
        <GlassmorphisContainer show={!isIntegrationActive}>
          <AutomationByTypeForm
            onSubmit={onSubmit}
            templateArgs={templateArgs}
            setTemplateArgs={setTemplateArgs}
            automation={automation}
            automationType={automationType}
            templates={filteredTemplates}
          />
        </GlassmorphisContainer>
      </>
    </LoadingScreen>
  );
};

export default EditBackgroundAutomationByTypePage;
