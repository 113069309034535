import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../constants/api-routes';
import { ConversationSectorsService, CreateConversationSectorDto } from '../../../../services/conversation-sectors.service';
import { ConversationSector } from '../../../../types/Prisma';

const PopoverNewSector = () => {
  const popover = useDisclosure();
  const queryClient = useQueryClient();
  const [newConversationSectorName, setNewConversationSectorName] =
    useState('');
  const toast = useToast();
  const createConversationSector = useMutation(
    async (createConversationSectorDto: CreateConversationSectorDto) =>
      ConversationSectorsService.createConversationSector(createConversationSectorDto),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          apiRoutes.listConversationSectors(),
          (oldData?: ConversationSector[]) => {
            if (!oldData) return [];
        
            const isNew = !oldData.some((sector) => sector.id === res.data.id);
        
            if (isNew) {
              return [
                ...oldData,
                {
                  id: res.data.id,
                  name: res.data.name,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  pos: oldData.length + 1,
                  isDeleted: false,
                  companyId: '',
                  automaticSortingOptionMessageId: null,
                },
              ];
            }
            return oldData;
          }
        );
  
        toast({
          title: 'Setor criado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
  
        popover.onClose();
        setNewConversationSectorName('');
      },
    }
  );
  

  function handleCreateConversationSector() {
    createConversationSector.mutate({
      name: newConversationSectorName,
    });
  }

  return (
    <Popover
      isOpen={popover.isOpen}
      onOpen={popover.onOpen}
      onClose={popover.onClose}
      placement="right"
      closeOnBlur={false}>
      <PopoverTrigger>
        <IconButton aria-label="Novo Setor" size="6px" icon={<AddIcon fontSize='12px'/>} />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverHeader fontWeight="semibold">Novo Setor</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack gap={2}>
            <Input
              id="conversaton-sector-name"
              onChange={(e) => setNewConversationSectorName(e.target.value)}
              value={newConversationSectorName}
              placeholder='Digite o nome do setor'
            />
            <ButtonGroup display="flex" width="100%" justifyContent="space-between" gap={4}>
              <Button variant="outline" onClick={popover.onClose}>
                Cancelar
              </Button>
              <Button
                isDisabled={!newConversationSectorName}
                bg='black'
                color='white'
                onClick={handleCreateConversationSector}
                isLoading={createConversationSector.isLoading}>
                Salvar
              </Button>
            </ButtonGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverNewSector;
