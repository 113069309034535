import { useQuery } from 'react-query';
import { apiRoutes } from '../../constants/api-routes';
import { CustomersService } from '../../services/customers.service';
import OrdersList from '../ListOrders';
interface DrawerOrdersHistoryProps {
  customerId: string;
}

const DrawerOrdersHistory = ({
  customerId,
}: DrawerOrdersHistoryProps) => {
  const { data: orders } = useQuery(
    apiRoutes.listOrdersHistoryByCustomer(customerId),
    async () => {
      const { data } =
        await CustomersService.listOrdersHistoryByCustomer(
          customerId,
        );
      return data;
    },
  );

  return <OrdersList orders={orders || []} />;
};

export default DrawerOrdersHistory;
