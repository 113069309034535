import { useState, useRef } from 'react';

interface useAudioRecorderParams {
  mimeType?: string;
}

type MediaRecorderState =
  | 'notInitialized'
  | 'recording'
  | 'inactive'
  | 'paused';

export const useAudioRecorder = () => {
  const [recorderState, setRecorderState] =
    useState<MediaRecorderState>('notInitialized');
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [audioExtension, setAudioExtension] = useState<string>('');

  const getSupportedMimeType = () => {
    if (MediaRecorder.isTypeSupported('audio/ogg; codecs=opus')) {
      setAudioExtension('.ogg');
      return 'audio/ogg; codecs=opus';
    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
      setAudioExtension('.webm');
      return 'audio/webm';
    }
    setAudioExtension('.wav');
    return 'audio/wav';
  };

  async function startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const selectedMimeType = getSupportedMimeType();
      const newRecorder = new MediaRecorder(stream, {
        mimeType: selectedMimeType,
      });

      setRecorderState('inactive');
      setRecorder(newRecorder);
      setMediaStream(stream);
      audioChunksRef.current = [];

      const timer = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);
      setTimer(timer);

      newRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      newRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: selectedMimeType,
        });
        setAudioUrl(URL.createObjectURL(audioBlob));
      };

      newRecorder.onstart = () => setRecorderState('recording');
      newRecorder.onpause = () => setRecorderState('paused');
      newRecorder.onresume = () => setRecorderState('recording');
      newRecorder.onerror = (error) => console.error('Recorder error:', error);

      newRecorder.start();
    } catch (error) {
      console.error('Erro ao acessar o microfone:', error);
    }
  }

  function stopRecording() {
    if (recorder) {
      recorder.stop();
      resetMediaStream();
    }
    if (timer) {
      clearInterval(timer);
    }
    setRecorderState('inactive');
  }

  function pauseRecording() {
    if (recorder) {
      recorder.pause();
    }
  }

  function resetRecorder() {
    if (recorderState === 'recording' && recorder?.state === 'recording') {
      stopRecording();
      setTimeout(resetRecorder, 100);
      return;
    }
    setRecordingDuration(0);
    resetMediaStream();
    setAudioUrl(null);
    setRecorder(null);
    setMediaStream(null);
    setRecorderState('notInitialized');
  }

  function resetMediaStream() {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
  }

  return {
    audioExtension,
    recorderState,
    startRecording,
    stopRecording,
    pauseRecording,
    audioUrl,
    resetRecorder,
    recordingDuration,
  };
};
