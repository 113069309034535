import { apiRoutes } from './../constants/api-routes';
import { request } from '../constants/request';
import { Axios, AxiosResponse } from 'axios';

const getVtexConfig = async (): Promise<
  AxiosResponse<{
    vtexAccountName: string;
    vtexStoreDomain: string;
    isVtexActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getVtexConfig());
};

export interface RegisterVtexOrderHookDto {
  vtexAccountName: string;
  vtexAppKey: string;
  vtexAppToken: string;
  vtexStoreDomain: string;
}

const registerVtexOrderHook = async (
  registerVtexOrderHookDto: RegisterVtexOrderHookDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.registerVtexOrderHook(),
    registerVtexOrderHookDto,
  );
};

const getShopifyConfig = async (): Promise<
  AxiosResponse<{
    shopifyShopName: string;
    isShopifyActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getShopifyConfig());
};

export interface SaveShopifyCredentialsDto {
  shopifyShopName: string;
  shopifyAdminAccessToken: string;
  shopifyApiKey: string;
  shopifyApiSecretKey: string;
}

const saveShopifyCredentials = async (
  saveShopifyCredentialsDto: SaveShopifyCredentialsDto,
) => {
  return request.post(
    apiRoutes.saveShopifyCredentials(),
    saveShopifyCredentialsDto,
  );
};

const getBlingConfig = async (): Promise<
  AxiosResponse<{
    isBlingActive: string;
    blingClientId: string;
  }>
> => {
  return request.get(apiRoutes.getBlingConfig());
};

export interface SaveBlingCredentialsDto {
  blingClientId: string;
  blingClientSecret: string;
}

const saveBlingCredentials = async (
  saveBlingCredentialsDto: SaveBlingCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveBlingCredentials(),
    saveBlingCredentialsDto,
  );
};

const getLojaIntegradaConfig = async (): Promise<
  AxiosResponse<{
    isLojaIntegradaActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getLojaIntegradaConfig());
};

export interface registerLojaIntegradaOrderHookDto {
  lojaIntegradaApiKey: string;
}

const registerLojaIntegradaOrderHook = async (
  registerLojaIntegradaOrderHookDto: registerLojaIntegradaOrderHookDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.registerLojaIntegradaOrderHook(),
    registerLojaIntegradaOrderHookDto,
  );
};

const getCartPandaConfig = async (): Promise<
  AxiosResponse<{
    isCartPandaActive: string;
    // cartPandaAccessToken: string;
    cartPandaShopSlug: string;
  }>
> => {
  return request.get(apiRoutes.getCartPandaConfig());
};

export interface SaveCartPandaCredentialsDto {
  cartPandaShopSlug: string;
  cartPandaAccessToken: string;
}

const saveCartPandaCredentials = async (
  saveCartPandaCredentialsDto: SaveCartPandaCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveCartPandaCredentials(),
    saveCartPandaCredentialsDto,
  );
};

const getUnboxConfig = async (): Promise<
  AxiosResponse<{
    isUnboxActive: string;
    unboxMetabaseId: string;
  }>
> => {
  return request.get(apiRoutes.getUnboxConfig());
};

export interface SaveUnboxCredentialsDto {
  unboxMetabaseId: string;
}

const saveUnboxCredentials = async (
  saveUnboxCredentialsDto: SaveUnboxCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveUnboxCredentials(),
    saveUnboxCredentialsDto,
  );
};

const getVisualECommerceConfig = async (): Promise<
  AxiosResponse<{
    isVisualECommerceActive: string;
    visualECommerceStoreDomain: string;
  }>
> => {
  return request.get(apiRoutes.getVisualECommerceConfig());
};

export interface SaveVisualECommerceCredentialsDto {
  visualECommerceApiKey: string;
  visualECommerceStoreDomain: string;
}

const saveVisualECommerceCredentials = async (
  saveVisualECommerceCredentialsDto: SaveVisualECommerceCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveVisualECommerceCredentials(),
    saveVisualECommerceCredentialsDto,
  );
};

const getMagazordConfig = async (): Promise<
  AxiosResponse<{
    isMagazordActive: string;
    magazordApiDomain: string;
  }>
> => {
  return request.get(apiRoutes.getMagazordConfig());
};

export interface SaveMagazordCredentialsDto {
  magazordApiDomain: string;
  magazordClientToken: string;
  magazordClientPassword: string;
}

const saveMagazordCredentials = async (
  saveMagazordCredentialsDto: SaveMagazordCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveMagazordCredentials(),
    saveMagazordCredentialsDto,
  );
};

const getMagentoConfig = async (): Promise<
  AxiosResponse<{
    isMagentoActive: string;
    magentoApiUrl: string;
    magentoUser: string;
    magentoVersion: string;
  }>
> => {
  return request.get(apiRoutes.getMagentoConfig());
};

export interface SaveMagentoCredentialsDto {
  magentoApiKey: string;
  magentoApiUrl: string;
  magentoUser: string;
  magentoVersion: string;
}

const saveMagentoCredentials = async (
  saveMagentoCredentialsDto: SaveMagentoCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveMagentoCredentials(),
    saveMagentoCredentialsDto,
  );
};

const getYampiConfig = async (): Promise<
  AxiosResponse<{
    isYampiActive: string;
    yampiUserToken: string;
    yampiUserSecretKey: string;
    yampiAlias: string;
  }>
> => {
  return request.get(apiRoutes.getYampiConfig());
};

export interface SaveYampiCredentialsDto {
  yampiUserToken: string;
  yampiUserSecretKey: string;
  yampiAlias: string;
}

const saveYampiCredentials = async (
  saveYampiCredentialsDto: SaveYampiCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveYampiCredentials(),
    saveYampiCredentialsDto,
  );
};

const getOmieConfig = async (): Promise<
  AxiosResponse<{
    isOmieActive: string;
  }>
> => {
  return request.get(apiRoutes.getOmieConfig());
};

export interface SaveOmieCredentialsDto {
  omieAppKey: string;
  omieAppSecret: string;
}

const saveOmieCredentials = async (
  saveOmieCredentialsDto: SaveOmieCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(apiRoutes.saveOmieCredentials(), saveOmieCredentialsDto);
};

const getOmnyConfig = async (): Promise<
  AxiosResponse<{
    isOmnyActive: string;
  }>
> => {
  return request.get(apiRoutes.getOmnyConfig());
};

export interface SaveOmnyCredentialsDto {
  omnyApiKey: string;
}

const saveOmnyCredentials = async (
  saveOmnyCredentialsDto: SaveOmnyCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(apiRoutes.saveOmnyCredentials(), saveOmnyCredentialsDto);
};

const getTrayConfig = async (): Promise<
  AxiosResponse<{
    isTrayActive: string;
    trayApiDomain: string;
  }>
> => {
  return request.get(apiRoutes.getTrayConfig());
};

export interface SaveTrayCredentialsDto {
  trayApiDomain: string;
  trayAuthCode: string;
}

const saveTrayCredentials = async (
  saveTrayCredentialsDto: SaveTrayCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(apiRoutes.saveTrayCredentials(), saveTrayCredentialsDto);
};

const getIntegrationStatusSummary = async (): Promise<
  AxiosResponse<{
    isShopifyActive: boolean;
    isVtexActive: boolean;
    isUnboxActive: boolean;
    isVisualECommerceActive: boolean;
    isLojaIntegradaActive: boolean;
    isBlingActive: boolean;
    isMagazordActive: boolean;
    isWooCommerceActive: boolean;
    isMagentoActive: boolean;
    isIngresseActive: boolean;
    isCartPandaActive: boolean;
    isOmnyActive: boolean;
    isOmieActive: boolean;
    isTrayActive: boolean;
    isYampiActive: boolean;
    isLinxCommerceActive: boolean;
  }>
> => {
  return request.get(apiRoutes.getIntegrationStatusSummary());
};

export interface SaveLinxCommerceCredentialsDto {
  linxCommerceAccessToken: string;
}

const saveLinxCommerceCredentials = async (
  saveLinxCommerceCredentialsDto: SaveLinxCommerceCredentialsDto,
): Promise<AxiosResponse<boolean>> => {
  return request.post(
    apiRoutes.saveLinxcommerceCredentials(),
    saveLinxCommerceCredentialsDto,
  );
};

export const IntegrationsService = {
  getVtexConfig,
  registerVtexOrderHook,
  getShopifyConfig,
  saveShopifyCredentials,
  getBlingConfig,
  saveBlingCredentials,
  getLojaIntegradaConfig,
  registerLojaIntegradaOrderHook,
  getIntegrationStatusSummary,
  getCartPandaConfig,
  saveCartPandaCredentials,
  getUnboxConfig,
  saveUnboxCredentials,
  getVisualECommerceConfig,
  saveVisualECommerceCredentials,
  getMagazordConfig,
  saveMagazordCredentials,
  getMagentoConfig,
  saveMagentoCredentials,
  getYampiConfig,
  saveYampiCredentials,
  getOmieConfig,
  saveOmieCredentials,
  getOmnyConfig,
  saveOmnyCredentials,
  getTrayConfig,
  saveTrayCredentials,
  saveLinxCommerceCredentials,
};
