import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  Collapse,
  Icon,
  Tooltip,
  useColorModeValue,
  Divider,
  IconButton,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { selectSidebarState, toggleSidebar } from '../../state/inboxSlice';
import { useDispatch, useSelector } from 'react-redux';
import { scrollbarStyles } from '../../styles/scrollbar.styles';

interface SiderItemProps {
  title?: string;
  icon?: React.ReactNode;
  rightElement: React.ReactNode;
  onClick: () => void;
  contextMenuItems?: {
    title: string;
    onClick: () => void;
  }[];
  children?: SiderItemProps[];
}

interface SiderProps {
  topElement?: React.ReactNode;
  width?: string;
  activeTab: string;
  items: SiderItemProps[];
}

const SectionTitle = ({
  children,
  isCollapsed,
}: {
  children: React.ReactNode;
  isCollapsed: boolean;
}) => {
  if (isCollapsed) return null;

  return (
    <Text
      fontSize="xs"
      fontWeight="medium"
      color="gray.500"
      textTransform="uppercase"
      px={2}
      mb={2}
    >
      {children}
    </Text>
  );
};

const SiderItem = ({
  activeTab,
  title,
  icon,
  onClick,
  children,
  rightElement,
  contextMenuItems,
  isCollapsed,
}: SiderItemProps & {
  activeTab: string;
  isCollapsed: boolean;
}) => {
  const hasChildren = children && children.length > 0;
  const isActive = activeTab === title;
  const contextMenu = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const bgColor = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const activeBg = useColorModeValue('blue.50', 'blue.900');
  const textColor = useColorModeValue('gray.800', 'white');

  const handleClick = () => {
    if (hasChildren) {
      setIsOpen(!isOpen);
    }
    onClick();
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    if (contextMenuItems?.length) {
      event.preventDefault();
      setPosition({ x: event.pageX, y: event.pageY });
      contextMenu.onOpen();
    }
  };

  return (
    <Box width="100%">
      {contextMenuItems && contextMenu.isOpen && (
        <Menu isOpen={true} onClose={contextMenu.onClose}>
          <MenuButton
            as={Box}
            position="absolute"
            left={`${position.x}px`}
            top={`${position.y}px`}
          />
          <MenuList zIndex="popover">
            {contextMenuItems.map(({ title, onClick }) => (
              <MenuItem key={title} onClick={onClick}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      <Tooltip label={isCollapsed ? title : ''} placement="right" hasArrow>
        <Stack
          spacing={0}
          onClick={handleClick}
          cursor="pointer"
          borderRadius="md"
          onContextMenu={handleContextMenu}
          bg={isActive ? activeBg : 'transparent'}
          _hover={{ bg: hoverBg }}
          transition="all 0.3s"
        >
          <Flex
            align="center"
            p={2}
            borderRadius="md"
            role="group"
            justify={isCollapsed ? 'center' : 'space-between'}
            minH="40px"
          >
            <Flex align="center" gap={3} flex={isCollapsed ? '0 0 auto' : 1}>
              {icon && (
                <Box
                  color={isActive ? 'blue.500' : textColor}
                  _groupHover={{ color: 'blue.500' }}
                  fontSize={isCollapsed ? '20px' : '16px'}
                >
                  {icon}
                </Box>
              )}
              {!isCollapsed && (
                <Text
                  color={isActive ? 'blue.500' : textColor}
                  fontWeight={hasChildren ? 'semibold' : 'medium'}
                  fontSize="sm"
                  _groupHover={{ color: 'blue.500' }}
                  noOfLines={1}
                >
                  {title}
                </Text>
              )}
            </Flex>

            {!isCollapsed && (
              <Flex align="center" gap={2}>
                {rightElement}
                {hasChildren && (
                  <Icon
                    as={isOpen ? MdKeyboardArrowDown : MdKeyboardArrowRight}
                    w={4}
                    h={4}
                    transition="all 0.3s"
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Stack>
      </Tooltip>

      {hasChildren && !isCollapsed && (
        <Collapse in={isOpen} animateOpacity>
          <Stack pl={4} mt={1} spacing={1}>
            {children.map((child) => (
              <SiderItem
                key={child.title}
                activeTab={activeTab}
                isCollapsed={isCollapsed}
                {...child}
              />
            ))}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
};

const Sider = ({
  items,
  activeTab,
  width = '280px',
  topElement,
}: SiderProps) => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(selectSidebarState);

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');

  const mainItems = items.slice(0, 4);
  const otherItems = items.slice(4);

  const collapsedWidth = '64px';
  const expandedWidth = width;

  return (
    <Box position="relative" >
      <Stack
        minW={isCollapsed ? collapsedWidth : expandedWidth}
        maxW={isCollapsed ? collapsedWidth : expandedWidth}
        w={isCollapsed ? collapsedWidth : expandedWidth}
        h="100vh"
        pt={topElement ? 0 : 5}
        px={isCollapsed ? 2 : 5}
        pb={5}
        bg={bgColor}
        overflowY="auto"
        overflowX="hidden"
        spacing={4}
        borderRight="1px"
        borderColor={borderColor}
        transition="all 0.3s"
        css={scrollbarStyles('4px')}
        >
        {topElement}

        <Stack spacing={4}>
          <Stack spacing={1}>
            <SectionTitle isCollapsed={isCollapsed}>Inbox</SectionTitle>
            {mainItems.map((item) => (
              <SiderItem
                key={item.title}
                activeTab={activeTab}
                isCollapsed={isCollapsed}
                {...item}
              />
            ))}
          </Stack>

          <Divider />

          <Stack spacing={1}>
            {otherItems.map((item) => (
              <SiderItem
                key={item.title}
                activeTab={activeTab}
                isCollapsed={isCollapsed}
                {...item}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>

      <Box
        position="absolute"
        top="90%" 
        transform="translateY(-50%)"
        right={isCollapsed ? '0' : '10px'}
        zIndex={2}
        boxSize={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          aria-label={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
          icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
          size="sm"
          width="36px"
          height="36px"
          borderRadius="full"
          boxShadow="lg"
          onClick={() => dispatch(toggleSidebar())}
          bg={bgColor}
          _hover={{
            bg: hoverBg,
            transform: 'scale(1.1)',
            transition: 'all 0.3s',
          }}
          _focus={{ boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)' }}
        />
      </Box>
    </Box>
  );
};

export default Sider;
