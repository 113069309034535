import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { BillingSettings } from '../types/BillingSettings';
import { Company } from '../types/Company';
import {
  CompanyDefinedField,
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum,
} from '../types/CompanyDefinedField';
import { parseJwt } from '../utils/parse-jwt.utils';

const getCompanyDetails = (): Promise<AxiosResponse<Company>> => {
  return request.get(apiRoutes.getCompanyDetails());
};

export interface UpdateCompanyDto {
  firstContactMessage: string;
  isAutomaticSortingActive: boolean;
  initialContactFlowId?: string;
}

export interface CompanyBusinessHours {
  weekday: number;
  startTime: Date;
  endTime: Date;
}

export interface CompanyBusinessHoursDto {
  businessHours: CompanyBusinessHours[];
  afterHoursMessage: string | null;
}

const updateCompany = (updateCompanyDto: UpdateCompanyDto) => {
  const accessToken = localStorage.getItem('access_token');
  const userData = parseJwt(accessToken!);
  return request.put(
    apiRoutes.updateCompany(userData!.companyId),
    updateCompanyDto,
  );
};

const getCompanyMessageBalance = (): Promise<
  AxiosResponse<{
    daily: {
      total: number;
      sent: number;
    };
    monthly: {
      total: number;
      sent: number;
      billable: number;
    };
    canSend: boolean;
  }>
> => {
  return request.get(apiRoutes.getCompanyMessageBalance());
};

const listCompanyDefinedFields = (
  table: CompanyDefinedFieldTableEnum,
): Promise<AxiosResponse<CompanyDefinedField[]>> => {
  return request.get(apiRoutes.listCompanyDefinedFields(table));
};

export interface CreateCompanyDefinedFieldDto {
  table: CompanyDefinedFieldTableEnum;
  name: string;
  dataType: CompanyDefinedFieldDataTypeEnum;
}

const createCompanyDefinedField = (
  createCompanyDefinedFieldDto: CreateCompanyDefinedFieldDto,
): Promise<AxiosResponse<CompanyDefinedField>> => {
  return request.post(
    apiRoutes.createCompanyDefinedField(),
    createCompanyDefinedFieldDto,
  );
};

const toggleColumnIsActive = async (companyDefinedFieldId: string) => {
  return request.put(apiRoutes.toggleColumnIsActive(companyDefinedFieldId));
};

const getCompanyBusinessHours = (): Promise<
  AxiosResponse<CompanyBusinessHoursDto>
> => {
  return request.get(apiRoutes.getCompanyBusinessHours());
};

const updateCompanyBusinessHours = (
  updateCompanyBusinessHoursDto: CompanyBusinessHoursDto,
) => {
  return request.post(
    apiRoutes.updateCompanyBusinessHours(),
    updateCompanyBusinessHoursDto,
  );
};

const getCompanyBillingSettings = (): Promise<
  AxiosResponse<BillingSettings>
> => {
  return request.get(apiRoutes.getCompanyBillingSettings());
};

export const CompaniesService = {
  getCompanyDetails,
  updateCompany,
  getCompanyMessageBalance,
  listCompanyDefinedFields,
  createCompanyDefinedField,
  toggleColumnIsActive,
  getCompanyBusinessHours,
  updateCompanyBusinessHours,
  getCompanyBillingSettings,
};
