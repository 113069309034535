import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { colors } from '../../../../constants/colors';
import { ListConversationDetailedItem, SelectableListConversationDetailedItem } from '../../../../types/Conversation';
import ConversationItem from '../ConversationItem';
import { User } from '../../../../types/Prisma';
import { scrollbarStyles } from '../../../../styles/scrollbar.styles';

interface ListConversationsProps {
  conversations: SelectableListConversationDetailedItem[];
  onScroll: () => void;
  listConversationRef: React.RefObject<HTMLDivElement>;
  footerComponent?: React.ReactNode | null;
  toggleListConversations: () => void;
  companyAgents: User[];
  onConversationSelect?: (conversationId: string, checked: boolean) => void;
  isSelectable?: boolean;
}

const ListConversations = ({ conversations, listConversationRef, onScroll, footerComponent, toggleListConversations, companyAgents, onConversationSelect, isSelectable = true }: ListConversationsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleClickConversation(conversationId: string) {
    searchParams.set('conversationId', conversationId);
    setSearchParams(searchParams);
    toggleListConversations()
  }

  const handleConversationSelect = (conversation: ListConversationDetailedItem, selected: boolean) => 
    onConversationSelect?.(conversation.id, selected);

  return (
    <Flex
      flexDir={'column'}
      padding={3}
      borderStyle="solid"
      height='100%'
      flex={1}
      borderColor={colors.lightGrey}
      onScroll={onScroll}
      ref={listConversationRef}
      css={scrollbarStyles('4px')}
    >
      {conversations.map((conversation) => (
        <ConversationItem
          key={conversation.id}
          conversationId={conversation.id}
          name={conversation.recipientName}
          message={conversation.lastMessage}
          isActive={conversation.id === searchParams.get('conversationId')}
          onClick={() => handleClickConversation(conversation.id)}
          companyAgents={companyAgents}
          isSelected={conversation.isSelected}
          onSelect={(selected: boolean) => handleConversationSelect(conversation, selected)}
          isSelectable={isSelectable}
        />
      ))}
      {footerComponent}
    </Flex>
  );
};

export default ListConversations;
