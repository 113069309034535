import {
  Accordion,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FocusLock,
  FormControl,
  FormLabel as ChakraFormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  useDisclosure,
  useToast,
  Text,
} from "@chakra-ui/react";
import ptBr from "date-fns/locale/pt-BR";
import { useCallback, useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputSelect, { SelectOption } from "../../../../components/InputSelect";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { queryStringDelimiter } from "../../../../constants/query-string-delimiter";
import { useCustomerSearchParams } from "../../../../hooks/useCustomerSearchParams";
import {
  CreateFilterDto,
  FiltersService,
  UpdateFilterDto,
} from "../../../../services/filters.service";
import { scrollbarStyles } from "../../../../styles/scrollbar.styles";
import { CustomerFiltersEnum } from "../../../../types/CustomerFiltersEnum";
import { Filter } from "../../../../types/Filter";
import { FilterType } from "../../../../types/FilterType";
import { UrlUtils } from "../../../../utils/url.utils";
import SectionCampaigns from "./SectionCampaigns";
import SectionCustomers from "./SectionCustomers";
import SectionCustomFields from "./SectionCustomFields";
import SectionProducts from "./SectionProducts";
import SectionPurchases from "./SectionPurchases";
import SectionRFM from "./SectionRFM";
import SectionTags from "./SectionTags";
import { resetSelectedCustomers } from "../../../../state/campaignCreationSlice";
import { useDispatch } from "react-redux";

registerLocale("pt-BR", ptBr);

const FilterSidebar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    selectedEngagementTemplateIds,
    searchQuery,
    minTotalPurchases,
    maxTotalPurchases,
    minAverageOrderValue,
    maxAverageOrderValue,
    minTotalOrders,
    maxTotalOrders,
    selectedEngagementActionTypes,
    startOrdersCreatedAt,
    endOrdersCreatedAt,
    minDaysSinceLastCampaign,
    sortBy,
    minAverageItemValue,
    maxAverageItemValue,
    selectedTags,
    excludedTags,
    isRemarketing,
    minDaysSinceLastPurchase,
    maxDaysSinceLastPurchase,
    exactDaysSinceLastPurchase,
    excludedTemplateIds,
    selectedProductIds,
    excludedProductIds,
    minProductQuantity,
    maxProductQuantity,
    minDaysSinceLastProductPurchase,
    maxDaysSinceLastProductPurchase,
    productNameContains,
    isLastProductPurchased,
    customFieldId1,
    customFieldValue1,
    isScheduledCampaignsVisible,
    selectedStates,
    selectedCities
  } = useCustomerSearchParams();
  const createFilterPopover = useDisclosure();
  const inputFilterNameRef = useRef(null);
  const [newFilterName, setNewFilterName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

  const { data: savedFilters = [] } = useQuery(apiRoutes.listFilters(), async () => {
    const { data } = await FiltersService.listFilters();
    return data;
  });
  const updateFilter = useMutation(
    (updateFilterDto: UpdateFilterDto) =>
      FiltersService.updateFilter(updateFilterDto),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(apiRoutes.listFilters(), (oldData: any) => {
          return oldData.map((filter: Filter) =>
            filter.id === res.data.id ? res.data : filter
          );
        });
        toast({
          title: "Filtro atualizado com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
    }
  );
  const createFilter = useMutation(
    (createFilterDto: CreateFilterDto) =>
      FiltersService.createFilter(createFilterDto),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(apiRoutes.listFilters(), (oldData: any) => {
          return [...oldData, res.data];
        });
      },
    }
  );

  const valuesFromUrl = {
    searchQuery,
    selectedEngagementTemplateIds: [] as SelectOption[], // this field is not cached
    selectedEngagementActionTypes: [] as SelectOption[], // this field is not cached
    totalPurchases: {
      minValue: Number(minTotalPurchases) || 0,
      maxValue: Number(maxTotalPurchases) || 0,
    },
    averageOrderValue: {
      minValue: Number(minAverageOrderValue) || 0,
      maxValue: Number(maxAverageOrderValue) || 0,
    },
    averageItemValue: {
      minValue: Number(minAverageItemValue) || 0,
      maxValue: Number(maxAverageItemValue) || 0,
    },
    totalOrders: {
      minValue: Number(minTotalOrders) || 0,
      maxValue: Number(maxTotalOrders) || 0,
    },
    startOrdersCreatedAt,
    endOrdersCreatedAt,
    minDaysSinceLastCampaign: Number(minDaysSinceLastCampaign) || 0,
    sortBy,
    selectedTags: [] as SelectOption[], // this field is not cached
    excludedTags: [] as SelectOption[], // this field is not cached
    isRemarketing: isRemarketing === "true" || false,
    minDaysSinceLastPurchase: Number(minDaysSinceLastPurchase) || 0,
    maxDaysSinceLastPurchase: Number(maxDaysSinceLastPurchase) || 0,
    exactDaysSinceLastPurchase: Number(exactDaysSinceLastPurchase) || 0,
    excludedTemplateIds: [] as SelectOption[],
    selectedProductIds: [] as SelectOption[], // this field is not cached
    excludedProductIds: [] as SelectOption[], // this field is not cached
    productQuantity: {
      minValue: Number(minProductQuantity) || 0,
      maxValue: Number(maxProductQuantity) || 0,
    },
    daysSinceLastProductPurchase: {
      minValue: Number(minDaysSinceLastProductPurchase) || 0,
      maxValue: Number(maxDaysSinceLastProductPurchase) || 0,
    },
    productNameContains,
    isLastProductPurchased: isLastProductPurchased === "true" || false,
    customFieldId1: customFieldId1 || "",
    customFieldValue1: customFieldValue1 || "",
    isScheduledCampaignsVisible:
      isScheduledCampaignsVisible === "true" || false,
    selectedStates: [] as SelectOption[], // this field is not cached
    selectedCities: [] as SelectOption[],
  };
  const useFormReturn = useForm({
    defaultValues: valuesFromUrl,
  });
  const { register, handleSubmit, reset, setValue, getValues } = useFormReturn;

  useEffect(() => {
    const {
      selectedEngagementTemplateIds,
      selectedEngagementActionTypes,
      selectedTags,
      excludedTags,
      selectedProductIds,
      excludedProductIds,
      excludedTemplateIds,
      selectedStates,
      selectedCities,
    } = getValues();

    reset({
      ...valuesFromUrl,
      selectedEngagementTemplateIds,
      selectedEngagementActionTypes,
      selectedTags,
      excludedTags,
      selectedProductIds,
      excludedProductIds,
      excludedTemplateIds,
      selectedStates,
      selectedCities,
    });
  }, [reset, searchParams.toString(), selectedFilter?.criteria]);

  const updateSelectedValues = useCallback(
    (options: {
      selectedValues: string;
      sourceData: any[] | undefined;
      valueToSet: any;
      optionValue: string;
      optionLabel: string;
    }) => {
      const {
        selectedValues,
        sourceData,
        valueToSet,
        optionValue,
        optionLabel,
      } = options;

      if (!selectedValues || selectedValues.length === 0) {
        setValue(valueToSet, []);
        return;
      }
      if (!sourceData) return;

      const valueIds = selectedValues.split(queryStringDelimiter);
      const selectedOptions = sourceData.filter((item) =>
        valueIds.includes(item[optionValue])
      );
      const updatedValues = selectedOptions.map((item) => ({
        value: item[optionValue],
        label: item[optionLabel],
      }));

      setValue(valueToSet, updatedValues);
    },
    [setValue]
  );

  function onSubmit(data: {
    searchQuery?: string;
    selectedEngagementTemplateIds?: SelectOption[];
    selectedEngagementActionTypes?: SelectOption[];
    totalPurchases?: { minValue?: any; maxValue?: any };
    averageOrderValue?: { minValue?: any; maxValue?: any };
    averageItemValue?: { minValue?: any; maxValue?: any };
    totalOrders?: { minValue?: any; maxValue?: any };
    startOrdersCreatedAt?: string;
    endOrdersCreatedAt?: string;
    minDaysSinceLastCampaign?: any;
    sortBy?: string;
    selectedTags?: SelectOption[];
    excludedTags?: SelectOption[];
    isRemarketing?: boolean;
    minDaysSinceLastPurchase?: any;
    maxDaysSinceLastPurchase?: any;
    exactDaysSinceLastPurchase?: any;
    excludedTemplateIds?: SelectOption[];
    selectedProductIds?: SelectOption[];
    excludedProductIds?: SelectOption[];
    productQuantity?: { minValue?: any; maxValue?: any };
    daysSinceLastProductPurchase?: { minValue?: any; maxValue?: any };
    productNameContains?: string;
    isLastProductPurchased?: boolean;
    customFieldId1?: string;
    customFieldValue1?: string;
    isScheduledCampaignsVisible?: boolean;
    selectedStates?: SelectOption[];
    selectedCities?: SelectOption[];
  }) {
    const {
      searchQuery,
      selectedEngagementTemplateIds,
      selectedEngagementActionTypes,
      totalPurchases,
      averageOrderValue,
      totalOrders,
      startOrdersCreatedAt,
      endOrdersCreatedAt,
      minDaysSinceLastCampaign,
      sortBy,
      averageItemValue,
      selectedTags,
      minDaysSinceLastPurchase,
      maxDaysSinceLastPurchase,
      exactDaysSinceLastPurchase,
      excludedTags,
      isRemarketing,
      excludedTemplateIds,
      selectedProductIds,
      excludedProductIds,
      productQuantity,
      daysSinceLastProductPurchase,
      productNameContains,
      isLastProductPurchased,
      customFieldId1,
      customFieldValue1,
      isScheduledCampaignsVisible,
      selectedStates,
      selectedCities,
    } = data;

    const queryValues: Record<string, string> = {
      [CustomerFiltersEnum.SEARCH_QUERY]: searchQuery || "",
      [CustomerFiltersEnum.SELECTED_ENGAGEMENT_TEMPLATE_IDS]:
        selectedEngagementTemplateIds
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.SELECTED_ENGAGEMENT_ACTION_TYPES]:
        selectedEngagementActionTypes
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.MIN_TOTAL_PURCHASES]: totalPurchases?.minValue || "",
      [CustomerFiltersEnum.MAX_TOTAL_PURCHASES]: totalPurchases?.maxValue || "",
      [CustomerFiltersEnum.MIN_AVERAGE_ORDER_VALUE]:
        averageOrderValue?.minValue || "",
      [CustomerFiltersEnum.MAX_AVERAGE_ORDER_VALUE]:
        averageOrderValue?.maxValue || "",
      [CustomerFiltersEnum.MIN_TOTAL_ORDERS]: totalOrders?.minValue || "",
      [CustomerFiltersEnum.MAX_TOTAL_ORDERS]: totalOrders?.maxValue || "",
      [CustomerFiltersEnum.START_ORDERS_CREATED_AT]: startOrdersCreatedAt || "",
      [CustomerFiltersEnum.END_ORDERS_CREATED_AT]: endOrdersCreatedAt || "",
      [CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN]:
        minDaysSinceLastCampaign || "",
      [CustomerFiltersEnum.SORT_BY]: sortBy || "",
      [CustomerFiltersEnum.MIN_AVERAGE_ITEM_VALUE]:
        averageItemValue?.minValue || "",
      [CustomerFiltersEnum.MAX_AVERAGE_ITEM_VALUE]:
        averageItemValue?.maxValue || "",
      [CustomerFiltersEnum.SELECTED_TAGS]:
        selectedTags
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.EXCLUDED_TAGS]:
        excludedTags
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE]:
        minDaysSinceLastPurchase || "",
      [CustomerFiltersEnum.MAX_DAYS_SINCE_LAST_PURCHASE]:
        maxDaysSinceLastPurchase || "",
      [CustomerFiltersEnum.EXACT_DAYS_SINCE_LAST_PURCHASE]:
        exactDaysSinceLastPurchase || "",
      [CustomerFiltersEnum.IS_REMARKETING]: isRemarketing ? "true" : "",
      [CustomerFiltersEnum.EXCLUDED_TEMPLATE_IDS]:
        excludedTemplateIds
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.SELECTED_PRODUCT_IDS]:
        selectedProductIds
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.EXCLUDED_PRODUCT_IDS]:
        excludedProductIds
          ?.map((option) => option.value)
          .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.MIN_PRODUCT_QUANTITY]:
        productQuantity?.minValue || "",
      [CustomerFiltersEnum.MAX_PRODUCT_QUANTITY]:
        productQuantity?.maxValue || "",
      [CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PRODUCT_PURCHASE]:
        daysSinceLastProductPurchase?.minValue || "",
      [CustomerFiltersEnum.MAX_DAYS_SINCE_LAST_PRODUCT_PURCHASE]:
        daysSinceLastProductPurchase?.maxValue || "",
      [CustomerFiltersEnum.PRODUCT_NAME_CONTAINS]: productNameContains || "",
      [CustomerFiltersEnum.IS_LAST_PRODUCT_PURCHASED]: isLastProductPurchased
        ? "true"
        : "",
      [CustomerFiltersEnum.CUSTOM_FIELD_ID_1]: customFieldId1 || "",
      [CustomerFiltersEnum.CUSTOM_FIELD_VALUE_1]: customFieldValue1 || "",
      [CustomerFiltersEnum.IS_SCHEDULED_CAMPAIGNS_VISIBLE]:
        isScheduledCampaignsVisible ? "true" : "",
      [CustomerFiltersEnum.SELECTED_STATES]: selectedStates
        ?.map((option) => option.value)
        .join(queryStringDelimiter) || "",
      [CustomerFiltersEnum.SELECTED_CITIES]: selectedCities
        ?.map((option) => option.value)
        .join(queryStringDelimiter) || "",
    };

    Object.keys(queryValues).forEach((key) => {
      if (queryValues[key] === "") {
        searchParams.delete(key);
      } else {
        searchParams.set(key, queryValues[key]);
      }
    });

    searchParams.delete('rfmGroup')

    if (searchParams.toString().length >= 1900) {
      toast({
        title: "Você atingiu o limite de filtros",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSearchParams(searchParams);
  }

  function handleChangeSelectedFilter(filterId: string) {
    const foundFilter = savedFilters.find(
      (filter: any) => filter.id === filterId
    );
    if (foundFilter) {
      const filterObject = UrlUtils.convertQueryStringToObject(foundFilter.criteria);
      const filterCriteria = UrlUtils.convertObjectToQueryString({
        ...filterObject,
        excludedTemplateIds,
      });
      setSelectedFilter(foundFilter);
      navigate({
        pathname: appPaths.customers.index(),
        search: filterCriteria,
      });
    }
  }

  function resetStates() {
    setSelectedFilter(null);
    setNewFilterName("");
  }

  async function handleClickUpdateFilter() {
    await updateFilter.mutateAsync({
      filterId: selectedFilter!.id,
      criteria: searchParams.toString(),
    });
  }

  async function handleClickCreateFilter() {
    try {
      const { data } = await createFilter.mutateAsync({
        name: newFilterName,
        criteria: searchParams.toString(),
        type: FilterType.customer,
      });
      resetStates();
      setSelectedFilter(data);
      createFilterPopover.onClose();
      toast({
        title: "Filtro salvo com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) { }
  }

  function handleClearFilters() {
    setSearchParams(new URLSearchParams());
    dispatch(resetSelectedCustomers());
    resetStates();
  }

  const totalAppliedFilters = [
    searchQuery,
    selectedEngagementTemplateIds,
    selectedEngagementActionTypes,
    Number(minTotalPurchases) || Number(maxTotalPurchases),
    Number(minAverageOrderValue) || Number(maxAverageOrderValue),
    Number(minTotalOrders) || Number(maxTotalOrders),
    startOrdersCreatedAt,
    endOrdersCreatedAt,
    Number(minDaysSinceLastCampaign),
    Number(minAverageItemValue) || Number(maxAverageItemValue),
    selectedTags,
    excludedTags,
    Number(minDaysSinceLastPurchase),
    Number(maxDaysSinceLastPurchase),
    Number(exactDaysSinceLastPurchase),
    isRemarketing,
    selectedProductIds,
    excludedProductIds,
    Number(minProductQuantity) || Number(maxProductQuantity),
    Number(minDaysSinceLastProductPurchase) ||
    Number(maxDaysSinceLastProductPurchase),
    productNameContains,
    isLastProductPurchased,
    customFieldValue1,
    isScheduledCampaignsVisible,
    selectedStates,
    selectedCities,
  ].filter((filter) => !!filter).length;

  const accordionDefaultIndex = [];
  if (searchParams.get('rfmGroup')) {
    accordionDefaultIndex.push(0);
  }
  if (excludedTemplateIds?.length) {
    accordionDefaultIndex.push(1);
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Flex
          flexDir={"column"}
          height="100%"
          paddingY={5}
          paddingX={2}
          gap={2}>
          <Flex
            overflowY="scroll"
            flexDir="column"
            maxHeight="70vh"
            flexGrow={1}
            css={scrollbarStyles("4px")}
            paddingRight="5px"
            gap={3}>
            <FormControl>
              <ChakraFormLabel fontWeight={"bold"}>
                Filtros salvos
              </ChakraFormLabel>
              <InputSelect
                placeholder="Selecione um filtro"
                options={savedFilters.map((filter: any) => ({
                  value: filter.id,
                  label: filter.name,
                }))}
                value={
                  selectedFilter
                    ? [
                      {
                        value: selectedFilter.id,
                        label: selectedFilter.name,
                      },
                    ]
                    : []
                }
                onChange={(option) => handleChangeSelectedFilter(option.value)}
              />
            </FormControl>
            <FormControl>
              <ChakraFormLabel fontWeight={"bold"}>Pesquisar</ChakraFormLabel>
              <Input
                size={"sm"}
                bg="white"
                {...register("searchQuery")}
                placeholder="Nome, telefone e etc..."
              />
            </FormControl>
            <FormControl>
              <ChakraFormLabel fontSize="sm">Ordenar por</ChakraFormLabel>
              <Select
                size="sm"
                bg="white"
                placeholder="Selecione"
                {...register("sortBy")}>
                <option value="createdAtDesc">Data de criação - desc</option>
                <option value="nameAsc">Nome do cliente - asc</option>
                <option value="totalPurchasesDesc">
                  Total em compras - desc
                </option>
                <option value="totalOrdersDesc">Total de pedidos - desc</option>
                <option value="lastPurchaseAtDesc">
                  Data de última compra - desc
                </option>
                <option value="random">
                  Aleatório
                </option>
              </Select>
            </FormControl>
            <Accordion
              defaultIndex={accordionDefaultIndex}
              allowMultiple>
              <SectionCustomers
                useFormReturn={useFormReturn}
                updateSelectedValues={updateSelectedValues}
              />
              <SectionRFM selectedFilter={selectedFilter} />
              <SectionCampaigns
                useFormReturn={useFormReturn}
                updateSelectedValues={updateSelectedValues}
              />
              <SectionTags
                useFormReturn={useFormReturn}
                updateSelectedValues={updateSelectedValues}
              />
              <SectionPurchases useFormReturn={useFormReturn} />
              <SectionProducts
                useFormReturn={useFormReturn}
                updateSelectedValues={updateSelectedValues}
              />
              <SectionCustomFields useFormReturn={useFormReturn} />
            </Accordion>
          </Flex>
          {totalAppliedFilters > 0 ? (
            <Button
              flexGrow={1}
              width="100%"
              onClick={() => handleClearFilters()}
            >
              Limpar ({totalAppliedFilters})
            </Button>
          ) : (
            <Button type="submit" width='100%'>
              Filtrar
            </Button>
          )}
          {totalAppliedFilters > 0 && (
            <Flex width="100%" gap={3}>
              <Flex flex="1" gap={3}>
                {selectedFilter ? (
                  <Button flex="1" onClick={handleClickUpdateFilter} isLoading={updateFilter.isLoading}>
                    Atualizar
                  </Button>
                ) : (
                  <Popover
                    isOpen={createFilterPopover.isOpen}
                    initialFocusRef={inputFilterNameRef}
                    onOpen={createFilterPopover.onOpen}
                    onClose={createFilterPopover.onClose}
                    placement="top-end"
                    closeOnBlur={false}
                  >
                    <PopoverTrigger>
                      <Button flex="1">Salvar</Button>
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                      <FocusLock>
                        <PopoverArrow />
                        <Stack spacing={4}>
                          <Text>Digite o nome do filtro:</Text>
                          <Input
                            id="first-name"
                            ref={inputFilterNameRef}
                            onChange={(e) => setNewFilterName(e.target.value)}
                            value={newFilterName}
                          />
                          <ButtonGroup display="flex" justifyContent="space-between" width="100%">
                            <Button flex="1" variant="outline" onClick={createFilterPopover.onClose}>
                              Cancelar
                            </Button>
                            <Button
                              flex="1"
                              isDisabled={!newFilterName}
                              colorScheme="teal"
                              onClick={handleClickCreateFilter}
                              isLoading={createFilter.isLoading}
                            >
                              Salvar
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      </FocusLock>
                    </PopoverContent>
                  </Popover>
                )}
              </Flex>
              <Flex flex="1">
                <Button type="submit" flex="1" variant="outline" colorScheme={'black'} bgColor="white">
                  Filtrar
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </form>
    </Box>
  );
};

export default FilterSidebar;
