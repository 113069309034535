import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { appPaths } from '../../../../constants/app-paths';
import { AutomationsService } from '../../../../services/automations.service';
import AutomationByTypeForm from '../components/AutomationByTypeForm';
import { apiRoutes } from '../../../../constants/api-routes';
import {
  ListMessageTemplateItem,
  MessageTemplatesService,
} from '../../../../services/message-templates.service';
import GlassmorphisContainer from '../../../../components/GlassmorphismContainer';
import { SourceIntegrationLabelsRoutesAndImages } from '../../../../types/source-integration-labels-routes-and-images';
import { IntegrationsService } from '../../../../services/integrations.service';
import IntegrationActiveKeyBySourceIntegration from '../constants/integration-active-key-by-source-integration';
import TopIntegrationInactiveErrorMessage from '../components/TopIntegrationInactiveErrorMessage';
import LoadingScreen from '../../../../components/LoadingScreen';

export interface CreateAutomationFromIntegrationDto {
  name: string;
  isActive: boolean;
  messageTemplateId?: string;
  templateArgs?: Record<string, string | null | undefined>;
  isAutomationRepetitionAllowed: boolean;
  automationTypeId: string;
  dailyMessageLimitOnWhatsapp: number;
  action: string;
  flowId?: string;
}

const CreateBackgroundAutomationByTypePage = () => {
  const { automationTypeId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { data: integrationStatus } = useQuery(
    apiRoutes.getIntegrationStatusSummary(),
    async () => {
      const { data } = await IntegrationsService.getIntegrationStatusSummary();
      return data;
    },
  );

  const { data: automationType } = useQuery(
    apiRoutes.showAutomationType(automationTypeId!),
    async () => {
      const { data } = await AutomationsService.showAutomationType(
        automationTypeId!,
      );
      return data;
    },
  );

  const { data: templates, isFetching: isFetchingMessageTemplates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => template.status === 'approved',
        );
      },
    },
  );

  const filteredTemplates = useMemo(() => {
    if (!templates || !automationType) return [];
    return templates.filter(
      (t) => t.type == automationType!.slug.toUpperCase(),
    );
  }, [automationType, templates]);

  const isIntegrationActive = useMemo(() => {
    if (!integrationStatus || !automationType) return false;
    const integrationActiveKey =
      IntegrationActiveKeyBySourceIntegration[
        automationType.sourceIntegration!
      ];
    return (integrationStatus as Record<string, boolean>)[integrationActiveKey];
  }, [integrationStatus, automationType]);

  const createAutomation = useMutation(
    (createAutomationDto: CreateAutomationFromIntegrationDto) =>
      AutomationsService.createAutomationFromIntegration(createAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: 'Automação criada com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      },
    },
  );

  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  function handleOpenIntegrationPage() {
    const routeKey =
      SourceIntegrationLabelsRoutesAndImages[automationType?.sourceIntegration!]
        .routeKey;
    if (!routeKey) {
      return;
    }
    const pathToOpen = appPaths.settings.integrationSettings[routeKey]?.();
    navigate(pathToOpen);
  }

  async function onSubmit(data: any) {
    const { name, isActive, messageTemplateId, dailyMessageLimitOnWhatsapp, action, flowId } =
      data;
    await createAutomation.mutateAsync({
      name,
      isActive,
      messageTemplateId,
      templateArgs,
      dailyMessageLimitOnWhatsapp,
      automationTypeId: automationTypeId!,
      isAutomationRepetitionAllowed: true,
      action,
      flowId,
    });
  }

  return (
    <>
      {automationType && !isIntegrationActive && (
        <TopIntegrationInactiveErrorMessage
          sourceIntegration={automationType?.sourceIntegration!}
        />
      )}
      <GlassmorphisContainer show={!isIntegrationActive}>
        <LoadingScreen isLoading={isFetchingMessageTemplates}>
          <AutomationByTypeForm
            onSubmit={onSubmit}
            templateArgs={templateArgs}
            setTemplateArgs={setTemplateArgs}
            automationType={automationType}
            templates={filteredTemplates}
          />
        </LoadingScreen>
      </GlassmorphisContainer>
    </>
  );
};

export default CreateBackgroundAutomationByTypePage;
