import { Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../../../../constants/app-paths';
import { colors } from '../../../../../constants/colors';
import { SourceIntegration } from '../../../../../types/Prisma';
import { SourceIntegrationLabelsRoutesAndImages } from '../../../../../types/source-integration-labels-routes-and-images';

const TopIntegrationInactiveErrorMessage = ({
  sourceIntegration,
}: {
  sourceIntegration: SourceIntegration;
}) => {
  const navigate = useNavigate();
  function handleOpenIntegrationPage() {
    const routeKey =
      SourceIntegrationLabelsRoutesAndImages[sourceIntegration!].routeKey;
    if (!routeKey) {
      return;
    }
    const pathToOpen = appPaths.settings.integrationSettings[routeKey]?.();
    navigate(pathToOpen);
  }
  return (
    <Box
      backgroundColor="#ffe6e6"
      margin="10px"
      borderRadius="5px"
      textAlign="center"
      padding="10px"
      sx={{ '& > *': { margin: '4px' } }}
    >
      <Text fontSize="22px" textColor="#333333" fontWeight="bold">
        {`Antes de criar uma automação, você precisa ativar a integração ${SourceIntegrationLabelsRoutesAndImages[sourceIntegration].label}`}
      </Text>
      <Button
        width="auto"
        color="white"
        bgColor={colors.primary}
        onClick={handleOpenIntegrationPage}
      >
        Configurar Automação
      </Button>
    </Box>
  );
};

export default TopIntegrationInactiveErrorMessage;
