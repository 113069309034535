import { FormControl } from '@chakra-ui/react';
import InputSelect, { SelectOption } from '../InputSelect';
import InputSelectAsync from '../InputSelectAsync';
import { Control, Controller } from 'react-hook-form';
import FormLabel from '../FormLabel';

interface SelectFieldProps {
  label: string;
  tooltip: string;
  name: string;
  control: Control<any>;
  defaultOptions: SelectOption[];
  isAsync?: boolean;
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>
}

const SelectField = ({ label, tooltip, name, control, defaultOptions, isAsync = false, loadOptions }: SelectFieldProps) => (
  <FormControl>
    <FormLabel
      size="sm"
      tooltip={tooltip}
    >
      {label}
    </FormLabel>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        isAsync && loadOptions ? (
          <InputSelectAsync
            defaultOptions={defaultOptions}
            isMulti
            cacheOptions={true}
            loadOptions={loadOptions}
            placeholder="Selecionar opções"
            value={field.value}
            onChange={field.onChange}
          />
        ) : (
          <InputSelect
            options={defaultOptions}
            isMulti
            value={field.value}
            onChange={(value: SelectOption) => field.onChange(value)}
          />
        )
      )}
    />
  </FormControl>
);

export default SelectField;
