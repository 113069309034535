export const countriesData = [
  {
    name: 'Afeganistão',
    dialCode: '+93',
    flag: 'af',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Albânia',
    dialCode: '+355',
    flag: 'al',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Argélia',
    dialCode: '+213',
    flag: 'dz',
    formattedNumber: '999 999 999',
  },
  { name: 'Andorra', dialCode: '+376', flag: 'ad', formattedNumber: '999 999' },
  {
    name: 'Angola',
    dialCode: '+244',
    flag: 'ao',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Antígua e Barbuda',
    dialCode: '+1-268',
    flag: 'ag',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    flag: 'ar',
    formattedNumber: '(99) 9999-9999',
  },
  {
    name: 'Armênia',
    dialCode: '+374',
    flag: 'am',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Austrália',
    dialCode: '+61',
    flag: 'au',
    formattedNumber: '(9) 9999 9999',
  },
  {
    name: 'Áustria',
    dialCode: '+43',
    flag: 'at',
    formattedNumber: '9 999 9999',
  },
  {
    name: 'Azerbaijão',
    dialCode: '+994',
    flag: 'az',
    formattedNumber: '9 999 9999',
  },
  {
    name: 'Bahamas',
    dialCode: '+1-242',
    flag: 'bs',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Bahrein',
    dialCode: '+973',
    flag: 'bh',
    formattedNumber: '999 99999',
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    flag: 'bd',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Barbados',
    dialCode: '+1-246',
    flag: 'bb',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Bielorrússia',
    dialCode: '+375',
    flag: 'by',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Bélgica',
    dialCode: '+32',
    flag: 'be',
    formattedNumber: '999 999 999',
  },
  { name: 'Belize', dialCode: '+501', flag: 'bz', formattedNumber: '999 9999' },
  {
    name: 'Benin',
    dialCode: '+229',
    flag: 'bj',
    formattedNumber: '999 999 999',
  },
  { name: 'Butão', dialCode: '+975', flag: 'bt', formattedNumber: '999 99999' },
  {
    name: 'Bolívia',
    dialCode: '+591',
    flag: 'bo',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Bósnia e Herzegovina',
    dialCode: '+387',
    flag: 'ba',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Botsuana',
    dialCode: '+267',
    flag: 'bw',
    formattedNumber: '999 9999',
  },
  {
    name: 'Brasil',
    dialCode: '+55',
    flag: 'br',
    formattedNumber: '(99) 99999-9999',
  },
  { name: 'Brunei', dialCode: '+673', flag: 'bn', formattedNumber: '999 9999' },
  {
    name: 'Bulgária',
    dialCode: '+359',
    flag: 'bg',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    flag: 'bf',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    flag: 'bi',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Cabo Verde',
    dialCode: '+238',
    flag: 'cv',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Camboja',
    dialCode: '+855',
    flag: 'kh',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Camarões',
    dialCode: '+237',
    flag: 'cm',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Canadá',
    dialCode: '+1',
    flag: 'ca',
    formattedNumber: '(999) 999-9999',
  },
  {
    name: 'República Centro-Africana',
    dialCode: '+236',
    flag: 'cf',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Chade',
    dialCode: '+235',
    flag: 'td',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Chile',
    dialCode: '+56',
    flag: 'cl',
    formattedNumber: '(99) 9999-9999',
  },
  {
    name: 'China',
    dialCode: '+86',
    flag: 'cn',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Colômbia',
    dialCode: '+57',
    flag: 'co',
    formattedNumber: '(99) 9999-9999',
  },
  {
    name: 'Comores',
    dialCode: '+269',
    flag: 'km',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Congo',
    dialCode: '+242',
    flag: 'cg',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Congo (República Democrática)',
    dialCode: '+243',
    flag: 'cd',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    flag: 'cr',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Croácia',
    dialCode: '+385',
    flag: 'hr',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    flag: 'cu',
    formattedNumber: '999 999 9999',
  },
  { name: 'Chipre', dialCode: '+357', flag: 'cy', formattedNumber: '999 9999' },
  {
    name: 'República Tcheca',
    dialCode: '+420',
    flag: 'cz',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Dinamarca',
    dialCode: '+45',
    flag: 'dk',
    formattedNumber: '999 9999',
  },
  {
    name: 'Djibuti',
    dialCode: '+253',
    flag: 'dj',
    formattedNumber: '999 9999',
  },
  {
    name: 'Dominica',
    dialCode: '+1-767',
    flag: 'dm',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'República Dominicana',
    dialCode: '+1-809',
    flag: 'do',
    formattedNumber: '(999) 999-9999',
  },
  {
    name: 'Equador',
    dialCode: '+593',
    flag: 'ec',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Egito',
    dialCode: '+20',
    flag: 'eg',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    flag: 'sv',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Guiné Equatorial',
    dialCode: '+240',
    flag: 'gq',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Eritreia',
    dialCode: '+291',
    flag: 'er',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Estônia',
    dialCode: '+372',
    flag: 'ee',
    formattedNumber: '999 9999',
  },
  {
    name: 'Essuatíni',
    dialCode: '+268',
    flag: 'sz',
    formattedNumber: '999 9999',
  },
  {
    name: 'Etiópia',
    dialCode: '+251',
    flag: 'et',
    formattedNumber: '999 999 999',
  },
  { name: 'Fiji', dialCode: '+679', flag: 'fj', formattedNumber: '999 9999' },
  {
    name: 'Finlândia',
    dialCode: '+358',
    flag: 'fi',
    formattedNumber: '999 999 999',
  },
  {
    name: 'França',
    dialCode: '+33',
    flag: 'fr',
    formattedNumber: '999 999 9999',
  },
  { name: 'Gabão', dialCode: '+241', flag: 'ga', formattedNumber: '999 9999' },
  { name: 'Gâmbia', dialCode: '+220', flag: 'gm', formattedNumber: '999 9999' },
  {
    name: 'Geórgia',
    dialCode: '+995',
    flag: 'ge',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Alemanha',
    dialCode: '+49',
    flag: 'de',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Gana',
    dialCode: '+233',
    flag: 'gh',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Grécia',
    dialCode: '+30',
    flag: 'gr',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Granada',
    dialCode: '+1-473',
    flag: 'gd',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    flag: 'gt',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Guiné',
    dialCode: '+224',
    flag: 'gn',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Guiné-Bissau',
    dialCode: '+245',
    flag: 'gw',
    formattedNumber: '999 999 999',
  },
  { name: 'Guiana', dialCode: '+592', flag: 'gy', formattedNumber: '999 9999' },
  { name: 'Haiti', dialCode: '+509', flag: 'ht', formattedNumber: '999 9999' },
  {
    name: 'Honduras',
    dialCode: '+504',
    flag: 'hn',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Hungria',
    dialCode: '+36',
    flag: 'hu',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Islândia',
    dialCode: '+354',
    flag: 'is',
    formattedNumber: '999 9999',
  },
  {
    name: 'Índia',
    dialCode: '+91',
    flag: 'in',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Indonésia',
    dialCode: '+62',
    flag: 'id',
    formattedNumber: '999 999 9999',
  },
  { name: 'Irã', dialCode: '+98', flag: 'ir', formattedNumber: '999 999 9999' },
  {
    name: 'Iraque',
    dialCode: '+964',
    flag: 'iq',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Irlanda',
    dialCode: '+353',
    flag: 'ie',
    formattedNumber: '999 9999',
  },
  {
    name: 'Israel',
    dialCode: '+972',
    flag: 'il',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Itália',
    dialCode: '+39',
    flag: 'it',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Jamaica',
    dialCode: '+1-876',
    flag: 'jm',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Japão',
    dialCode: '+81',
    flag: 'jp',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Jordânia',
    dialCode: '+962',
    flag: 'jo',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Cazaquistão',
    dialCode: '+7',
    flag: 'kz',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Quênia',
    dialCode: '+254',
    flag: 'ke',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    flag: 'ki',
    formattedNumber: '999 9999',
  },
  {
    name: 'Coreia do Norte',
    dialCode: '+850',
    flag: 'kp',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Coreia do Sul',
    dialCode: '+82',
    flag: 'kr',
    formattedNumber: '999 999 9999',
  },
  { name: 'Kuwait', dialCode: '+965', flag: 'kw', formattedNumber: '999 9999' },
  {
    name: 'Quirguistão',
    dialCode: '+996',
    flag: 'kg',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Laos',
    dialCode: '+856',
    flag: 'la',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Letônia',
    dialCode: '+371',
    flag: 'lv',
    formattedNumber: '999 9999',
  },
  {
    name: 'Líbano',
    dialCode: '+961',
    flag: 'lb',
    formattedNumber: '999 999 999',
  },
  { name: 'Lesoto', dialCode: '+266', flag: 'ls', formattedNumber: '999 9999' },
  {
    name: 'Libéria',
    dialCode: '+231',
    flag: 'lr',
    formattedNumber: '999 9999',
  },
  {
    name: 'Líbia',
    dialCode: '+218',
    flag: 'ly',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    flag: 'li',
    formattedNumber: '999 999',
  },
  {
    name: 'Lituânia',
    dialCode: '+370',
    flag: 'lt',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Luxemburgo',
    dialCode: '+352',
    flag: 'lu',
    formattedNumber: '999 9999',
  },
  {
    name: 'Madagáscar',
    dialCode: '+261',
    flag: 'mg',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    flag: 'mw',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Malásia',
    dialCode: '+60',
    flag: 'my',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Maldivas',
    dialCode: '+960',
    flag: 'mv',
    formattedNumber: '999 9999',
  },
  {
    name: 'Mali',
    dialCode: '+223',
    flag: 'ml',
    formattedNumber: '999 999 999',
  },
  { name: 'Malta', dialCode: '+356', flag: 'mt', formattedNumber: '999 9999' },
  {
    name: 'Ilhas Marshall',
    dialCode: '+692',
    flag: 'mh',
    formattedNumber: '999 9999',
  },
  {
    name: 'Mauritânia',
    dialCode: '+222',
    flag: 'mr',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Maurício',
    dialCode: '+230',
    flag: 'mu',
    formattedNumber: '999 9999',
  },
  {
    name: 'México',
    dialCode: '+52',
    flag: 'mx',
    formattedNumber: '(99) 9999-9999',
  },
  {
    name: 'Micronésia',
    dialCode: '+691',
    flag: 'fm',
    formattedNumber: '999 9999',
  },
  {
    name: 'Moldávia',
    dialCode: '+373',
    flag: 'md',
    formattedNumber: '999 999 999',
  },
  { name: 'Mônaco', dialCode: '+377', flag: 'mc', formattedNumber: '999 9999' },
  {
    name: 'Mongólia',
    dialCode: '+976',
    flag: 'mn',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    flag: 'me',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Marrocos',
    dialCode: '+212',
    flag: 'ma',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Moçambique',
    dialCode: '+258',
    flag: 'mz',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Mianmar',
    dialCode: '+95',
    flag: 'mm',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Namíbia',
    dialCode: '+264',
    flag: 'na',
    formattedNumber: '999 999 999',
  },
  { name: 'Nauru', dialCode: '+674', flag: 'nr', formattedNumber: '999 9999' },
  {
    name: 'Nepal',
    dialCode: '+977',
    flag: 'np',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Países Baixos',
    dialCode: '+31',
    flag: 'nl',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Nova Zelândia',
    dialCode: '+64',
    flag: 'nz',
    formattedNumber: '(9) 9999 9999',
  },
  {
    name: 'Nicarágua',
    dialCode: '+505',
    flag: 'ni',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Níger',
    dialCode: '+227',
    flag: 'ne',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Nigéria',
    dialCode: '+234',
    flag: 'ng',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Macedônia do Norte',
    dialCode: '+389',
    flag: 'mk',
    formattedNumber: '999 999 999',
  },
  { name: 'Noruega', dialCode: '+47', flag: 'no', formattedNumber: '999 9999' },
  { name: 'Omã', dialCode: '+968', flag: 'om', formattedNumber: '999 9999' },
  {
    name: 'Paquistão',
    dialCode: '+92',
    flag: 'pk',
    formattedNumber: '999 999 9999',
  },
  { name: 'Palau', dialCode: '+680', flag: 'pw', formattedNumber: '999 9999' },
  {
    name: 'Panamá',
    dialCode: '+507',
    flag: 'pa',
    formattedNumber: '9999 9999',
  },
  {
    name: 'Papua-Nova Guiné',
    dialCode: '+675',
    flag: 'pg',
    formattedNumber: '999 9999',
  },
  {
    name: 'Paraguai',
    dialCode: '+595',
    flag: 'py',
    formattedNumber: '999 999 999',
  },
  { name: 'Peru', dialCode: '+51', flag: 'pe', formattedNumber: '999 999 999' },
  {
    name: 'Filipinas',
    dialCode: '+63',
    flag: 'ph',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Polônia',
    dialCode: '+48',
    flag: 'pl',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    flag: 'pt',
    formattedNumber: '999 999 999',
  },
  { name: 'Catar', dialCode: '+974', flag: 'qa', formattedNumber: '999 9999' },
  {
    name: 'Romênia',
    dialCode: '+40',
    flag: 'ro',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Rússia',
    dialCode: '+7',
    flag: 'ru',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Ruanda',
    dialCode: '+250',
    flag: 'rw',
    formattedNumber: '999 999 999',
  },
  {
    name: 'São Cristóvão e Nevis',
    dialCode: '+1-869',
    flag: 'kn',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Santa Lúcia',
    dialCode: '+1-758',
    flag: 'lc',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'São Vicente e Granadinas',
    dialCode: '+1-784',
    flag: 'vc',
    formattedNumber: '(999) 999 9999',
  },
  { name: 'Samoa', dialCode: '+685', flag: 'ws', formattedNumber: '999 9999' },
  {
    name: 'San Marino',
    dialCode: '+378',
    flag: 'sm',
    formattedNumber: '999 9999',
  },
  {
    name: 'São Tomé e Príncipe',
    dialCode: '+239',
    flag: 'st',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Arábia Saudita',
    dialCode: '+966',
    flag: 'sa',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    flag: 'sn',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Sérvia',
    dialCode: '+381',
    flag: 'rs',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Seicheles',
    dialCode: '+248',
    flag: 'sc',
    formattedNumber: '999 9999',
  },
  {
    name: 'Serra Leoa',
    dialCode: '+232',
    flag: 'sl',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Singapura',
    dialCode: '+65',
    flag: 'sg',
    formattedNumber: '999 9999',
  },
  {
    name: 'Eslováquia',
    dialCode: '+421',
    flag: 'sk',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Eslovênia',
    dialCode: '+386',
    flag: 'si',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Ilhas Salomão',
    dialCode: '+677',
    flag: 'sb',
    formattedNumber: '999 9999',
  },
  {
    name: 'Somália',
    dialCode: '+252',
    flag: 'so',
    formattedNumber: '999 999 999',
  },
  {
    name: 'África do Sul',
    dialCode: '+27',
    flag: 'za',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Sudão do Sul',
    dialCode: '+211',
    flag: 'ss',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Espanha',
    dialCode: '+34',
    flag: 'es',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    flag: 'lk',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Sudão',
    dialCode: '+249',
    flag: 'sd',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    flag: 'sr',
    formattedNumber: '999 9999',
  },
  {
    name: 'Suécia',
    dialCode: '+46',
    flag: 'se',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Suíça',
    dialCode: '+41',
    flag: 'ch',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Síria',
    dialCode: '+963',
    flag: 'sy',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    flag: 'tw',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Tajiquistão',
    dialCode: '+992',
    flag: 'tj',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Tanzânia',
    dialCode: '+255',
    flag: 'tz',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Tailândia',
    dialCode: '+66',
    flag: 'th',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    flag: 'tl',
    formattedNumber: '999 9999',
  },
  {
    name: 'Togo',
    dialCode: '+228',
    flag: 'tg',
    formattedNumber: '999 999 999',
  },
  { name: 'Tonga', dialCode: '+676', flag: 'to', formattedNumber: '999 9999' },
  {
    name: 'Trinidad e Tobago',
    dialCode: '+1-868',
    flag: 'tt',
    formattedNumber: '(999) 999 9999',
  },
  {
    name: 'Tunísia',
    dialCode: '+216',
    flag: 'tn',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Turquia',
    dialCode: '+90',
    flag: 'tr',
    formattedNumber: '999 999 9999',
  },
  {
    name: 'Turcomenistão',
    dialCode: '+993',
    flag: 'tm',
    formattedNumber: '999 999 999',
  },
  { name: 'Tuvalu', dialCode: '+688', flag: 'tv', formattedNumber: '999 9999' },
  {
    name: 'Uganda',
    dialCode: '+256',
    flag: 'ug',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Ucrânia',
    dialCode: '+380',
    flag: 'ua',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Emirados Árabes Unidos',
    dialCode: '+971',
    flag: 'ae',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Reino Unido',
    dialCode: '+44',
    flag: 'gb',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Estados Unidos',
    dialCode: '+1',
    flag: 'us',
    formattedNumber: '(999) 999-9999',
  },
  {
    name: 'Uruguai',
    dialCode: '+598',
    flag: 'uy',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Uzbequistão',
    dialCode: '+998',
    flag: 'uz',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    flag: 'vu',
    formattedNumber: '999 9999',
  },
  {
    name: 'Cidade do Vaticano',
    dialCode: '+379',
    flag: 'va',
    formattedNumber: '999 9999',
  },
  {
    name: 'Venezuela',
    dialCode: '+58',
    flag: 've',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Vietnã',
    dialCode: '+84',
    flag: 'vn',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Iêmen',
    dialCode: '+967',
    flag: 'ye',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Zâmbia',
    dialCode: '+260',
    flag: 'zm',
    formattedNumber: '999 999 999',
  },
  {
    name: 'Zimbábue',
    dialCode: '+263',
    flag: 'zw',
    formattedNumber: '999 999 999',
  },
];
