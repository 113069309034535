import { FaShoppingBasket } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Card,
  Heading,
  HStack,
  useDisclosure,
  Text,
  Collapse,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { Order } from '../../../types/Order';
import { MoneyUtils } from '../../../utils/money.utils';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface CardOrderProps {
  order: Order;
}

const CardOrder = ({ order }: CardOrderProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Card maxW="xl" boxShadow="md" p={4}>
      <HStack spacing={4} align="flex-start">
        <Avatar bg="black" color="white" icon={<FaShoppingBasket />} />
        <Box flex="1">
          <Heading size="sm" mb={1}>
            Pedido em{' '}
            {format(new Date(order.createdAt), 'dd/MM/yyyy', { locale: ptBR })}
          </Heading>
          {order.coupon && (
            <HStack>
              <Text>Cupom:</Text>
              <Text fontWeight="bold">{order.coupon}</Text>
            </HStack>
          )}
          {order.status && (
            <HStack>
              <Text>Status:</Text>
              <Text fontWeight="bold">{order.status}</Text>
            </HStack>
          )}
          <HStack>
            <Text>Quantidade total de itens:</Text>
            <Text fontWeight="bold">{order.totalItemsQuantity}</Text>
          </HStack>

          <HStack justify="space-between" align="center" mt={2}>
            <HStack>
              <Text>Total:</Text>
              <Text fontWeight="bold">
                {MoneyUtils.formatCurrency(order.value)}
              </Text>
            </HStack>

            <IconButton
              bg="gray.300"
              borderRadius="10%"
              icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              size="lg"
              colorScheme="black"
              onClick={onToggle}
              aria-label={isOpen ? 'Esconder Itens' : 'Mostrar Itens'}
              variant="link"
            />
          </HStack>

          <Collapse in={isOpen} animateOpacity>
            <VStack align="stretch" mt={3} spacing={2}>
              {order.orderItems.map((item, index) => (
                <HStack key={index} justify="space-between" w="100%">
                  <Text>{item.product.name}</Text>
                  <Text>x {item.quantity}</Text>
                </HStack>
              ))}
            </VStack>
          </Collapse>
        </Box>
      </HStack>
    </Card>
  );
};

export default CardOrder;
