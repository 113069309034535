import { startOfDay, endOfDay, format } from 'date-fns';
import { Button, useBoolean, useToast } from '@chakra-ui/react';
import { TbTableExport } from 'react-icons/tb';
import useDownloadFile from '../../../../../hooks/useDownloadFile';
import { ReportsService } from '../../../../../services/reports.service';
import { colors } from '../../../../../constants/colors';

interface ButtonExportReportsProps {
  startDate: Date | null;
  endDate: Date | null;
  isDisabled: boolean;
}

export default function ButtonExportReports({
  startDate,
  endDate,
  isDisabled,
}: ButtonExportReportsProps) {
  const { downloadExcelReport } = useDownloadFile();
  const [isExporting, setIsExporting] = useBoolean(false);
  const toast = useToast();

  async function handleClickExportCampaigns() {
    if (!startDate || !endDate) return;
    setIsExporting.on();
    try {
      const { data } = await ReportsService.exportAttendanceMetricsReport(
        startOfDay(startDate).toISOString(),
        endOfDay(endDate).toISOString(),
      );
      const formattedDate = format(new Date(), 'dd_MM_yyyy_HH_mm_ss');
      downloadExcelReport(data, `metricas_${formattedDate}.xlsx`);
      toast({
        title: 'Exportação realizada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsExporting.off();
    }
  }

  return (
    <Button
      bgColor={colors.primary}
      color={colors.white}
      leftIcon={<TbTableExport size={20} />}
      onClick={handleClickExportCampaigns}
      isLoading={isExporting}
      isDisabled={isDisabled}
    >
      Exportar
    </Button>
  );
}
