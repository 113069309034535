import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  Checkbox,
  FormControl,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { CustomersService } from '../../../../services/customers.service';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../constants/colors';
import { useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';

interface DeleteCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUsers: Record<string, boolean>;
  redirectTo: string;
}

export const DeleteCustomerModal = ({
  isOpen,
  onClose,
  selectedUsers,
  redirectTo,
}: DeleteCustomerModalProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const selectedUserCount = Object.values(selectedUsers).filter(Boolean).length;
  const [blockCustomers, setBlockCustomers] = useState(false);

  const deleteCustomersMutation = useMutation(
    (data: { customerIds: string[]; blockCustomers: boolean }) =>
      CustomersService.deleteCustomers(data),
    {
      onSuccess: () => {
        toast({
          title: 'Cliente(s) Excluído(s)',
          description: `${selectedUserCount} cliente${selectedUserCount > 1 ? 's' : ''} excluído${selectedUserCount > 1 ? 's' : ''} com sucesso.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
        navigate(redirectTo, { replace: true });
        navigate(0);
      },
      onError: () => {
        toast({
          title: 'Erro ao Excluir Cliente(s)',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const handleClickSubmit = () => {
    const userIds = Object.keys(selectedUsers).filter(
      (userId) => selectedUsers[userId],
    );

    if (userIds.length < 1) {
      toast({
        title: 'Erro',
        description: 'Selecione pelo menos 1 cliente para excluir.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const data = {
      customerIds: userIds,
      blockCustomers,
    };

    deleteCustomersMutation.mutate(data);
  };

  const isConfirmDisabled = selectedUserCount === 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Excluir Cliente{selectedUserCount > 1 ? 's' : ''}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg" paddingBottom={4}>
            {'Tem certeza de que deseja excluir '}
            <Text as="strong" fontWeight="bold">
              {selectedUserCount}
            </Text>
            {` cliente${selectedUserCount > 1 ? 's' : ''}? Esta ação é permanente e não pode ser desfeita.`}
          </Text>
          <FormControl display="flex" alignItems="center" my={4}>
            <Checkbox
              isChecked={blockCustomers}
              onChange={(e) => setBlockCustomers(e.target.checked)}
              colorScheme="red"
              icon={<CheckIcon />}
              mr={4}
            />
            <Text
              as="span"
              fontWeight="medium"
              onClick={() => setBlockCustomers((prev) => !prev)}
            >
              Impedir {selectedUserCount > 1 ? 'clientes' : 'cliente'} de{' '}
              {selectedUserCount > 1 ? 'receberem' : 'receber'} campanhas
              futuras.
            </Text>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Flex width="100%" justify="space-between" gap={4}>
            <Button
              variant="outline"
              onClick={onClose}
              isDisabled={deleteCustomersMutation.isLoading}
            >
              Cancelar
            </Button>
            <Button
              isDisabled={
                isConfirmDisabled || deleteCustomersMutation.isLoading
              }
              onClick={handleClickSubmit}
              isLoading={deleteCustomersMutation.isLoading}
              variant="solid"
              bgColor={colors.red}
              color="white"
              _hover={{ bgColor: colors.red }}
            >
              Confirmar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
