import { FormDataUtils } from './../utils/form-data.utils';
import { AxiosResponse } from 'axios';
import { Edge, Node } from 'reactflow';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { Flow } from '../types/Flow';
import { FlowNode } from '../types/FlowNode';
import { FlowTrigger } from '../types/FlowTrigger';

export interface CreateFlowDto extends Record<any, any> {}

type CreateFlowResponse = Flow;
const createFlow = async (
  createFlowDto: CreateFlowDto,
): Promise<AxiosResponse<CreateFlowResponse>> => {
  return request.post(apiRoutes.createFlow(), createFlowDto);
};

export interface UpdateFlowDto {
  title: string;
  type: string;
  isActive: boolean;
  nodes: Node[];
  edges: Edge[];
  flowTriggers: any[];
}

type UpdateFlowResponse = ShowFlowResponse;
const updateFlow = async (
  flowId: string,
  updateFlowDto: UpdateFlowDto,
): Promise<AxiosResponse<UpdateFlowResponse>> => {
  const data = FormDataUtils.convertJsonToFormData(updateFlowDto);
  return request.put(apiRoutes.updateFlow(flowId), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 120000,
  });
};

export interface ListFlowItem extends Flow {
  flowNodes: FlowNode[];
  flowTriggers: FlowTrigger[];
}

const listFlows = async (): Promise<AxiosResponse<ListFlowItem[]>> => {
  return request.get(apiRoutes.listFlows());
};

export interface ShowFlowResponse extends Flow {
  flowNodes: FlowNode[];
  flowTriggers: FlowTrigger[];
}
const showFlow = async (
  flowId: string,
): Promise<AxiosResponse<ShowFlowResponse>> => {
  return request.get(apiRoutes.showFlow(flowId));
};

const deleteFlow = async (flowId: string): Promise<AxiosResponse> => {
  return request.delete(apiRoutes.deleteFlow(flowId));
};

export const FlowsService = {
  createFlow,
  updateFlow,
  listFlows,
  showFlow,
  deleteFlow,
};
