import {
  Box,
  Flex,
  Image,
  Progress,
  Text,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import {
  FaCheck,
  FaCheckDouble,
  FaCloudDownloadAlt,
  FaRegClock,
  FaChevronDown,
} from 'react-icons/fa';

import { VscError } from 'react-icons/vsc';
import { useQuery } from 'react-query';
import reactStringReplace from 'react-string-replace';
import { apiRoutes } from '../../constants/api-routes';
import { colors } from '../../constants/colors';
import { screenSizes } from '../../constants/screen-sizes';
import useDownloadFile from '../../hooks/useDownloadFile';
import { Button, MessageTemplatesService } from '../../services/message-templates.service';
import { MessagesService } from '../../services/messages.service';
import { MediaType, MessageStatus } from '../../types/Message';
import WhatsappMessageItem from '../WhatsappMessageItem';

import { ListConversationDetailedItem } from '../../types/Conversation';
import { FlowNodeButtonsService } from '../../services/flow-node-buttons.service';
import { ButtonType } from '../../types/ButtonTypeEnum';

const StatusIcon = ({
  status,
  size,
}: {
  status: MessageStatus;
  size: number;
}) => {
  if (status === 'sent' || status === 'enqueued') {
    return <FaCheck size={size} />;
  }
  if (status === 'delivered') {
    return <FaCheckDouble size={size} />;
  }
  if (status === 'read') {
    return <FaCheckDouble size={size} color={colors.green} />;
  }
  if (status === 'failed' || status === 'mismatch') {
    return <VscError size={size} />;
  }

  return <FaRegClock size={size} />;
};

interface MediaItemProps {
  mediaType: MediaType;
  mediaUrl: string;
  onClickSave: (mediaUrl: string) => void;
  fileName: string;
  bgColor?: string;
}

interface UploadProgressProps {
  progress: number;
}

interface DownloadAreaProps {
  bgColor: string;
  text: string;
  onClick: () => void;
}
const DownloadArea = ({ bgColor, text, onClick }: DownloadAreaProps) => {
  return (
    <Flex
      flexDir={'column'}
      bgColor={bgColor}
      borderRadius={5}
      padding={2}
      justify="center"
      align={'center'}
      onClick={onClick}
      cursor="pointer"
    >
      <FaCloudDownloadAlt size={32} />
      {text}
    </Flex>
  );
};

const MediaItem = ({
  mediaUrl,
  onClickSave,
  mediaType,
  fileName,
  bgColor = colors.middleGrey,
}: MediaItemProps) => {
  return mediaType === 'image' ? (
    <Box>
      <Image
        src={mediaUrl}
        onClick={() => onClickSave(mediaUrl)}
        cursor="pointer"
        position="relative"
        width={'300px'}
      />
    </Box>
  ) : mediaType === 'audio' ? (
    <Flex>
      <audio controls src={mediaUrl}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </Flex>
  ) : (
    <DownloadArea
      bgColor={bgColor}
      onClick={() => onClickSave(mediaUrl)}
      text={fileName}
    />
  );
};

const UploadProgress = ({ progress }: UploadProgressProps) => {
  const isDesktop = useMediaQuery(screenSizes.desktop)[0];
  const processing = progress === 100;

  return (
    <Box width="100%" p={4} paddingTop={2} borderRadius="md" bg="white">
      <Flex alignItems="center" justifyContent="space-between" mb={1}>
        <Text fontSize="14px">
          {progress > 0 && progress < 100
            ? 'enviando mídia '
            : 'processando mídia '}
          ...
        </Text>
      </Flex>
      <Progress
        value={progress}
        size="sm"
        colorScheme={processing ? 'blue' : 'teal'}
        borderRadius="md"
        max={100}
        isIndeterminate={!isDesktop || processing}
      />
    </Box>
  );
};

const pulseAnimation = `
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.7);
    }
    70% {
      box-shadow: 0 0 0 5px rgba(255, 215, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
    }
  }
`;

interface MessageItemProps {
  messageId: string;
  bgColor: string;
  contextMessageBgColor?: string;
  status: MessageStatus;
  text: string;
  textColor: string;
  createdAt: string;
  isFromSystem: boolean;
  mediaType?: MediaType | null;
  mediaId?: string | null;
  fileKey?: string | null;
  mediaUrl?: string | null;
  messageTemplateId?: string | null;
  uploadProgress?: number;
  contextMessage?: ListConversationDetailedItem;
  isContextMessage?: boolean;
  isHighlighted?: boolean;
  flowNodeId?: string | null;
  onHighlight?: (messageId: string) => void;
  onClickReply?: (messageId: string) => void;
}

const MessageItem = ({
  messageId,
  bgColor,
  status,
  text,
  textColor,
  createdAt,
  isFromSystem,
  mediaType,
  mediaId,
  fileKey,
  mediaUrl: initialMediaUrl,
  messageTemplateId,
  flowNodeId,
  uploadProgress = 0,
  contextMessage,
  isContextMessage,
  isHighlighted,
  onHighlight,
  onClickReply,
}: MessageItemProps) => {
  const [mediaUrl, setMediaUrl] = useState(initialMediaUrl);
  const { downloadFileFromUrl } = useDownloadFile();
  const optionsMenu = useDisclosure();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleClickMenuTrigger = (event: React.MouseEvent) => {
    event.preventDefault();
    optionsMenu.onClose();
    setPosition({ x: event.pageX, y: event.pageY });
    optionsMenu.onOpen();
  };

  const handleClickReply = () => {
    onClickReply?.(messageId);
  };

  const messageTemplates = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
      enabled: !messageTemplateId,
    },
  );
  const messageTemplate = messageTemplates.data?.find(
    (template) => template.id === messageTemplateId,
  );

  const { data: flowNodeButtons } = useQuery(
    apiRoutes.listFlowNodeButtons(flowNodeId ?? ''),
    async () => {
      if (!flowNodeId) return [];
      const { data } = await FlowNodeButtonsService.listFlowNodeButtons(flowNodeId);
      return data.map((button) => ({
        type: button.type as ButtonType,
        text: button.text,
        url: button.url ?? undefined,
      }))
    },
    {
      staleTime: 43200000 // 12 hours
    }
  );

  const handleDownload = useCallback(() => {
    if (mediaUrl) return;
    MessagesService.downloadMedia({
      mediaId: mediaId!,
      mediaName: text,
      fileKey: fileKey!,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const haveMediaItemComponent = ['image', 'audio'].includes(mediaType!);
      if (haveMediaItemComponent) {
        setMediaUrl(url);
      } else {
        downloadFileFromUrl(url, text);
      }
    });
  }, [downloadFileFromUrl, fileKey, mediaId, mediaType, mediaUrl, text]);

  const hasContextMessage = contextMessage?.text;
  const shouldShowOptionsMenu = !isContextMessage && !isFromSystem;

  return (
    <>
      {optionsMenu.isOpen && (
        <Menu isOpen={true} onClose={() => optionsMenu.onClose()}>
          <MenuButton
            as={Box}
            position="absolute"
            left={`${position.x}px`}
            top={`${position.y}px`}
          />
          <MenuList>
            <MenuItem onClick={handleClickReply}>Responder</MenuItem>
          </MenuList>
        </Menu>
      )}
      <Flex
        direction="column"
        gap={1}
        alignItems={isFromSystem ? 'flex-end' : 'flex-start'}
        width="100%"
        position="relative"
      >
        <Flex
          maxWidth={isContextMessage ? '100%' : '75%'}
          width={isContextMessage ? '100%' : 'auto'}
          background={bgColor}
          color={textColor}
          borderRadius="15px"
          alignItems="flex-end"
          position="relative"
          gap={messageTemplate ? 2 : 0}
          direction="column"
          borderLeft={isContextMessage ? '4px solid #00000055' : 'none'}
          css={isHighlighted ? pulseAnimation : ''}
          animation={isHighlighted ? 'pulse 1s 10' : 'none'}
          _hover={{ '.chevron-icon': { display: 'block' } }}
        >
          {shouldShowOptionsMenu && (
            <Box
              className="chevron-icon"
              position="absolute"
              top={hasContextMessage ? 2 : 1}
              right={2}
              display="none"
              bgColor="inherit"
              borderRadius="50%"
              p={1}
              zIndex={1}
              onClick={handleClickMenuTrigger}
              cursor={'pointer'}
            >
              <FaChevronDown size={10} />
            </Box>
          )}
          {hasContextMessage && (
            <Box
              width="100%"
              padding={1}
              opacity={0.8}
              borderRadius="inherit"
              cursor="pointer"
              onClick={() => onHighlight?.(contextMessage.id)}
              position="relative"
            >
              <MessageItem
                isContextMessage
                messageId={contextMessage.id}
                bgColor={bgColor}
                status={contextMessage.status || 'sent'}
                text={contextMessage.text || ''}
                textColor={textColor}
                createdAt={contextMessage.createdAt || '0'}
                isFromSystem={!!contextMessage.fromSystem}
              />
            </Box>
          )}
          {isContextMessage && (
            <Box
              bgColor="rgba(0, 0, 0, 0.1)"
              position="absolute"
              borderRadius="inherit"
              width="100%"
              height="100%"
            />
          )}
          <Flex width="100%" padding={2} direction="column">
            {isContextMessage && (
              <Text fontSize={12} marginBottom={1} fontWeight={'bold'}>
                {isFromSystem ? 'Atendente' : 'Cliente'}
              </Text>
            )}
            <Flex>
              {uploadProgress > 0 ? (
                <UploadProgress progress={uploadProgress} />
              ) : messageTemplate ? (
                <WhatsappMessageItem
                  fileUrl={mediaUrl}
                  buttons={messageTemplate.messageTemplateButtons}
                  footer={messageTemplate.footerText}
                  limitedOfferText={messageTemplate.limitedOfferText}
                  limitedOfferExpirationDate={messageTemplate.limitedOfferExpirationDate?.toString()}
                  message={text}
                  messageTemplateCards={messageTemplate.messageTemplateCards}
                />
              ) : flowNodeButtons?.length ? (
                  <WhatsappMessageItem
                  buttons={flowNodeButtons}
                  message={text}
                />
              ) : mediaId || fileKey || mediaUrl ? (
                mediaUrl && mediaType ? (
                  <MediaItem
                    bgColor={'white'}
                    mediaType={mediaType}
                    mediaUrl={mediaUrl}
                    onClickSave={() => downloadFileFromUrl(mediaUrl, text)}
                    fileName={text}
                  />
                ) : (
                  <DownloadArea
                    bgColor={'white'}
                    onClick={handleDownload}
                    text={text}
                  />
                )
              ) : (
                <Text
                  maxWidth={'100%'}
                  whiteSpace="pre-line"
                  fontSize={isContextMessage ? 12 : 16}
                >
                  {reactStringReplace(text, /\*(.*?)\*/, (match, i) => (
                    <Text as="span" fontWeight="bold" key={i}>
                      {match}
                    </Text>
                  ))}
                  {Array(16).fill('\u00A0').join('')}
                </Text>
              )}
              {!isContextMessage && (
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={1}
                  position={messageTemplate ? 'relative' : 'absolute'}
                  right={messageTemplate ? '0px' : 2}
                  bottom={messageTemplate ? '0px' : 2}
                >
                  <Text fontSize={8}>
                    {format(new Date(createdAt), 'dd/MM - HH:mm')}
                  </Text>
                  {isFromSystem && <StatusIcon status={status} size={8} />}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default MessageItem;
