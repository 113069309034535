import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useFileValidation from '../../../../hooks/useFileValidation';
import {
  MessagesService,
  SendMessageDto,
} from '../../../../services/messages.service';
import {
  addMessagesToConversation,
  getMessageByTempIdAndConversationId,
  updateMessageByTempId,
  updateMessageStatusByTempId,
  updateMessageUploadProgressByTempId,
} from '../../../../state/inboxSlice';
import { AppDispatch, store } from '../../../../state/store';
import { v4 as uuidv4 } from 'uuid';
import { FileValidationUtils } from '../../../../utils/file-validation.utils';
import { FaPaperclip, FaTimes } from 'react-icons/fa';
import { colors } from '../../../../constants/colors';
import { useSendTemplateModal } from '../../../../hooks/useSendTemplateModal';
import { ConversationWithIncludes } from '../../../../types/Conversation';
import { apiRoutes } from '../../../../constants/api-routes';
import { QuickRepliesService } from '../../../../services/quick-replies.service';
import InputButtons from './InputButtons';
import { BsFillEmojiSmileFill } from 'react-icons/bs';
import CustomEmojiPicker from '../../../../components/CustomEmojiPicker';
import MessageItem from '../../../../components/MessageItem';
import { MessageWithCardsIncludes } from '../../../../types/Message';

interface InputAreaProps {
  conversation: ConversationWithIncludes;
  isDesktop: boolean;
  contextMessage?: MessageWithCardsIncludes | null;
  onClearContextMessage?: () => void;
}

const InputArea = ({
  conversation,
  isDesktop,
  contextMessage,
  onClearContextMessage,
}: InputAreaProps) => {
  const [searchParams] = useSearchParams();
  const { openModal: openSendTemplateModal } = useSendTemplateModal();
  const { data: quickReplies } = useQuery(
    apiRoutes.listQuickReplies(),
    async () => {
      const { data } = await QuickRepliesService.listQuickReplies();
      return data;
    },
    {
      staleTime: 1000 * 60 * 10,
    },
  );
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const fileInputRef = useRef(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const conversationId = searchParams.get('conversationId');
  const [text, setText] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const { validateFile } = useFileValidation();

  function updateUploadProgress(tempId: string, uploadProgress: number) {
    dispatch(
      updateMessageUploadProgressByTempId({
        conversationId: conversationId!,
        tempId,
        uploadProgress,
      }),
    );
  }

  const sendMessage = useMutation(
    async (sendMessageDto: SendMessageDto) => {
      const { data } = await MessagesService.sendMessage(sendMessageDto);
      return data;
    },
    {
      onMutate: async (sendMessageDto) => {
        dispatch(
          addMessagesToConversation({
            conversationId: conversationId!,
            messages: [
              {
                id: '',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                tempId: sendMessageDto.tempId,
                text: sendMessageDto.text,
                senderPhoneNumberId: '',
                recipientPhoneNumberId: '',
                conversationId: conversationId!,
                fromSystem: true,
                status: 'enqueued',
                whatsappMessageId: null,
                mediaId: null,
                mediaUrl: null,
                mediaType: null,
                fileKey: null,
                messageTemplateId: null,
                whatsappCampaignId: null,
                firstReplyId: null,
                errorCode: null,
                errorMessage: null,
                automationId: null,
                uploadProgress: sendMessageDto.file ? 1 : 0,
                flowNodeId: null,
                contextMessageId: sendMessageDto.contextMessageId,
                context: { ...contextMessage },
              },
            ],
          }),
        );
        setText('');
      },
      onError: (error: any, sendMessageDto) => {
        const message = getMessageByTempIdAndConversationId(
          store.getState(),
          conversationId!,
          sendMessageDto.tempId,
        );
        if (
          [504, 0].includes(error?.response?.status) &&
          message?.uploadProgress === 100
        ) {
          return;
        }
        dispatch(
          updateMessageStatusByTempId({
            conversationId: conversationId!,
            tempId: sendMessageDto.tempId,
            status: 'failed',
          }),
        );
        updateUploadProgress(sendMessageDto.tempId, 0);
      },
      onSuccess: (data, sendMessageDto) => {
        dispatch(
          updateMessageByTempId({
            conversationId: conversationId!,
            tempId: sendMessageDto.tempId,
            message: { ...data },
          }),
        );
      },
    },
  );

  function handleSubmitMessage(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (text.trim() === '') return;
    onClearContextMessage?.();
    sendMessage.mutate({
      text,
      conversationId: conversationId!,
      tempId: uuidv4(),
      contextMessageId: contextMessage?.id,
    });
  }

  function buildInputButtonGroup() {
    return (
      <InputButtons
        currentText={text}
        onSend={handleSendAudio}
        isDesktop={isDesktop}
      />
    );
  }

  function handleTextareaKeyDown(e: any) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitMessage(e);
    }
  }

  function handleFileChange(event: any) {
    const file = event.target.files && event.target.files[0];

    const isValidFile = validateFile(file);
    if (isValidFile) {
      const tempId = uuidv4();

      sendMessage.mutate({
        text: file.name,
        conversationId: conversationId!,
        contextMessageId: contextMessage?.id,
        tempId: tempId,
        file,
        onProgress: (progress: number) => {
          updateUploadProgress(tempId, progress);
        },
      });
    }

    event.target.value = null;
  }

  function handleSendAudio(file: File) {
    const isValidFile = validateFile(file);
    if (isValidFile) {
      sendMessage.mutate({
        text: file.name,
        conversationId: conversationId!,
        tempId: uuidv4(),
        contextMessageId: contextMessage?.id,
        file,
      });
    }
    return true;
  }

  const handleEmojiPick = (emoji: string) => {
    const textarea = textareaRef.current! as any;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      emoji +
      currentValue.substring(endPosition);

    setText(newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + emoji.length + 1,
      startPosition + emoji.length + 1,
    );
  };

  const isReplyingToMessage = !!contextMessage;

  return (
    <Box shadow={'md'} borderRadius="md" position="relative">
      {isReplyingToMessage && !sendMessage.isLoading && (
        <Box mb={0.5} position="relative">
          <MessageItem
            messageId={contextMessage.id}
            isContextMessage
            status={contextMessage.status || 'sent'}
            text={contextMessage.text || ''}
            bgColor={
              contextMessage.fromSystem ? colors.blueLight : colors.lightGrey
            }
            textColor={colors.black}
            createdAt={contextMessage.createdAt || '0'}
            isFromSystem={!!contextMessage.fromSystem}
          />
          <Box
            position="absolute"
            top={2}
            right={2}
            cursor="pointer"
            onClick={onClearContextMessage}
          >
            <FaTimes size={10} opacity={0.8} />
          </Box>
        </Box>
      )}
      {(showQuickReplies || text.startsWith('/')) && (
        <Flex
          flexDir="column"
          gap={2}
          position="absolute"
          bottom="100%"
          padding="10px"
          width="100%"
          overflow="scroll"
          maxHeight="60vh"
          boxShadow="inner"
          bg="white"
        >
          {quickReplies
            ?.filter((reply) =>
              reply.title
                .toLowerCase()
                .includes(text.toLowerCase().replace('/', '')),
            )
            .map((reply) => (
              <Box
                boxShadow={'md'}
                padding={2}
                key={reply.id}
                fontSize={12}
                cursor="pointer"
                bgColor="white"
                onClick={() => {
                  setShowQuickReplies(false);
                  setText(reply.text);
                }}
                _hover={{ bgColor: colors.lightGrey }}
              >
                <Text fontWeight={'bold'}>{reply.title}</Text>
                <Text>{reply.text}</Text>
              </Box>
            ))}
        </Flex>
      )}
      <form onSubmit={handleSubmitMessage}>
        <Textarea
          ref={textareaRef}
          sx={{
            '&:focus': {
              outline: 'none',
              boxShadow: 'none',
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
          }}
          border="none"
          pr="4.5rem"
          onPasteCapture={(e) => {
            if (e.clipboardData.files.length > 0) {
              handleFileChange({
                target: { files: [e.clipboardData.files[0]] },
              });
            }
          }}
          _active={{}}
          placeholder="Digite uma mensagem"
          value={text}
          onChange={(e) => {
            if (e.target.value === '') {
              setShowQuickReplies(false);
            }
            setText(e.target.value)
          }}
          onKeyDown={handleTextareaKeyDown}
          resize={text.includes('\n') ? 'vertical' : 'none'}
          mb={2}
        />
        <Flex justifyContent={'space-between'}>
          <Box position="relative">
            <input
              style={{ display: 'none' }}
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              accept={FileValidationUtils.supportedFileTypes.join(', ')}
            />
            <IconButton
              aria-label="Anexar arquivo"
              variant="ghost"
              icon={<FaPaperclip fontSize={16} color={colors.darkGrey} />}
              onClick={() => {
                if (fileInputRef.current) {
                  // @ts-ignore
                  fileInputRef.current.click();
                }
              }}
            />
            <CustomEmojiPicker
              Icon={
                <BsFillEmojiSmileFill fontSize={16} color={colors.darkGrey} />
              }
              renderAs="IconButton"
              emojiPickerAnchorPosition="top"
              emojiPickerGap={10}
              onEmojiSelection={handleEmojiPick}
            />
            <Button
              variant="ghost"
              color={colors.darkGrey}
              onClick={() =>
                openSendTemplateModal({
                  conversation: conversation!,
                  templateTypes: [
                    'INITIAL_MESSAGE',
                    'MARKETING',
                    'REVIEW_REQUEST',
                  ],
                  deliveryType: 'direct',
                })
              }
            >
              Templates Meta
            </Button>
            <Button
              variant="ghost"
              color={colors.darkGrey}
              onClick={() => setShowQuickReplies((prev) => !prev)}
            >
              Respostas rápidas
            </Button>
          </Box>
          {buildInputButtonGroup()}
        </Flex>
      </form>
    </Box>
  );
};

export default InputArea;
