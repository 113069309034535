import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel, useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useVirtual } from "react-virtual";
import { colors } from "../../constants/colors";
import { request } from "../../constants/request";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { TableHeaders } from "../../types/TableHeaders";
import { UrlUtils } from "../../utils/url.utils";
import IndeterminateCheckbox from "../IndeterminateCheckbox";
import LoadingScreen from "../LoadingScreen";
import Pagination from "../Pagination";

const getRowId = (row: any, relativeIndex: any, parent: any) => {
  return parent ? [parent.id, row.uniqueId].join(".") : row.id;
};

interface DataTableServerPaginatedProps {
  headersUrl: string;
  dataUrl: string;
  queryParameters?: Record<string, string | undefined | null>;
  transformHeader?: (column: TableHeaders) => ColumnDef<any>;
  rowSelection?: Record<string, any>;
  onRowSelectionChange?: (selectedRows: Record<string, boolean>) => void;
  showRowSelection?: boolean;
  refetchKey?: number | string;
}

const DataTableServerPaginated = <DataType extends object>({
  headersUrl,
  dataUrl,
  queryParameters,
  transformHeader,
  rowSelection,
  onRowSelectionChange,
  showRowSelection = true,
  refetchKey = 0,
}: DataTableServerPaginatedProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dataUrlWithParams, setDataUrlWithParams] = useState(
    `${dataUrl}?page=${currentPage}&perPage=${rowsPerPage}&${UrlUtils.convertObjectToQueryString(
      queryParameters
    )}`
  );

  const { data: tableHeaders, isFetching: isFetchingHeaders } = useQuery<
    TableHeaders[]
  >(headersUrl, async () => {
    const { data } = await request.get(headersUrl);
    return data;
  });

  const {
    data: tableData,
    refetch: refetchTableData,
    isFetching: isFetchingData,
  } = useQuery<PaginatedResponse<DataType>>(dataUrlWithParams, async () => {
    const { data } = await request.get(dataUrlWithParams, {
      timeout: 1000 * 120,
    });
    return data;
  });

  const getTransformedHeaders = () => {
    if (!tableHeaders) return [];
  
    let headers = tableHeaders?.map((header) => {
      return transformHeader ? transformHeader(header) : header;
    });
  
    headers = [
      {
        id: "select",
        header: (info: any) => {
          if (!info) return <div />;
          const table = info.table;
          return (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllPageRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllPageRowsSelectedHandler(),
              }}
            />
          );
        },
        cell: ({ row }: any) => {
          return (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          );
        },
      },
      ...headers,
    ];
  
    return headers;
  };

  const { getHeaderGroups, getRowModel } = useReactTable({
    getRowId,
    state: {
      rowSelection,
    },
    columns: getTransformedHeaders() || [],
    data: tableData?.data || [],
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: (updater) => {
      if (onRowSelectionChange) {
        if (typeof updater === 'function') {
          const newRowSelection = updater(rowSelection || {});
          onRowSelectionChange(newRowSelection);
        } else {
          onRowSelectionChange(updater);
        }
      }
    }
  });

  const { rows } = getRowModel();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  useEffect(() => {
    refetchTableData();
  }, [refetchTableData, refetchKey]);

  useEffect(() => {
    if (currentPage === 1) return;
    setDataUrlWithParams(
      `${dataUrl}?page=${currentPage}&perPage=${rowsPerPage}&${UrlUtils.convertObjectToQueryString(
        queryParameters
      )}`
    );
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
    setDataUrlWithParams(
      `${dataUrl}?page=${1}&perPage=${rowsPerPage}&${UrlUtils.convertObjectToQueryString(
        queryParameters
      )}`
    );
  }, [dataUrl, rowsPerPage, JSON.stringify(queryParameters), refetchTableData]);

  return (
    <LoadingScreen isLoading={isFetchingData || isFetchingHeaders}>
      <TableContainer
        height={"83vh"}
        ref={tableContainerRef}
        overflowY="scroll">
        <Table>
          <Thead position="sticky" top={0} bgColor={colors.white} zIndex={1}>
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === "asc" ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Box>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {/* {cell.getValue() || cell.column.id === 'isOptedOut'
                        ? flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        : "-"} */}
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        initialPage={currentPage}
        onChangePage={(page) => setCurrentPage(page)}
        rowsPerPage={rowsPerPage}
        totalRows={tableData?.meta.totalItems || 0}
        onChangeRowsPerPage={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
        }}
      />
    </LoadingScreen>
  );
};

function areEqual(
  prevProps: DataTableServerPaginatedProps,
  nextProps: DataTableServerPaginatedProps
): boolean {
  return (
    prevProps.headersUrl === nextProps.headersUrl &&
    prevProps.dataUrl === nextProps.dataUrl &&
    JSON.stringify(prevProps.queryParameters) ===
      JSON.stringify(nextProps.queryParameters) &&
    prevProps.showRowSelection === nextProps.showRowSelection &&
    prevProps.refetchKey === nextProps.refetchKey &&
    prevProps.rowSelection === nextProps.rowSelection
  );
}

export default React.memo(DataTableServerPaginated, areEqual);
