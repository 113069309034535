import * as Sentry from '@sentry/react';

class ErrorService {
  static captureError(error: unknown, context?: Record<string, any>) {
    Sentry.captureException(error, {
      extra: context,
    });
  }

  static captureMessage(
    message: string,
    level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' = 'error',
  ) {
    Sentry.captureMessage(message, {
      level,
    });
  }
}

export default ErrorService;
