import { CategoryWithSector } from '../types/ConversationCategory';

function formatAndSortCategories(conversationCategories: CategoryWithSector[]) {
  return conversationCategories
    ?.map((category) => ({
      id: category.id,
      name: `${category.name} - ${category.conversationSector?.name || 'Geral'}`,
      sector: category.conversationSector?.name || 'Geral',
      pos: category.pos,
    }))
    .sort((a, b) => a.sector.localeCompare(b.sector) || a.pos - b.pos);
}

export const ConversationCategoriesUtils = {
  formatAndSortCategories,
};
