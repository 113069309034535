import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { BsCheckAll } from 'react-icons/bs';
import { MdOutlineCampaign } from 'react-icons/md';
import { TbFileImport } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appPaths } from '../../constants/app-paths';
import {
  finishCampaignCreation,
  setFilterCriteria,
} from '../../state/campaignCreationSlice';
import { RootState } from '../../state/store';
import FilterSidebar from './components/FilterSidebar';
import TableCustomersPaginated from './components/TableCustomersPaginated';
import { UploadFileModal } from './components/UploadFileModal';
import { AddTagModal } from './components/AddTagModal';
import { FaTrashAlt } from 'react-icons/fa';
import { DeleteCustomerModal } from './components/DeleteCustomerModal';
import { LuTags } from 'react-icons/lu';

const CustomersPage = () => {
  const { showSelectCustomerRows, selectedCustomerRows } = useSelector(
    (state: RootState) => state.campaignCreation,
  );
  const dispatch = useDispatch();
  const uploadFileModal = useDisclosure();
  const addTagModal = useDisclosure();
  const deleteCustomerModal = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const shouldFinishCampaignCreationOnUnmountRef = useRef(true);
  const toast = useToast();

  useEffect(() => {
    if (searchParams.toString()) {
      const paramsCount = searchParams.toString().split('&').length;
      toast({
        title: 'Filtros aplicados',
        description: `${paramsCount} filtros aplicados`,
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  function handleClickCancelCampaign() {
    setSearchParams('');
    dispatch(finishCampaignCreation());
    navigate(appPaths.customers.index());
  }

  function handleClickNewCampaign() {
    const newPath = `${appPaths.campaigns.create()}?${searchParams.toString()}`;
    navigate(newPath);
  }

  function handleClickConfirmCustomersSelection() {
    shouldFinishCampaignCreationOnUnmountRef.current = false;
    dispatch(setFilterCriteria(window.location.search.substring(1)));
    navigate(appPaths.campaigns.create());
  }

  useEffect(() => {
    shouldFinishCampaignCreationOnUnmountRef.current = true;
  }, []);

  useEffect(() => {
    return () => {
      if (shouldFinishCampaignCreationOnUnmountRef.current) {
        dispatch(finishCampaignCreation());
      }
    };
  }, [shouldFinishCampaignCreationOnUnmountRef, dispatch]);

  const hasSelectedRows = Object.values(selectedCustomerRows).some(Boolean);

  return (
    <Flex height="100%" flexDir={'column'}>
      <Grid
        templateColumns="300px auto"
        padding="25px"
        paddingLeft="0px"
        alignItems="center"
      >
        <GridItem paddingLeft="25px">
          <Heading>Clientes</Heading>
        </GridItem>

        <GridItem justifySelf="end" width="100%">
          {showSelectCustomerRows ? (
            <Flex
              bottom={'20px'}
              gap={1}
              justifyContent={'space-around'}
              position="absolute"
              right="25px"
              top="25px"
            >
              <Button variant="outline" onClick={handleClickCancelCampaign}>
                Cancelar
              </Button>
              <Button
                leftIcon={<BsCheckAll fontSize="24px" />}
                variant="primary"
                onClick={handleClickConfirmCustomersSelection}
                isDisabled={
                  Object.values(selectedCustomerRows).filter(Boolean).length ===
                  0
                }
              >
                Confirmar
              </Button>
            </Flex>
          ) : (
            <>
              <Grid templateColumns="auto auto" gap={2}>
                <GridItem>
                  {hasSelectedRows && (
                    <>
                      <Tooltip label="Adicionar Tag">
                        <IconButton
                          icon={<LuTags size="20px"/>}
                          aria-label="Adicionar Tag"
                          size="sm"
                          variant='outline'
                          onClick={addTagModal.onOpen}
                          mr={2}
                        />
                      </Tooltip>
                      <Tooltip label="Deletar Cliente">
                        <IconButton
                          icon={<FaTrashAlt fontSize="18px" />}
                          aria-label="Deletar Cliente"
                          size="sm"
                          variant='outline'
                          onClick={deleteCustomerModal.onOpen}
                          mr={4}
                        />
                      </Tooltip>
                    </>
                  )}
                </GridItem>
                <GridItem justifySelf="end">
                  <Flex
                    gap={1}
                    justifyContent="space-around"
                    position="absolute"
                    right="25px"
                    top="25px"
                  >
                    <Button
                      leftIcon={<TbFileImport size="24px" />}
                      variant="outline"
                      onClick={uploadFileModal.onOpen}
                    >
                      Importar arquivo
                    </Button>
                    <Button
                      variant="primary"
                      leftIcon={<MdOutlineCampaign size="24px" />}
                      onClick={handleClickNewCampaign}
                    >
                      Nova Campanha
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns={'300px auto'}
        templateAreas={'"filter-sidebar table"'}
        flexGrow={1}
      >
        <GridItem area="filter-sidebar" boxShadow={'lg'}>
          <FilterSidebar />
        </GridItem>
        <GridItem area="table">
          <TableCustomersPaginated />
        </GridItem>
      </Grid>
      <UploadFileModal
        isOpen={uploadFileModal.isOpen}
        onClose={uploadFileModal.onClose}
      />
      <AddTagModal
        isOpen={addTagModal.isOpen}
        onClose={addTagModal.onClose}
        selectedUsers={selectedCustomerRows}
      />
      <DeleteCustomerModal
        isOpen={deleteCustomerModal.isOpen}
        onClose={deleteCustomerModal.onClose}
        selectedUsers={selectedCustomerRows}
        redirectTo={appPaths.customers.index()}
      />
    </Flex>
  );
};

export default CustomersPage;
