import { Box } from '@chakra-ui/react';
import GlassmorphismContainerProps from './types/glassmorphism-container-props';

const GlassmorphisContainer = ({
  show,
  children,
}: GlassmorphismContainerProps) => {
  return (
    <>
      {show ? (
        <Box position="relative" overflow="hidden">
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(255, 255, 255, 0.15)',
              borderRadius: '16px',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(3px)',
              WebkitBackdropFilter: 'blur(3px)',
              border: '1px solid rgba(255, 255, 255, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '24px',
              fontWeight: 'bold',
              zIndex: 10,
            }}
          />
          <Box position="relative" zIndex={5}>
            {children}
          </Box>
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default GlassmorphisContainer;
