const IntegrationActiveKeyBySourceIntegration = <Record<string, string>>{
  shopify_ecommerce: 'isShopifyActive',
  vtex_ecommerce: 'isVtexActive',
  unbox: 'isUnboxActive',
  visual_ecommerce: 'isVisualECommerceActive',
  loja_integrada: 'isLojaIntegradaActive',
  bling: 'isBlingActive',
  magazord: 'isMagazordActive',
  woo_commerce: 'isWooCommerceActive',
  magento1_ecommerce: 'isMagentoActive',
  magento2_ecommerce: 'isMagentoActive',
  ingresse: 'isIngresseActive',
  cartPanda: 'isCartPandaActive',
  omny: 'isOmnyActive',
  omie: 'isOmieActive',
  tray: 'isTrayActive',
  yampi: 'isYampiActive',
  linx_commerce: 'isLinxCommerceActive',
};

export default IntegrationActiveKeyBySourceIntegration;
