import { Controller, useFieldArray, Control } from "react-hook-form";
import { Box, Button, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import SelectInput from "./components/SelectInput";
import { FaTrash } from "react-icons/fa";
import NumberInput from "./components/NumberInput";
import { FlowNodeConditionBlock } from "../../../../../../../../../types/ReactFlowNode";
import { colors } from "../../../../../../../../../constants/colors";

interface ConditionBlockProps {
  control: Control<{
    flowNodeConditionBlocks: FlowNodeConditionBlock[];
  }, any>;
  block: FlowNodeConditionBlock;
  blockIndex: number;
  appendCondition?: () => void;
  removeBlock: (blockIndex: number) => void;
  errors: any;
  tagOptions: { value: string; label: string }[];
  flowType: string;
}

const FlowNodeConditionBlockJoinTypeOptions = [
  { value: "AND", label: "todas as condições" },
  { value: "OR", label: "qualquer uma das condições" },
];

type ConditionTypeType = "total_orders" | "total_purchases" | "days_since_last_purchase" | "has_tag" | "abandoned_cart_value";
type ConditionTypeTypeWithDefault = "total_orders" | "total_purchases" | "days_since_last_purchase" | "has_tag" | "abandoned_cart_value" | "default";

type ConditionTypeOption = {
  value: ConditionTypeType
  label: string;
};

const ConditionTypeOptions: ConditionTypeOption[] = [
  { value: "total_orders", label: "Total de Pedidos" },
  { value: "total_purchases", label: "Total em Compras" },
  { value: "days_since_last_purchase", label: "Última Compra" },
  { value: "has_tag", label: "Possui Tag" },
]

const ConditionTypeOptionsForAbandonedCartFlow: ConditionTypeOption[] = [
  { value: "total_orders", label: "Total de Pedidos" },
  { value: "total_purchases", label: "Total em Compras" },
  { value: "days_since_last_purchase", label: "Última Compra" },
  { value: "has_tag", label: "Possui Tag" },
  { value: "abandoned_cart_value", label: "Valor do Carrinho" },
]

const DefaultComparisonOperatorByConditionType: Record<ConditionTypeType, 'GREATER_THAN' | 'EQUALS'> = {
  "total_orders": "GREATER_THAN",
  "total_purchases": "GREATER_THAN",
  "days_since_last_purchase": "GREATER_THAN",
  "has_tag": "EQUALS",
  "abandoned_cart_value": "GREATER_THAN",
}

const ConditionOperatorOptionsByType: Record<ConditionTypeTypeWithDefault, {value: 'GREATER_THAN' | 'LESS_THAN', label: string}[]> = {
  "total_orders": [
    { value: "GREATER_THAN", label: "é maior que" },
    { value: "LESS_THAN", label: "é menor que" },
  ],
  "total_purchases": [
    { value: "GREATER_THAN", label: "é maior que" },
    { value: "LESS_THAN", label: "é menor que" },
  ],
  "days_since_last_purchase": [
    { value: "GREATER_THAN", label: "nos últimos" },
    { value: "LESS_THAN", label: "antes dos últimos" },
  ],
  "abandoned_cart_value": [
    { value: "GREATER_THAN", label: "é maior que" },
    { value: "LESS_THAN", label: "é menor que" },
  ],
  "has_tag": [],
  "default": [],
}

const ConditionBlock = ({ control, block, blockIndex, removeBlock, errors, tagOptions, flowType }: ConditionBlockProps) => {
  const { fields: conditions, append, remove, update } = useFieldArray({
    control,
    name: `flowNodeConditionBlocks.${blockIndex}.flowNodeConditions`,
  });

  const getPlaceholder = (type: string) => {
    switch (type) {
      case "total_orders":
        return "Pedidos";
      default:
        return "";
    }
  }

  const getRightOrLeftText = (type: string): { side: 'left' | 'right', text: string } | undefined => {
    switch (type) {
      case "total_purchases":
        return { side: 'left', text: 'R$' };
      case "abandoned_cart_value":
        return { side: 'left', text: 'R$' };
      case "days_since_last_purchase":
        return { side: 'right', text: 'dias' };
      default:
        return undefined
    }
  }

  return (
    <Box
      key={block.id}
      border="2px solid #E2E8F0"
      borderRadius="md"
      p={3}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
    >
      <Box style={{position: 'absolute', right: '17px', fontSize: '16px', zIndex: '999'}}>
        <Button
          onClick={
            () => removeBlock(blockIndex)
          }
          style={{ background: 'transparent'}}
        >
          X
        </Button>
      </Box>

      <FormControl style={{display: 'flex', alignItems: 'center', marginTop: 16, marginBottom: 16}}>
        <FormLabel style={{marginBottom: 0, fontSize: 14}}>O cliente atende à</FormLabel>
        <Box style={{width: 'fit-content'}}>
          <Controller
            control={control}
            name={`flowNodeConditionBlocks.${blockIndex}.conditionalJoinType`}
            render={({ field }) => (
              <SelectInput options={FlowNodeConditionBlockJoinTypeOptions} field={field} borderBottom={'0px'} color={'blue'} />
            )}
          />
        </Box>
      </FormControl>

      {conditions.map((condition, conditionIndex) => (
        <Box
          key={condition.id}
          display="flex"
          align-items="center"
          gap={2}
          fontSize={14}
          alignItems={"center"}
          padding={'4px'}
          border={'0px solid #aaa9a952'}
        >
          <FormControl flex={0}>
            <Controller
              control={control}
              name={`flowNodeConditionBlocks.${blockIndex}.flowNodeConditions.${conditionIndex}.type`}
              render={({ field }) => (
                <SelectInput
                  options={flowType === 'default' ? ConditionTypeOptions : ConditionTypeOptionsForAbandonedCartFlow}
                  field={field}
                  onChange={(e) => {
                    update(conditionIndex, {
                      ...condition,
                      type: e.target.value,
                      comparisonOperator: DefaultComparisonOperatorByConditionType[e.target.value as ConditionTypeType],
                      value: "",
                    });
                  }
                }
                />
              )}
            />
          </FormControl>
          {["has_tag"].includes(condition.type) ? null : (
            <FormControl flex={0}>
              <Controller
                control={control}
                name={`flowNodeConditionBlocks.${blockIndex}.flowNodeConditions.${conditionIndex}.comparisonOperator`}
                render={({ field }) => (
                  <SelectInput
                    options={ConditionOperatorOptionsByType[condition.type]}
                    field={field}
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl flex={1} style={{position: 'relative'}}>
            <Controller
              control={control}
              name={`flowNodeConditionBlocks.${blockIndex}.flowNodeConditions.${conditionIndex}.value`}
              render={({ field }) => {
                return (
                  condition.type === "has_tag" && condition.comparisonOperator !== null ? (
                    <>
                      <SelectInput
                        options={tagOptions}
                        field={field}
                        placeholder={'Selecione uma tag'}
                        error={errors?.flowConditionBlocks?.[blockIndex]?.flowConditions?.[conditionIndex]?.value}
                      />
                    { errors?.flowConditionBlocks?.[blockIndex]?.flowConditions?.[conditionIndex]?.value && (
                      <Text color="red" fontSize={12} position="fixed">
                        {errors.flowConditionBlocks[blockIndex].flowConditions[conditionIndex].value.message}
                      </Text>
                    )}
                    </>
                  ) : (
                    <>
                      <NumberInput
                        field={{
                          ...field,
                        }}
                        placeholder={getPlaceholder(condition.type)}
                        textAddon={getRightOrLeftText(condition.type)}
                        error={errors?.flowNodeConditionBlocks?.[blockIndex]?.flowNodeConditions?.[conditionIndex]?.value}
                      />
                      { errors?.flowNodeConditionBlocks?.[blockIndex]?.flowNodeConditions?.[conditionIndex]?.value && (
                        <Text color="red" fontSize={12} position="fixed">
                          {errors.flowNodeConditionBlocks[blockIndex].flowNodeConditions[conditionIndex].value.message}
                        </Text>
                      )}
                    </>
                  )
                );
              }}
            />
          </FormControl>

          <Box>
            <Button
              onClick={() => {
                remove(conditionIndex)
              }}
              style={{background: 'transparent', fontSize: 12, marginLeft: "auto", minWidth: 'auto', padding: '4px'}}
            >
              <FaTrash color={colors.danger} />
            </Button>
          </Box>
        </Box>
      ))}

      <Button mt={5} mb={3} onClick={() => append({ id: uuidv4(), type: 'total_orders', comparisonOperator: 'GREATER_THAN', value: "", flowNodeConditionBlockId: block.id })}>
        + Condição
      </Button>
    </Box>
  );
};

export default ConditionBlock;
