import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { useEffect, useRef, useState } from 'react';
import { SearchIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { countriesData } from './countriesData';
import useOutsideClickDetector from '../../../../hooks/useOutsideClickDetector';

interface PhoneInputProps {
  resetPhoneInputData: boolean;
  recipientPhoneNumber: string;
  setRecipientPhoneNumber: (recipientPhoneNumber: string) => void;
}

export interface CountryInterface {
  name: string;
  dialCode: string;
  flag: string;
  formattedNumber: string;
}

enum InputType {
  INPUT = 'input',
  SELECT = 'select',
}

const PhoneInput = ({ resetPhoneInputData, recipientPhoneNumber, setRecipientPhoneNumber }: PhoneInputProps) => {
  const [inputType, setInputType] = useState<InputType>(InputType.INPUT);
  const [countrySelected, setCountrySelected] = useState<CountryInterface>({
    name: 'Brazil',
    dialCode: '+55',
    flag: 'br',
    formattedNumber: '(99) 99999-9999'
  });
  const [searchText, setSearchText] = useState('');
  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    setCountrySelected({
      name: 'Brazil',
      dialCode: '+55',
      flag: 'br',
      formattedNumber: '(99) 99999-9999'
    });
    setRecipientPhoneNumber('');
  }, [resetPhoneInputData])

  const SelectInputRef = useRef(null)
  useOutsideClickDetector(SelectInputRef, () => setInputType(InputType.INPUT))

  const countries = countriesData;

  const filteredCountries = countries.filter((country) => {
    const normalizedSearchText = searchText.replace(/^\+/, '');
    const normalizedDialCode = country.dialCode.replace(/^\+/, '');
    return (
      country.name.toLowerCase().startsWith(normalizedSearchText.toLowerCase()) ||
      normalizedDialCode.startsWith(normalizedSearchText)
    );
  });

  const handleSelectCountry = (country: CountryInterface) => {
    setCountrySelected(country);
    setSearchText('');
    setRecipientPhoneNumber('')
    setInputType(InputType.INPUT);
  };

  const escapeDialCodeForMask = (dialCode: string) => {
    return dialCode.replace(/9/g, '\\9');
  };

  useEffect(() => {
    if (inputType === 'select') {
      setShowSelect(true);
    } else {
      setShowSelect(false);
    }
  }, [inputType]);

  return (
    <>
      {inputType === InputType.INPUT && (
        <InputGroup>
          <InputLeftAddon padding={1}>
            <Button
              size="sm"
              rightIcon={<TriangleDownIcon boxSize={2.5} />}
              aria-label="Selecionar país"
              onClick={() => setInputType(InputType.SELECT)}
              padding={0}
            >
              <Box display="flex" alignItems="center">
                <img
                  src={`https://flagcdn.com/w40/${countrySelected.flag}.png?style=flat`}
                  alt={`Bandeira: ${countrySelected.name}`}
                  style={{ width: '30px' }}
                  onError={(e) => {
                    (e.target as HTMLImageElement).style.display = 'none';
                    const parent = (e.target as HTMLImageElement).parentElement;
                    if (parent) {
                      parent.innerHTML = `<span style="font-size: 14px; font-weight: bold;">${countrySelected.name}</span>`;
                    }
                  }}
                />
              </Box>
            </Button>
          </InputLeftAddon>
          <Input
            paddingLeft={3}
            type="tel"
            placeholder={`${countrySelected.dialCode} ${countrySelected.formattedNumber}`}
            as={InputMask}
            mask={`${escapeDialCodeForMask(countrySelected.dialCode)} ${countrySelected.formattedNumber}`}
            value={recipientPhoneNumber}
            onChange={(e) => setRecipientPhoneNumber(e.target.value)}
          />
        </InputGroup>
      )}

      {inputType === InputType.SELECT && (
        <Box ref={SelectInputRef} position="relative" width="100%">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.500" />
            </InputLeftElement>
            <Input
              placeholder="Pesquise países ou códigos"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              paddingLeft="2.5rem"
              borderBottomRadius="0"
            />
          </InputGroup>

          <Box
            position="absolute"
            top="100%"
            left="0"
            width="100%"
            maxH="200px"
            overflowY="auto"
            bg="white"
            boxShadow="md"
            zIndex="10"
            borderBottomRadius="md"
            transform={showSelect ? 'translateY(0)' : 'translateY(-10px)'}
            opacity={showSelect ? 1 : 0}
            transition="transform 0.3s ease, opacity 0.3s ease"
          >
            {filteredCountries.length > 0 ? (
              filteredCountries.map((country) => (
                <button
                  key={country.flag}
                  onClick={() => handleSelectCountry(country)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    cursor: 'pointer',
                    border: 'none',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.1)')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <img
                      src={`https://flagcdn.com/w20/${country.flag}.png?style=flat`}
                      alt={country.name}
                      style={{ width: '16px', height: '12px' }}
                    />
                    <Text width="80%">{country.name}</Text>
                  </Box>
                  <Text textAlign="end" width="20%" color="gray.500">{country.dialCode}</Text>
                </button>
              ))
            ) : (
              <Text p={2}>Nenhum país encontrado</Text>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default PhoneInput;
