import {
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../../../../../constants/api-routes';
import { CompaniesService } from '../../../../../services/companies.service';
import {
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum,
} from '../../../../../types/CompanyDefinedField';

const TableColumnSettings = () => {
  const toast = useToast();
  const { data: companyDefinedFields } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS,
      );
      return data;
    },
  );

  function getTranslatedDataType(
    dataType: CompanyDefinedFieldDataTypeEnum,
  ): string {
    const translations: Record<CompanyDefinedFieldDataTypeEnum, string> = {
      string: 'TEXTO',
      number: 'NÚMERO',
      boolean: 'BOOLEANO',
      date: 'DATA',
    };

    return translations[dataType] as any;
  }

  const toggleColumnIsActive = useMutation(
    (companyDefinedFieldId: string) => CompaniesService.toggleColumnIsActive(companyDefinedFieldId),
    {
      onSuccess: ({ data }) => {
        toast({
          title: data.isActive ? 'Coluna Ativada' : 'Coluna Inativada',
          description: `A coluna "${data.name}" foi ${data.isActive ? 'ativada' : 'inativada'} com sucesso.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome do campo</Th>
            <Th>Tipo</Th>
            <Th>Ativo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {companyDefinedFields?.map((companyDefinedField) => {
            return (
              <Tr key={companyDefinedField.id}>
                <Td fontWeight={'bold'}>{companyDefinedField.name}</Td>
                <Td>{getTranslatedDataType(companyDefinedField.dataType)}</Td>
                <Td>
                  <Switch
                    size="md"
                    colorScheme="red"
                    defaultChecked={companyDefinedField.isActive}
                    onChange={async () => {
                      await toggleColumnIsActive.mutateAsync(companyDefinedField.id);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableColumnSettings;
