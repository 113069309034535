import {
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { colors } from "../../../../../constants/colors";
import { ListMessageTemplateItem } from "../../../../../services/message-templates.service";
import { MessageTemplateUtils } from "../../../../../utils/message-templates.utils";
import InputSwitchControlled from "../../../../../components/InputSwitchControlled";
import WhatsappTemplatePreview from "../../../../../components/WhatsappTemplatePreview";
import { AutomationType } from "../../../../../types/Prisma";
import { AutomationByTypeFormValues } from "../../EditBackgroundAutomationByTypePage";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../../constants/api-routes";
import { FlowsService, ListFlowItem } from "../../../../../services/flows.service";
import { AutomationActionEnum } from "../../../../../types/AutomationActionEnum";

const schema = yup
  .object({
    name: yup.string().required(),
    isActive: yup.boolean().required(),
    messageTemplateId: yup.string().nullable(),
    dailyMessageLimitOnWhatsapp: yup.number().nullable().positive().integer(),
    flowId: yup.string().nullable(),
    action: yup.string().required(),
  })
  .required();

interface AutomationByTypeFormProps {
  onSubmit: (data: any) => void;
  templateArgs: { [key: string]: string | undefined };
  setTemplateArgs: (args: { [key: string]: string | undefined }) => void;
  automation?: AutomationByTypeFormValues;
  automationType?: AutomationType;
  templates: ListMessageTemplateItem[];
}

const AutomationByTypeForm = ({
  onSubmit,
  templateArgs,
  setTemplateArgs,
  automation,
  automationType,
  templates,
}: AutomationByTypeFormProps) => {
  const defaultValues = {
    name: automation?.name || automationType?.name || '',
    isActive: automation?.isActive || (true as boolean),
    messageTemplateId: automation?.messageTemplateId || null,
    dailyMessageLimitOnWhatsapp: automation?.dailyMessageLimitOnWhatsapp || 100,
    flowId: automation?.flowId || null,
    action: automation?.action || 'send_message_template',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const watchMessageTemplateId = watch("messageTemplateId");

  const { data: flows } = useQuery(
    apiRoutes.listFlows(),
    async () => {
      const { data } = await FlowsService.listFlows();
      if (data.length >= 1) {
        return data.filter(
          (flow: ListFlowItem) => {
            return flow.type === 'abandoned_cart';
          }
        );
      }
    }
  );

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  const selectedTemplate = watchMessageTemplateId
    ? templates?.find((template) => template.id === watchMessageTemplateId)
    : null;

  const customParameters = MessageTemplateUtils.getCustomParametersInText(
    selectedTemplate?.templateText || '',
  );

  useEffect(() => {
    if (automation) {
      setValue("name", automation.name);
      setValue("isActive", automation.isActive);
      setValue("messageTemplateId", automation.messageTemplateId);
      setValue("dailyMessageLimitOnWhatsapp", automation.dailyMessageLimitOnWhatsapp);
      setValue("flowId", automation.flowId);
      setValue("action", automation.action);
    }
  }, [automation, setValue]);

  const handleChangeAction = (value: AutomationActionEnum) => {
    setValue("action", value);

    if (value === AutomationActionEnum.SEND_MESSAGE_TEMPLATE) {
      setValue("flowId", null);
    } else {
      setValue("messageTemplateId", null);
    }
  };

  return(
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}
    >
      <GridItem>
        <Heading size="md" mb={5}>
          {automation ? 'Editar automação' : 'Nova automação'}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel>Nome da automação</FormLabel>
              <Input placeholder="Nome" {...register('name')} />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            {automationType?.slug === 'abandoned_cart' && (
              <FormControl>
                <FormLabel>Ação da automação</FormLabel>
                <Controller
                  name="action"
                  control={control}
                  defaultValue={AutomationActionEnum.SEND_MESSAGE_TEMPLATE}
                  render={({ field }) => (
                    <RadioGroup
                      onChange={handleChangeAction}
                      defaultValue={AutomationActionEnum.SEND_MESSAGE_TEMPLATE}
                      value={field.value}
                    >
                      <HStack gap="6">
                        <Radio value={AutomationActionEnum.SEND_MESSAGE_TEMPLATE}>
                          Enviar template de mensagem
                        </Radio>
                        <Radio value={AutomationActionEnum.TRIGGER_FLOW}>
                          Acionar fluxo
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
            {watch("action") === AutomationActionEnum.SEND_MESSAGE_TEMPLATE ? (
              <FormControl isRequired>
                <FormLabel>Template para envio</FormLabel>
                <Select
                  placeholder="Selecione um template"
                  {...register("messageTemplateId")}>
                  {templates?.map((template) => (
                    <option
                      key={template.id}
                      value={template.id}
                      title={template.templateText}>
                      {template.name}
                    </option>
                  ))}
                </Select>
                <Text color={colors.danger} fontSize="xs">
                  {errors.messageTemplateId?.message}
                </Text>
              </FormControl>
            ) : (
              <FormControl isRequired>
                <FormLabel>Fluxo a ser ativado</FormLabel>
                <Select
                  placeholder="Selecione um fluxo"

                  {...register("flowId")}
                >
                  {flows?.map((flow) => (
                    <option
                      key={flow.id}
                      value={flow.id}
                      title={flow.title}>
                      {flow.title}
                    </option>
                  ))}
                </Select>
                <Text color={colors.danger} fontSize="xs">
                  {errors.flowId?.message}
                </Text>
              </FormControl>
            )}
            {selectedTemplate && (
              <Container paddingX={'50px'}>
                {customParameters.map((param) => (
                  <FormControl isRequired key={param}>
                    <FormLabel fontSize="sm">
                      {param.replaceAll('[', '').replaceAll(']', '')}
                    </FormLabel>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleChangeTemplateParameter(param, e.target.value)
                      }
                      value={templateArgs[param] || ''}
                      required
                    />
                  </FormControl>
                ))}
              </Container>
            )}
            <FormControl isRequired>
              <FormLabel>Limite diário de envio</FormLabel>
              <Input
                type="number"
                placeholder="Limite diário"
                {...register('dailyMessageLimitOnWhatsapp')}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.dailyMessageLimitOnWhatsapp?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Ativo?</FormLabel>
              <InputSwitchControlled name="isActive" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isActive?.message}
              </Text>
            </FormControl>
            <Divider />
            <Flex justify={'flex-end'}>
              <Button
                width="30%"
                isLoading={false}
                color={colors.white}
                bgColor={colors.primary}
                type="submit"
              >
                Salvar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={'sticky'} top={0} alignSelf="start">
        {selectedTemplate && (
          <WhatsappTemplatePreview
            message={selectedTemplate.templateText}
            footer={selectedTemplate.footerText}
            buttons={selectedTemplate.messageTemplateButtons}
            fileUrl={selectedTemplate.mediaUrl}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default AutomationByTypeForm;
