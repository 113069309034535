import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { Automation } from '../types/Automation';
import { AutomationType } from '../types/Prisma';
import { UpdateAutomationFromIntegrationDto } from '../pages/AutomationsPage/BackgroundAutomationsPage/EditBackgroundAutomationByTypePage';
import { CreateAutomationFromIntegrationDto } from '../pages/AutomationsPage/BackgroundAutomationsPage/CreateBackgroundAutomationByTypePage';

export interface ListAutomationItem extends Automation {
  filter?: {
    name: string;
  };
  messageTemplate: {
    name: string;
  };
  flow: {
    title: string;
  }
}

export interface ListAutomationTypeItem extends AutomationType {
  automations: Automation[];
}

const listAutomations = async (): Promise<
  AxiosResponse<ListAutomationItem[]>
> => {
  return request.get(apiRoutes.listAutomations());
};

const listAutomationTypes = async (params: {
  justWithSourceIntegrations?: boolean;
  withAutomations?: boolean;
  justActiveAutomations?: boolean;
  orderBy: string;
  orderDirection: string;
}): Promise<
  AxiosResponse<ListAutomationTypeItem[]>
> => {
  const mapping: Record<string, string> = {
    justWithSourceIntegrations: "just-source-integrations",
    withAutomations: "with-automations",
    justActiveAutomations: "just-active-automations",
    orderBy: "order-by",
    orderDirection: "order-direction",
  };

  const queryParams = Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${mapping[key]}=${value}`)
    .join("&");

  return request.get(`${apiRoutes.listAutomationTypes()}${queryParams?`?${queryParams}`:''}`);
};

export interface CreateAutomationDto {
  name: string;
  isActive: boolean;
  messageTemplateId: string | null;
  filterId: string;
  dailyMessageLimitOnWhatsapp: number;
  frequency: 'daily' | 'weekly' | 'monthly';
  daysOfWeek: number[];
  daysOfMonth: number[];
  hours: number;
  minutes: number;
  templateArgs?: Record<string, string | null | undefined>;
  isAutomationRepetitionAllowed: boolean;
  minDaysSinceLastCampaign: number;
  automationTypeId?: string;
  action: 'send_message_template' | 'trigger_flow';
  flowId: string | null;
}

export interface UpdateAutomationDto extends CreateAutomationDto {}

export interface AutomationFormValues
  extends Omit<CreateAutomationDto, 'daysOfWeek' | 'daysOfMonth'> {
  daysOfWeek: string[];
  daysOfMonth: string[];
  automationType: { slug: string };
}

const createAutomation = async (
  createAutomationDto: CreateAutomationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.post(apiRoutes.createAutomation(), createAutomationDto);
};

const updateAutomation = async (
  automationId: string,
  updateAutomationDto: UpdateAutomationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.put(
    apiRoutes.updateAutomation(automationId),
    updateAutomationDto,
  );
};

const createAutomationFromIntegration = async (
  createAutomationDto: CreateAutomationFromIntegrationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.post(apiRoutes.createAutomationFromIntegration(), createAutomationDto);
};

const updateAutomationFromIntegration = async (
  automationId: string,
  updateAutomationDto: UpdateAutomationFromIntegrationDto,
): Promise<AxiosResponse<Automation>> => {
  return request.put(
    apiRoutes.updateAutomationFromIntegration(automationId),
    updateAutomationDto,
  );
};

const deleteAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.delete(apiRoutes.deleteAutomation(automationId));
};

const toggleAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.patch(apiRoutes.toggleAutomation(automationId));
};

const showAutomation = async (
  automationId: string,
): Promise<AxiosResponse<Automation>> => {
  return request.get(apiRoutes.showAutomation(automationId));
};

const showAutomationType = async (
  automationTypeId: string,
): Promise<AxiosResponse<AutomationType>> => {
  return request.get(apiRoutes.showAutomationType(automationTypeId));
};

export const AutomationsService = {
  listAutomations,
  createAutomation,
  updateAutomation,
  deleteAutomation,
  toggleAutomation,
  showAutomation,
  showAutomationType,
  listAutomationTypes,
  createAutomationFromIntegration,
  updateAutomationFromIntegration
};
