import { useEffect } from 'react';

type OutsideClickHandler = () => void;
type ShouldCallOnOutsideClickFn = (event: MouseEvent) => boolean;

const useOutsideClickDetector = (
  ref: React.RefObject<HTMLElement>,
  onOutsideClick: OutsideClickHandler,
  shouldCallOnOutsideClickFn?: ShouldCallOnOutsideClickFn,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickingOutside =
        ref.current && !ref.current.contains(event.target as Node);
      if (!isClickingOutside) return;

      if (!shouldCallOnOutsideClickFn) {
        onOutsideClick();
        return;
      }

      if (shouldCallOnOutsideClickFn(event)) {
        event.stopPropagation();
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, onOutsideClick, shouldCallOnOutsideClickFn]);
};

export default useOutsideClickDetector;
