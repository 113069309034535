import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import {
  CategoryWithSector,
  ConversationCategory,
  ConversationCategoryDetailed,
} from '../types/ConversationCategory';

const listConversationCategories = async (): Promise<
  AxiosResponse<CategoryWithSector[]>
> => {
  return request.get(apiRoutes.listConversationCategories());
};

const listConversationCategoriesDetailed = async (): Promise<
  AxiosResponse<ConversationCategoryDetailed[]>
> => {
  return request.get(apiRoutes.listConversationCategoriesDetailed());
};

export interface CreateConversationCategoryDto {
  name: string;
  conversationSectorId?: string;
}

const createConversationCategory = async (
  createConversationCategoryDto: CreateConversationCategoryDto,
): Promise<AxiosResponse<ConversationCategory>> => {
  return request.post(
    apiRoutes.createConversationCategory(),
    createConversationCategoryDto,
  );
};

export interface UpdateConversationCategoryDto {
  conversationSectorId?: string;
  name?: string;
}

const updateConversationCategory = async (
  updateConversationCategoryDto: UpdateConversationCategoryDto,
  conversationCategoryId: string,
): Promise<AxiosResponse<ConversationCategory>> => {
  return request.put(
    apiRoutes.updateConversationCategory(conversationCategoryId),
    updateConversationCategoryDto,
  );
};

const deleteConversationCategory = async (
  conversationCategoryId: string,
): Promise<AxiosResponse<ConversationCategory>> => {
  return request.delete(
    apiRoutes.deleteConversationCategory(conversationCategoryId),
  );
};

export interface UpdateConversationCategoryPosValueDto {
  conversationCategoryId: string;
  previousConversationCategoryId: string;
}
const updateConversationCategoryPosValue = async (
  updateConversationCategoryPosValueDto: UpdateConversationCategoryPosValueDto,
) => {
  const { conversationCategoryId, ...data } =
    updateConversationCategoryPosValueDto;
  return request.post(
    apiRoutes.updateConversationCategoryPosValue(conversationCategoryId),
    data,
  );
};

export const ConversationCategoriesService = {
  listConversationCategories,
  createConversationCategory,
  updateConversationCategory,
  updateConversationCategoryPosValue,
  deleteConversationCategory,
  listConversationCategoriesDetailed,
};
