import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Select,
  Stack,
  Text,
  useDisclosure,
  useId,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import AlertDialogBase from '../components/AlertDialog';
import ButtonIcon from '../components/ButtonIcon';
import CrudModal from '../components/CrudModal';
import { apiRoutes } from '../constants/api-routes';
import { colors } from '../constants/colors';
import {
  AutoRepliesService,
  CreateAutoReplyDto,
  UpdateAutoReplyDto,
} from '../services/auto-replies.service';
import { ConversationCategoriesService } from '../services/conversation-categories.service';
import { MixpanelService } from '../services/mixpanel.service';
import { AutomaticReply } from '../types/AutomaticReply';
import { AutomaticReplyCondition } from '../types/AutomaticReplyCondition';
import { FileValidationUtils } from '../utils/file-validation.utils';
import useDownloadFile from './useDownloadFile';
import useFileValidation from './useFileValidation';
import useGetCrudText from './useGetCrudText';
import { ConversationCategoriesUtils } from '../utils/conversation-categories.utils';

interface CrudAutoReplyModalContextData {
  openCreateModal: () => void;
  openEditModal: (autoReplyId: string) => void;
  handleClickDelete: (autoReplyId: string) => void;
}

interface CrudAutoReplyProps {
  children?: ReactNode;
}

const CrudAutoReplyModalContext = createContext(
  {} as CrudAutoReplyModalContextData,
);

const schema = yup
  .object({
    keyword: yup.string().required('Palavra-chave é obrgatória'),
    message: yup.string().required('Mensagem é obrigatória'),
    condition: yup
      .string()
      .required('Resposta automática é obrigatória')
      .oneOf(['contains', 'equals']),
    conversationCategoryId: yup
      .string()
      .required('Categoria de destino é obrigatória')
      .nullable(true)
      .default(null),
    conversationTicketStatus: yup
      .string()
      .required('Status do ticket é obrigatório')
      .oneOf(['open', 'closed']),
  })
  .required();

export function CrudAutoReplyModalProvider({ children }: CrudAutoReplyProps) {
  const {
    onOpen: onOpenCrudModal,
    onClose: onCloseCrudModal,
    isOpen: isOpenCrudModal,
  } = useDisclosure();
  const [autoReplyId, setAutoReplyId] = useState<string | null>(null);
  const { crudActionText, crudTitle } = useGetCrudText(!autoReplyId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const formId = useId();
  const toast = useToast();
  const { validateFile } = useFileValidation();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const { downloadWhatsappMedia } = useDownloadFile();
  const [currentAutoReply, setCurrentAutoReply] =
    useState<AutomaticReply | null>(null);
  const queryClient = useQueryClient();
  const { data: conversationCategories } = useQuery(
    apiRoutes.listConversationCategories(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategories();
      return data;
    },
  );
  const createAutoReply = useMutation(
    (newAutoReply: CreateAutoReplyDto) =>
      AutoRepliesService.createAutoReply(newAutoReply),
    {
      onSuccess: (res) => {
        MixpanelService.track('create-auto-reply');
        queryClient.setQueryData(apiRoutes.listAutoReplies(), (old: any) => [
          ...old,
          res.data,
        ]);
      },
    },
  );

  const updateAutoReply = useMutation(
    (updatedAutoReply: UpdateAutoReplyDto) =>
      AutoRepliesService.updateAutoReply(updatedAutoReply),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(apiRoutes.listAutoReplies(), (old: any) => {
          return old.map((option: AutomaticReply) =>
            option.id === res.data.id ? res.data : option,
          );
        });
      },
    },
  );

  const deleteAutoReply = useMutation(
    (optionId: string) => AutoRepliesService.deleteAutoReply(optionId),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(apiRoutes.listAutoReplies(), (old: any) =>
          old.filter((option: AutomaticReply) => option.id !== res.data.id),
        );
      },
    },
  );

  useEffect(() => {
    const cachedAutoReplies = queryClient.getQueryData<AutomaticReply[]>(
      apiRoutes.listAutoReplies(),
    );
    const current = cachedAutoReplies?.find(
      (option) => option.id === autoReplyId,
    );

    if (current) {
      setCurrentAutoReply(current);
      setValue('keyword', current.keyword);
      setValue('message', current.message);
      setValue('condition', current.condition);
      setValue('conversationCategoryId', current.conversationCategoryId);
      setValue('conversationTicketStatus', current.conversationTicketStatus);
    }
  }, [autoReplyId, queryClient, setValue]);

  async function onSubmit(submitData: any) {
    try {
      const { files, ...data } = submitData;
      let file = files[0];

      if (!autoReplyId) {
        await createAutoReply.mutateAsync({ ...data, file });
      } else {
        const isFileRemoved = currentAutoReply?.file === null;

        await updateAutoReply.mutateAsync({
          ...data,
          id: autoReplyId,
          file: file || (isFileRemoved ? null : undefined),
        });
      }
      toast({
        title: 'Resposta automática salva com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      resetState();
    } catch (err) {}
  }

  function openEditModal(autoReplyId: string) {
    setAutoReplyId(autoReplyId);
    onOpenCrudModal();
  }

  function handleCloseModal() {
    resetState();
  }

  function openCreateModal() {
    setAutoReplyId(null);
    onOpenCrudModal();
  }

  function resetState() {
    onCloseCrudModal();
    setCurrentAutoReply(null);
    setAutoReplyId(null);

    setValue('keyword', '');
    setValue('message', '');
    setValue('condition', null);
    setValue('conversationCategoryId', null);
    setValue('files', []);
    setValue('conversationTicketStatus', null);
  }

  async function handleClickDelete(optionId: string) {
    deleteAutoReply.mutate(optionId);
    onCloseCrudModal();
  }

  function handleFileChange(event: any) {
    const file = event.target.files && event.target.files[0];

    const isValidFile = validateFile(file);

    if (!isValidFile) {
      event.target.value = null;
    }
  }

  return (
    <CrudAutoReplyModalContext.Provider
      value={{
        openCreateModal,
        openEditModal,
        handleClickDelete: (optionId: string) => {
          setAutoReplyId(optionId);
          onOpenAlert();
        },
      }}
    >
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={onCloseAlert}
        title="Deletar resposta automática"
        onConfirm={() => {
          handleClickDelete(autoReplyId!);
          onCloseAlert();
        }}
      >
        Tem certeza que deseja deletar esta opção?
      </AlertDialogBase>
      <CrudModal
        isLoading={updateAutoReply.isLoading || createAutoReply.isLoading}
        actionButtonText={crudActionText}
        isOpen={isOpenCrudModal}
        onClose={handleCloseModal}
        title={`${crudTitle} resposta automática`}
        onClickDelete={onOpenAlert}
        canDelete={Boolean(autoReplyId)}
        formId={formId}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={2}>
            <FormControl isRequired>
              <FormLabel>Se a mensagem</FormLabel>
              <Select
                placeholder="Selecione uma condição"
                {...register('condition')}
              >
                {[
                  {
                    value: AutomaticReplyCondition.contains,
                    label: 'contém',
                  },
                  {
                    value: AutomaticReplyCondition.equals,
                    label: 'é igual',
                  },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.keyword?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>a frase ou palavra</FormLabel>
              <Input
                placeholder="Escreva a palavra-chave ou frase"
                {...register('keyword')}
                isInvalid={errors.keyword?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.keyword?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>deve responder com a seguinte mensagem</FormLabel>
              <Textarea
                placeholder="Escreva a resposta automática"
                {...register('message')}
                isInvalid={errors.message?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.message?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>depois enviar o arquivo</FormLabel>
              {currentAutoReply?.file ? (
                <Flex align={'center'} gap={3}>
                  <Link
                    onClick={() =>
                      downloadWhatsappMedia({
                        mediaName: currentAutoReply.file!.name,
                        fileKey: currentAutoReply.file!.key,
                      })
                    }
                  >
                    {currentAutoReply.file.name}
                  </Link>
                  <ButtonIcon
                    icon={<FaRegTimesCircle color="red" />}
                    onClick={() =>
                      setCurrentAutoReply((prevState: any) => {
                        return prevState
                          ? {
                              ...prevState,
                              file: null,
                            }
                          : null;
                      })
                    }
                  />
                </Flex>
              ) : (
                <input
                  {...register('files')}
                  type="file"
                  onChange={handleFileChange}
                  accept={FileValidationUtils.supportedFileTypes.join(', ')}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>mover conversa para</FormLabel>
              <Select
                placeholder="Selecione uma opção"
                {...register('conversationCategoryId')}
              >
                {ConversationCategoriesUtils.formatAndSortCategories(conversationCategories ?? [])?.map(
                  (category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ),
                )}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.conversationCategoryId?.message}
              </Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>e mudar o status para</FormLabel>
              <Select
                placeholder="Selecione uma opção"
                {...register('conversationTicketStatus')}
              >
                {[
                  {
                    value: 'open',
                    name: 'Em andamento',
                  },
                  {
                    value: 'closed',
                    name: 'Finalizado',
                  },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.conversationTicketStatus?.message}
              </Text>
            </FormControl>
          </Stack>
        </form>
      </CrudModal>
      {children}
    </CrudAutoReplyModalContext.Provider>
  );
}

export function useCrudAutoReplyModal(): CrudAutoReplyModalContextData {
  const context = useContext(CrudAutoReplyModalContext);
  if (!context) {
    throw new Error(
      'useCrudAutoReplyModal must be used within a CrudAutoReplyModal',
    );
  }
  return context;
}
