import {
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Select,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { colors } from '../../constants/colors';
import { ConversationsService } from '../../services/conversations.service';
import {
  setDaysSinceLastMessage,
  setTicketStatus,
  setConversations,
  addConversations,
  selectSidebarState,
} from '../../state/inboxSlice';
import { AppDispatch, RootState } from '../../state/store';
import { ConversationTicketStatus } from '../../types/ConversationTicket';
import InboxChat from './components/InboxChat';
import ListConversations from './components/ListConversations';
import SideNavigation from './components/SideNavigation';
import { useSearchParams } from 'react-router-dom';
import { FiLoader, FiLogOut, FiSearch } from 'react-icons/fi';
import { logout } from '../../state/authSlice';
import { screenSizes } from '../../constants/screen-sizes';
import { apiRoutes } from '../../constants/api-routes';
import { UsersService } from '../../services/users.service';
import { SelectableListConversationDetailedItem } from '../../types/Conversation';
import OpenConversationToolbar from './components/InboxToolbar/OpenConversationToolbar';

export enum ConversationTabsEnum {
  NOT_READ = 'Não lidas',
  ALL_CONVERSATIONS = 'Todas as conversas',
  MY_CONVERSATIONS = 'Minhas conversas',
  NO_ASSIGNMENT = 'Sem atribuição',
}

const PER_PAGE = 50;

const InboxPage = () => {
  const {
    conversationCategoryId,
    ticketStatus,
    daysSinceLastMessage,
    hasUnreadMessages,
    activeTab,
    conversations,
    byAgentId,
    hasNoAgentAssigned,
    openConversationIds: openConversationIdsObject,
  } = useSelector((state: RootState) => state.inbox);
  const toast = useToast();
  const conversationsListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [allOpenConversationsSelected, setAllOpenConversationsSelected] =
    useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currSearchTerm, setCurrSearchTerm] = useState<string>('');

  const openConversationIds = Object.keys(openConversationIdsObject);
  const totalOpenConversationsCount = openConversationIds.length;

  const queryClient = useQueryClient();
  const {
    isFetching: isFetchingConversations,
    isError: isErrorConversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      'listConversationsDetailed',
      {
        daysSinceLastMessage,
        ticketStatus,
        conversationCategoryId,
        hasUnreadMessages,
        byAgentId,
        hasNoAgentAssigned,
        searchTerm,
      },
    ],
    async ({ pageParam = 1 }) => {
      const { data } = await ConversationsService.listConversationsDetailed({
        daysSinceLastMessage,
        ticketStatus,
        conversationCategoryId,
        hasUnreadMessages,
        page: pageParam,
        perPage: PER_PAGE,
        byAgentId,
        hasNoAgentAssigned,
        searchTerm,
      });
      return data;
    },
    {
      getNextPageParam: (pageParams, pages) => {
        if (pageParams.meta.totalPages === pageParams.meta.page) {
          return undefined;
        }
        return pageParams.meta.page + 1;
      },
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length - 1];

        if (totalOpenConversationsCount === 0 && lastPage.data.length === 0) {
          return;
        }

        if (lastPage.meta.page === 1) {
          dispatch(setConversations(lastPage.data));
        } else {
          dispatch(addConversations(lastPage.data));
        }
      },
    }
  );

  const filteredConversations = conversations.filter((conversation) => {
    const isRightCategory = !!conversationCategoryId
      ? conversationCategoryId === conversation.categoryId
      : true;
    const isRightStatus =
      conversation.hasOpenTicket === (ticketStatus === 'open');

    let isRightPeriod = true;
    if (conversation.lastMessage?.createdAt) {
      isRightPeriod =
        differenceInDays(
          new Date(),
          new Date(conversation.lastMessage.createdAt)
        ) <= daysSinceLastMessage;
    }

    return isRightCategory && isRightStatus && isRightPeriod;
  });

  useEffect(() => {
    handleScroll();
  }, [conversations]);

  useEffect(() => {
    const reset = () => {
      queryClient.resetQueries('listConversationsDetailed');
      dispatch(setConversations([]));
    };

    reset();
  }, [
    byAgentId,
    ticketStatus,
    conversationCategoryId,
    hasUnreadMessages,
    daysSinceLastMessage,
    hasNoAgentAssigned,
    queryClient,
    dispatch,
  ]);

  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } =
      conversationsListRef?.current as HTMLDivElement;
    const isScrolledToBottom =
      Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;

    if (
      isScrolledToBottom &&
      hasNextPage &&
      !isFetchingNextPage &&
      conversations.length >= PER_PAGE
    ) {
      await fetchNextPage();
    }
  };

  const isDesktop = useMediaQuery(screenSizes.desktop)[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const [listConversationsOpen, setListConversationsOpen] = useState(
    searchParams.get('conversationId') === null
  );

  const toggleListConversations = () => {
    if (isDesktop) return;
    setListConversationsOpen(!listConversationsOpen);

    if (!listConversationsOpen) {
      searchParams.delete('conversationId');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const isOpen = searchParams.get('conversationId') === null;
    setListConversationsOpen(isOpen);
  }, [searchParams]);

  const showConversations = () => {
    if (isDesktop) return true;

    return listConversationsOpen;
  };

  const showInboxChat = () => {
    if (isDesktop) return true;

    return !listConversationsOpen;
  };

  const { data: companyAgents = [] } = useQuery(
    apiRoutes.listCompanyAgents(),
    async () => {
      const { data } = await UsersService.listCompanyAgents();
      return data.filter((company) => company.isActive);
    }
  );

  const loadedOpenConversationsCount = conversations.length;

  const handleSelectAllOpenConversationsChange = () => {
    setAllOpenConversationsSelected(true);
    toast.closeAll();
    toast({
      title: `Todas as ${totalOpenConversationsCount} conversas em aberto foram selecionadas.`,
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  }

  const handleSelectAllLoadedOpenConversationsChange = (checked: boolean) => {
    if (!checked) setAllOpenConversationsSelected(false);
    const updatedConversations: SelectableListConversationDetailedItem[] = conversations.map((conversation) => ({ ...conversation, isSelected: checked }));
    dispatch(setConversations(updatedConversations));
    if (checked && loadedOpenConversationsCount !== totalOpenConversationsCount) {
      toast.closeAll()
      toast({
        title: (
          <Box onClick={handleSelectAllOpenConversationsChange} cursor='pointer'>
            Foram selecionadas {loadedOpenConversationsCount} de {totalOpenConversationsCount} conversas carregadas.{' '}
            <span style={{ color: colors.yellowMedium }}>Clique aqui</span> para selecionar todas as {totalOpenConversationsCount} conversas em aberto.
          </Box>
        ),
        status: 'info',
        duration: 7000,
        isClosable: true,
      })
    }
  }

  const handleConversationSelect = (conversationId: string, selected: boolean) => {
    setAllOpenConversationsSelected(false);
    const conversation = conversations.find(
      (conversation) => conversation.id === conversationId
    );
    if (!conversation) return;
    const updatedConversations: SelectableListConversationDetailedItem[] = conversations.map((conversation) =>
      conversation.id === conversationId ? { ...conversation, isSelected: selected } : conversation
    );
    dispatch(setConversations(updatedConversations));
  }

  const resetInboxChat = async () => {
    searchParams.delete('conversationId');
    setSearchParams(searchParams);
    setListConversationsOpen(false)
    await Promise.all([
      queryClient.resetQueries('listConversationsDetailed'),
      queryClient.resetQueries(apiRoutes.getConversationIdsWithOpenTickets({ daysSinceLastMessage })),
      queryClient.resetQueries(apiRoutes.getConversationIdsWithUnreadMessages({ daysSinceLastMessage }))
    ])
  }

  const selectedConversationsIds = useMemo(() => {
    if (allOpenConversationsSelected) return openConversationIds;
    return filteredConversations.filter((conversation) => conversation.isSelected).map((conversation) => conversation.id)
  }, [allOpenConversationsSelected, filteredConversations, openConversationIds]);
  const selectedConversationsCount = selectedConversationsIds.length;
  const areLoadedConversationsSelected =
    selectedConversationsCount > 0 &&
    selectedConversationsCount === loadedOpenConversationsCount;
  const areAllConversationsSelected =
    selectedConversationsCount > 0 &&
    selectedConversationsCount === totalOpenConversationsCount;
  const isTabTicketStatusOpen = ticketStatus === 'open';

  const handleCurrSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value || '';

    if (value === '' && searchTerm !== '') {
      setSearchTerm('');
    }

    setCurrSearchTerm(value);
  };

  const handleSearch = () => {
    setSearchTerm(currSearchTerm);
  };

  const isCollapsed = useSelector(selectSidebarState);

  return (
    <Grid
      templateColumns={
        isDesktop
          ? `${isCollapsed ? '60px' : '250px'} 300px auto`
          : 'auto'
      }
      templateRows="1fr"
      height="100vh"
      transition="all 0.3s ease"
    >
      {isDesktop && (
        <GridItem>
          <SideNavigation
            width="250px"
            companyAgents={companyAgents}
          />
        </GridItem>
      )}
      <GridItem
        display={showConversations() ? 'flex' : 'none'}
        flexDir="column"
        overflow="hidden"
      >
        <Flex
          height={'60px'}
          alignItems="center"
          padding={3}
          borderWidth={'1px'}
          borderStyle="solid"
          borderColor={colors.lightGrey}
          justifyContent="space-between"
        >
          <Text fontWeight="bold" fontSize={'lg'}>
            {activeTab}
          </Text>
          {!isDesktop && (
            <IconButton
              aria-label="Sair"
              onClick={() => {
                dispatch(logout());
              }}
              icon={<FiLogOut />}
              color={colors.red}
            />
          )}
        </Flex>
        <Flex
          justifyContent={'space-between'}
          borderWidth={'1px'}
          borderStyle="solid"
          borderColor={colors.lightGrey}
        >
          {isTabTicketStatusOpen && (
            <Checkbox
              size="sm"
              pl={4}
              onChange={() => handleSelectAllLoadedOpenConversationsChange(!(areAllConversationsSelected || areLoadedConversationsSelected))}
              isChecked={areAllConversationsSelected}
              isIndeterminate={areLoadedConversationsSelected}
            />
          )}
          <Select
            width="fit-content"
            onChange={(e) =>
              dispatch(
                setTicketStatus(e.target.value as ConversationTicketStatus)
              )
            }
            value={ticketStatus}
            border="none"
            size="sm"
            fontWeight={'bold'}
          >
            <option value="closed">Finalizado</option>
            <option value="open">Em aberto</option>
          </Select>
          <Select
            width="fit-content"
            onChange={(e) =>
              dispatch(
                setDaysSinceLastMessage(Number(e.target.value) as number)
              )
            }
            value={String(daysSinceLastMessage)}
            border="none"
            size="sm"
            fontWeight={'bold'}
          >
            <option value="7">Últimos 7 dias</option>
            <option value="15">Últimos 15 dias</option>
            <option value="30">Últimos 30 dias</option>
            <option value="60">Últimos 60 dias</option>
          </Select>
        </Flex>
        <Flex
          height={7}
          boxSizing="content-box"
          py={3}
          px={3}
          flexDirection={'row'}
          gap={1}
        >
          <Input
            height={'100%'}
            placeholder="Nome, telefone ou conversa"
            fontSize={14}
            type="search"
            value={currSearchTerm}
            onChange={handleCurrSearchTermChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <IconButton
            aria-label="Search"
            height={'100%'}
            isLoading={!!searchTerm?.length && isFetchingConversations}
            icon={<FiSearch />}
            onClick={handleSearch}
          />
        </Flex>
        {isTabTicketStatusOpen && (
          <OpenConversationToolbar
            statusMessage={`Mostrando ${loadedOpenConversationsCount}-${totalOpenConversationsCount}`}
            selectedConversationsIds={selectedConversationsIds}
            totalCount={totalOpenConversationsCount}
            selectedCount={selectedConversationsCount}
            onConversationsTicketsClosed={resetInboxChat}
          />
        )}
        <Loading
          isLoading={isFetchingConversations && conversations.length === 0}
          children={
            <ListConversations
              toggleListConversations={toggleListConversations}
              conversations={filteredConversations}
              listConversationRef={conversationsListRef}
              onScroll={handleScroll}
              companyAgents={companyAgents}
              footerComponent={
                isFetchingNextPage ? (
                  <Box
                    display="flex"
                    justifyContent={'center'}
                    alignItems="center"
                    padding="20px"
                  >
                    <Spinner />
                  </Box>
                ) : null
              }
              onConversationSelect={handleConversationSelect}
              isSelectable={isTabTicketStatusOpen && selectedConversationsCount > 0}
            />
          }
          isError={isErrorConversations}
          loadingMessage="Carregando conversas..."
          errorMessage="Erro ao carregar conversas."
        />
      </GridItem>
      <GridItem
        overflow={'hidden'}
        display={showInboxChat() ? 'block' : 'none'}
      >
        <InboxChat
          toggleListConversations={toggleListConversations}
          isDesktop={isDesktop}
          companyAgents={companyAgents}
        />
      </GridItem>
    </Grid>
  );
};

export default InboxPage;

