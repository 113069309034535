import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { ConversationSector } from '../types/ConversationSector';

const listConversationSectors = async (): Promise<
  AxiosResponse<ConversationSector[]>
> => {
  return request.get(apiRoutes.listConversationSectors());
};

export interface CreateConversationSectorDto {
  name: string;
}

const createConversationSector = async (
  createConversationSectorDto: CreateConversationSectorDto,
): Promise<AxiosResponse<ConversationSector>> => {
  return request.post(
    apiRoutes.createConversationSector(),
    createConversationSectorDto,
  );
};

const deleteConversationSector = async (
  conversationSectorId: string,
): Promise<AxiosResponse<ConversationSector>> => {
  return request.delete(
    apiRoutes.deleteConversationSector(conversationSectorId),
  );
};

export const ConversationSectorsService = {
  listConversationSectors,
  createConversationSector,
  deleteConversationSector,
};
