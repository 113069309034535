import { AxiosResponse } from 'axios';
import { FlowNodeButton } from '../types/Prisma';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';

const listFlowNodeButtons = async (
  flowNodeId: string,
): Promise<AxiosResponse<FlowNodeButton[]>> => {
  return request.get(apiRoutes.listFlowNodeButtons(flowNodeId));
};

export const FlowNodeButtonsService = {
  listFlowNodeButtons,
};
