export const SourceIntegrationLabelsRoutesAndImages = {
  bling: {
    label: 'Bling',
    image: 'integrations-logomark/bling.png',
    routeKey: 'bling',
  },
  cartPanda: {
    label: 'Cartpanda',
    image: 'integrations-logomark/cart-panda.png',
    routeKey: 'cartPanda',
  },
  custom: {
    label: 'Custom',
    image: '',
    routeKey: '',
  },
  direct_message: {
    label: 'Mensagem Direta',
    image: '',
    routeKey: '',
  },
  file_import: {
    label: 'Importação de Arquivo',
    image: '',
    routeKey: '',
  },
  google_tag_manager: {
    label: 'Google Tag Manager',
    image: '',
    routeKey: '',
  },
  hubspot_crm: {
    label: 'HubSpot CRM',
    image: '',
    routeKey: '',
  },
  ingresse: {
    label: 'Ingresse',
    image: 'integrations-logomark/ingresse.png',
    routeKey: 'ingresse',
  },
  loja_integrada: {
    label: 'Loja Integrada',
    image: 'integrations-logomark/loja-integrada.png',
    routeKey: 'lojaIntegrada',
  },
  magazord: {
    label: 'Magazord',
    image: 'integrations-logomark/magazord.png',
    routeKey: 'magazord',
  },
  magento1_ecommerce: {
    label: 'Magento Versão 1',
    image: 'integrations-logomark/magento.png',
    routeKey: 'magento',
  },
  magento2_ecommerce: {
    label: 'Magento Versão 2',
    image: 'integrations-logomark/magento.png',
    routeKey: 'magento',
  },
  omie: {
    label: 'Omie',
    image: 'integrations-logomark/omie.png',
    routeKey: 'omie',
  },
  omny: {
    label: 'Omny',
    image: 'integrations-logomark/omny.png',
    routeKey: 'omny',
  },
  shopify_ecommerce: {
    label: 'Shopify Ecommerce',
    image: 'integrations-logomark/shopify.png',
    routeKey: 'shopify',
  },
  tray: {
    label: 'Tray',
    image: 'integrations-logomark/tray.png',
    routeKey: 'tray',
  },
  unbox: {
    label: 'Unbox',
    image: 'integrations-logomark/unbox.png',
    routeKey: 'unbox',
  },
  unknown: {
    label: 'Desconhecido',
    image: '',
    routeKey: '',
  },
  visual_ecommerce: {
    label: 'Visual Ecommerce',
    image: 'integrations-logomark/visual-ecommerce.png',
    routeKey: '',
  },
  vtex_ecommerce: {
    label: 'VTEX Ecommerce',
    image: 'integrations-logomark/vtex.png',
    routeKey: 'vtex',
  },
  woo_commerce: {
    label: 'WooCommerce',
    image: 'integrations-logomark/woo-commerce.png',
    routeKey: 'wooCommerce',
  },
  yampi: {
    label: 'Yampi',
    image: 'integrations-logomark/yampi.png',
    routeKey: 'yampi',
  },
};
