import { useQuery } from 'react-query';
import { SelectOption } from '../components/InputSelect';

const useSelectOptionsQuery = (
  queryKey: string,
  fetchFn: () => Promise<{ data: any }>,
  dataPath: string,
) => {
  const { data: options = [] } = useQuery(
    queryKey,
    async () => {
      const response = await fetchFn();
      return response;
    },
    {
      select: (response): SelectOption[] => {
        return response.data[dataPath].map((item: string) => ({
          value: item,
          label: item,
        }));
      },
      refetchOnWindowFocus: false,
    },
  );

  return options;
};

export default useSelectOptionsQuery;
