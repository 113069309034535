import { FormControl, Select } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MdGroups } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import FormLabel from '../../../../../components/FormLabel'
import { apiRoutes } from '../../../../../constants/api-routes'
import { rfmColors } from '../../../../../constants/colors';
import { StatisticsService } from '../../../../../services/statistics.service'
import { CustomerFiltersEnum } from '../../../../../types/CustomerFiltersEnum'
import { RFMGroupsEnum } from '../../../../../types/RFMGroupsEnum'
import { UrlUtils } from '../../../../../utils/url.utils'
import AccordionItemLayout from '../AccordionItemLayout'
import { Filter } from '../../../../../types/Filter'

export const maxRfmRecencyOptions = [
  {
    value: '365',
    name: 'Último ano'
  },
  {
    value: '540',
    name: 'Últimos 1,5 anos'
  },
  {
    value: '730',
    name: 'Últimos 2 anos'
  },
  {
    value: '1095',
    name: 'Últimos 3 anos'
  }
];

export interface SectionRFMProps {
  selectedFilter: Filter | null;
}


const SectionRFM = ({
  selectedFilter
}: SectionRFMProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const rfmMaxRecency = Number(searchParams.get('rfmMaxRecency') || 365);
  const rfmGroup= searchParams.get('rfmGroup') as RFMGroupsEnum || undefined;

  const { data: rfmSummary, isFetching } = useQuery(
    apiRoutes.getRFMAnalysis(rfmMaxRecency),
    async () => {
      const { data } = await StatisticsService.getRFMAnalysis(rfmMaxRecency);
      return data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );

  useEffect(() => {
    if (!rfmGroup) {
      searchParams.delete('rfmGroup');
      searchParams.delete('rfmMaxRecency');
      setSearchParams(searchParams);
      return;
    };
    if (!rfmSummary) return;

    const rfmGroupFilterCriteria = rfmSummary[rfmGroup].filterCriteria || '';

    if (!rfmGroupFilterCriteria) return;

    const initialFilters = UrlUtils.convertQueryStringToObject(searchParams.toString());
    const rfmFilters = UrlUtils.convertQueryStringToObject(rfmGroupFilterCriteria);

    Object.keys(initialFilters).forEach((key) => {
      const filtersToKeep = [
        'rfmGroup',
        'rfmMaxRecency',
        CustomerFiltersEnum.EXCLUDED_TEMPLATE_IDS,
        CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN,
      ];
      if (!filtersToKeep.includes(key as any)) {
        delete initialFilters[key];
      }
    });
    const newFilters = {
      ...initialFilters,
      ...rfmFilters,
    };
    const newQueryString = UrlUtils.convertObjectToQueryString(newFilters);
    
    if (!selectedFilter) {
      setSearchParams(newQueryString);
    }
  }, [rfmMaxRecency, rfmGroup, JSON.stringify(rfmSummary)]);

  function handleChangeRfmGroup(rfmGroup: RFMGroupsEnum) {
    searchParams.set('rfmGroup', rfmGroup);
    setSearchParams(searchParams);
  }

  const rfmGroupOptions = Object.values(RFMGroupsEnum).map((rfmGroup) => ({
    value: rfmGroup,
    label: t(`enums.RFMGroup.${rfmGroup}`),
  }));

  function handleChangeMaxRecency(rfmMaxRecency: string) {
    searchParams.set('rfmMaxRecency', rfmMaxRecency);
    setSearchParams(searchParams);
  }

  return (
    <AccordionItemLayout title="Matriz RFM" icon={<MdGroups size="18px" />}>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Segmente com base em grupos da matriz RFM"
        >
          Grupo
        </FormLabel>
        <Select
          placeholder="Selecione um grupo"
          value={rfmGroup || ''}
          bgColor={rfmColors[rfmGroup]?.secondary || ''}
          onChange={(e) => handleChangeRfmGroup(e.target.value as RFMGroupsEnum)}
        >
          {rfmGroupOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel size="sm"
          tooltip="O período máximo indica o máximo de dias desde a última compra">
          Período máximo
        </FormLabel>
        <Select
          placeholder="Selecione um período"
          value={rfmMaxRecency || ''}
          onChange={(e) => handleChangeMaxRecency(e.target.value)}
        >
          {maxRfmRecencyOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </AccordionItemLayout>
  )
}

export default SectionRFM