import {
  Avatar,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react';
import { BiSolidCategory } from 'react-icons/bi';
import { BsPeopleFill, BsPersonCheckFill } from 'react-icons/bs';
import { FaInbox, FaChartBar, FaSearch, FaEllipsisH } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConversationTabsEnum } from '../..';
import Sider from '../../../../components/Sider';
import { apiRoutes } from '../../../../constants/api-routes';
import { appPaths } from '../../../../constants/app-paths';
import { ConversationCategoriesService } from '../../../../services/conversation-categories.service';
import { ConversationsService } from '../../../../services/conversations.service';
import {
  changeActiveTab,
  getTotalOpenConversationByCategoryId,
  selectSidebarState,
  setConversationCategories,
  setConversationIdToCategoryIdMap,
  setConversationSectors,
  setConversationsIdsByAgentId,
  setHasNoAgentConversationIds,
  setOpenConversationIds,
  setUnreadConverstionIds,
} from '../../../../state/inboxSlice';
import { AppDispatch, RootState } from '../../../../state/store';
import {
  ConversationCategory,
  ConversationCategoryDetailed,
} from '../../../../types/ConversationCategory';
import PopoverNewConversation from './PopoverNewConversation';
import PopoverNewConversationCategory from './PopoverNewConversationCategory';
import { MdPersonOff } from 'react-icons/md';
import { ConversationSector, User } from '../../../../types/Prisma';
import PopoverNewSector from './PopoverNewSector';
import { ConversationSectorsService } from '../../../../services/conversation-sectors.service';

interface SideNavigationProps {
  width?: string;
  companyAgents: User[];
}

const SideNavigation = ({ width, companyAgents }: SideNavigationProps) => {
  const {
    activeTab,
    conversationCategories,
    conversationSectors,
    openConversationIds,
    unreadConversationIds,
    daysSinceLastMessage,
    conversationCategoryId,
    hasNoAgentConversationIds,
    conversationsIdsByAgentId,
  } = useSelector((state: RootState) => state.inbox);
  const isCollapsed = useSelector(selectSidebarState);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useDispatch<AppDispatch>();
  const totalOpenConversationsByCategoryId = useSelector((state: RootState) =>
    getTotalOpenConversationByCategoryId(state),
  );
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const deleteConversationCategory = useMutation(
    async (categoryId: string) => {
      const { data } =
        await ConversationCategoriesService.deleteConversationCategory(
          categoryId,
        );
      return data;
    },
    {
      onSuccess: (data) => {
        if (conversationCategoryId === data.id) {
          dispatch(
            changeActiveTab({
              tab: 'all-conversations',
              conversationCategoryId: null,
            }),
          );
        }
        queryClient.setQueryData(
          apiRoutes.listConversationCategoriesDetailed(),
          (oldData?: ConversationCategoryDetailed[]) => {
            return (
              oldData?.filter((category: any) => category.id !== data.id) || []
            );
          },
        );
        queryClient.setQueryData(
          apiRoutes.listConversationCategories(),
          (oldData?: ConversationCategory[]) => {
            return (
              oldData?.filter((category: any) => category.id !== data.id) || []
            );
          },
        );
        toast({
          title: 'Categoria deletada',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );
  const deleteConversationSector = useMutation(
    async (sectorId: string) => {
      const { data } =
        await ConversationSectorsService.deleteConversationSector(sectorId);
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          apiRoutes.listConversationSectors(),
          (oldData?: ConversationSector[]) => {
            return (
              oldData?.filter((sector: any) => sector.id !== data.id) || []
            );
          },
        );

        toast({
          title: 'Setor deletado',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );
  const moveCategory = useMutation(
    async ({
      categoryId,
      sectorId,
    }: {
      categoryId: string;
      sectorId?: string;
    }) => {
      const { data } =
        await ConversationCategoriesService.updateConversationCategory(
          { conversationSectorId: sectorId },
          categoryId,
        );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          apiRoutes.listConversationCategoriesDetailed(),
        );

        toast({
          title: 'Categoria movida com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  useQuery(
    apiRoutes.listConversationCategoriesDetailed(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategoriesDetailed();
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setConversationCategories(data));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    },
  );

  useQuery(
    apiRoutes.listConversationSectors(),
    async () => {
      const { data } =
        await ConversationSectorsService.listConversationSectors();
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setConversationSectors(data));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    },
  );

  useQuery(
    apiRoutes.getConversationIdsWithOpenTickets({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } =
        await ConversationsService.getConversationIdsWithOpenTickets({
          daysSinceLastMessage,
        });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setOpenConversationIds(data.map((c) => c.id)));

        const noAgentConversationIds = data
          .filter(({ agentId, id }) => !agentId && id)
          .map(({ id }) => id);
        if (noAgentConversationIds.length > 0) {
          dispatch(setHasNoAgentConversationIds(noAgentConversationIds));
        }

        const conversationsByAgentId = data.filter(
          ({ agentId }) => agentId !== null,
        ) as { id: string; agentId: string }[];
        dispatch(setConversationsIdsByAgentId(conversationsByAgentId));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    },
  );

  useQuery(
    apiRoutes.getConversationIdsWithUnreadMessages({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } =
        await ConversationsService.getConversationIdsWithUnreadMessages({
          daysSinceLastMessage,
        });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setUnreadConverstionIds(data.map((c) => c.id)));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    },
  );

  useQuery(
    apiRoutes.listConversationSummary({
      daysSinceLastMessage,
    }),
    async () => {
      const { data } = await ConversationsService.listConversationSummary({
        daysSinceLastMessage,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setConversationIdToCategoryIdMap(data));
      },
      refetchInterval: 1000 * 60 * 1, // redundant with the socket to guarantee the data is always up to date
    },
  );

  const organizedSectors = conversationSectors.reduce(
    (acc, sector) => {
      acc[sector.id] = {
        sectorId: sector.id,
        name: sector.name,
        categories: [],
      };
      return acc;
    },
    {} as Record<string, { sectorId: string; name: string; categories: any[] }>,
  );

  organizedSectors['general'] = {
    sectorId: 'general',
    name: 'Geral',
    categories: [],
  };

  conversationCategories.forEach((category) => {
    const sectorId = category.conversationSector?.id;
    const categoryItem = {
      rightElement: (
        <Text>{totalOpenConversationsByCategoryId[category.id!] || ''}</Text>
      ),
      title: category.name!,
      contextMenuItems: [
        {
          title: 'Apagar Categoria',
          onClick: () => deleteConversationCategory.mutate(category.id!),
        },
        ...Object.values(organizedSectors)
          .filter((sector) => sector.sectorId !== sectorId)
          .map((sector) => ({
            title: `Mover para '${sector.name}'`,
            onClick: () =>
              moveCategory.mutate({
                categoryId: category.id!,
                sectorId: sector.sectorId,
              }),
          })),
      ],
      onClick: () =>
        dispatch(
          changeActiveTab({
            tab: 'conversation-category',
            conversationCategoryId: category.id,
          }),
        ),
    };

    if (sectorId && organizedSectors[sectorId]) {
      organizedSectors[sectorId].categories.push(categoryItem);
    } else {
      organizedSectors['general'].categories.push(categoryItem);
    }
  });

  Object.values(organizedSectors).forEach((sector) => {
    sector.categories.sort((a, b) => a.title.localeCompare(b.title));
  });

  const sortedSectors = Object.values(organizedSectors).sort((a, b) => {
    if (a.name === 'Geral') return -1;
    if (b.name === 'Geral') return 1;
    return a.name.localeCompare(b.name);
  });

  return (
    <div>
      <Sider
        topElement={
          <>
            <Flex
              justifyContent={'space-between'}
              alignItems="center"
              height="60px"
              direction={{ base: 'column', md: 'row' }}
              gap={2}
            >
              <Text
                fontSize="2xl"
                fontWeight="bold"
                display={{
                  base: 'none',
                  md: isCollapsed ? 'none' : 'block',
                }}
              >
                Inbox
              </Text>

              {isCollapsed ? (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={<FaEllipsisH />}
                      aria-label="Mais ações"
                      size="sm"
                      marginLeft={2}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Flex direction="column" gap={2}>
                        <IconButton
                          size="sm"
                          aria-label="Análises de Atendimentos"
                          onClick={() => navigate(appPaths.reports.index())}
                          icon={<FaChartBar fontSize={16} />}
                        />
                        <PopoverNewConversation />
                        <IconButton
                          size="sm"
                          aria-label="Pesquisar"
                          onClick={() => navigate(appPaths.customers.index())}
                          icon={<FaSearch fontSize={16} />}
                        />
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : (
                <Flex gap={2}>
                  <IconButton
                    aria-label="Análises de Atendimentos"
                    onClick={() => navigate(appPaths.reports.index())}
                    icon={<FaChartBar />}
                    size="sm"
                  />
                  <PopoverNewConversation />
                  <IconButton
                    aria-label="Pesquisar"
                    onClick={() => navigate(appPaths.customers.index())}
                    icon={<FaSearch />}
                    size="sm"
                  />
                </Flex>
              )}
            </Flex>
            <Divider />
          </>
        }
        width={width}
        activeTab={activeTab}
        items={[
          {
            rightElement: (
              <Text>{Object.keys(unreadConversationIds).length || ''}</Text>
            ),
            title: ConversationTabsEnum.NOT_READ,
            icon: <FaInbox />,
            onClick: () => {
              dispatch(
                changeActiveTab({
                  tab: 'not-read',
                  conversationCategoryId: null,
                }),
              );
            },
            children: [],
          },
          {
            rightElement: (
              <Text>{Object.keys(openConversationIds).length || ''}</Text>
            ),
            title: ConversationTabsEnum.ALL_CONVERSATIONS,
            icon: <BsPeopleFill />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: 'all-conversations',
                  conversationCategoryId: null,
                }),
              ),
            children: [],
          },
          {
            rightElement: (
              <Text>
                {(conversationsIdsByAgentId[currentUser?.sub!] || []).length ||
                  ''}
              </Text>
            ),
            title: ConversationTabsEnum.MY_CONVERSATIONS,
            icon: <BsPersonCheckFill />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: 'agent-conversations',
                  conversationCategoryId: null,
                  agentId: currentUser?.sub,
                }),
              ),
            children: [],
          },
          {
            rightElement: (
              <Text>{Object.keys(hasNoAgentConversationIds).length || ''}</Text>
            ),
            title: ConversationTabsEnum.NO_ASSIGNMENT,
            icon: <MdPersonOff />,
            onClick: () =>
              dispatch(
                changeActiveTab({
                  tab: 'no-assignment',
                  conversationCategoryId: null,
                }),
              ),
            children: [],
          },
          {
            title: 'Setores',
            icon: <BiSolidCategory />,
            onClick: () => {},
            children: [
              ...sortedSectors.map((sectorData) => ({
                rightElement: <></>,
                contextMenuItems:
                  sectorData.sectorId !== 'general'
                    ? [
                        {
                          title: 'Apagar Setor',
                          onClick: () =>
                            deleteConversationSector.mutate(
                              sectorData.sectorId,
                            ),
                        },
                      ]
                    : undefined,
                title: sectorData.name,
                children: [
                  ...sectorData.categories,
                  {
                    title: 'Nova Categoria',
                    rightElement: (
                      <PopoverNewConversationCategory
                        conversationSectorId={
                          sectorData.sectorId === 'general'
                            ? undefined
                            : sectorData.sectorId
                        }
                      />
                    ),
                    onClick: () => {},
                  },
                ],
                onClick: () => {},
              })),
              {
                title: 'Novo Setor',
                rightElement: <PopoverNewSector />,
                onClick: () => {},
              },
            ],
            rightElement: <></>,
          },
          {
            rightElement: (
              <Text>{Object.keys(companyAgents).length || ''}</Text>
            ),
            title: 'Colaboradores',
            icon: <BiSolidCategory />,
            onClick: () => {},
            children: companyAgents
              .filter((agent) => !!agent.id)
              .map((agent) => ({
                rightElement: (
                  <Text>
                    {(conversationsIdsByAgentId[agent.id!] || []).length || ''}
                  </Text>
                ),
                title: agent.name!,
                icon: <Avatar name={agent.name} size={'xs'} />,
                onClick: () =>
                  dispatch(
                    changeActiveTab({
                      tab: 'agent-conversations',
                      conversationCategoryId: null,
                      agentId: agent.id,
                      agentName: agent.name,
                    }),
                  ),
              })),
          },
        ]}
      />
    </div>
  );
};

export default SideNavigation;
