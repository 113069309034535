import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { LuEdit, LuHistory } from 'react-icons/lu';
import DrawerEditConversation from '../DrawerEditConversation';
import DrawerOrdersHistory from '../DrawerHistoryOrdersConversation';

interface DrawerConversationSettingsProps {
  conversationId: string;
  customerId: string;
  trigger: React.ReactElement;
}

const DrawerConversationSettings = ({
  conversationId,
  customerId,
  trigger,
}: DrawerConversationSettingsProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const btnRef = useRef<any>();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      {React.cloneElement(trigger, { onClick: onOpen })}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Detalhes do Cliente</DrawerHeader>
          <DrawerBody>
            <Tabs
              isFitted
              variant="enclosed"
              index={selectedTab}
              onChange={(index) => setSelectedTab(index)}
            >
              <TabList>
                <Tab>
                  <Icon as={LuHistory} style={{ marginRight: '8px' }} />
                  Histórico de Pedidos
                </Tab>
                <Tab>
                  <Icon as={LuEdit} style={{ marginRight: '8px' }} />
                  Editar Cliente
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <DrawerOrdersHistory customerId={customerId} />
                </TabPanel>
                <TabPanel>
                  <DrawerEditConversation conversationId={conversationId} onClose={onClose}/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default DrawerConversationSettings;
