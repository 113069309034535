export enum CompanyDefinedFieldDataTypeEnum {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export enum CompanyDefinedFieldTableEnum {
  CUSTOMERS = 'customers',
}

export type CompanyDefinedField = {
  createdAt: Date;
  updatedAt: Date;
  id: string;
  companyId: string;
  table: CompanyDefinedFieldTableEnum;
  name: string;
  dataType: CompanyDefinedFieldDataTypeEnum;
  isDeleted: boolean;
  isActive: boolean;
};
