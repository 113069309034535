import { Stack, Text } from '@chakra-ui/react';
import CardOrder from '../DrawerHistoryOrdersConversation/CardOrders';
import { Order } from '../../types/Order';


interface OrdersListProps {
  orders: Order[];
}

const OrdersList = ({ orders }: OrdersListProps) => (
  <Stack spacing={4}>
    {orders?.length === 0 ? (
      <Text textAlign="center">Nenhum pedido encontrado.</Text>
    ) : (
      orders?.map((order) => <CardOrder key={order.id} order={order} />)
    )}
  </Stack>
);

export default OrdersList;
