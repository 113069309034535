import { useEffect, useState, useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { IoPersonOutline } from 'react-icons/io5';
import { SelectOption } from '../../../../../components/InputSelect';
import { useCustomerSearchParams } from '../../../../../hooks/useCustomerSearchParams';
import { CustomersService } from '../../../../../services/customers.service';
import AccordionItemLayout from '../AccordionItemLayout';
import useSelectOptionsQuery from '../../../../../hooks/useSelectOptionsQuery';
import SelectField from '../../../../../components/SelectField/SelectField';
import { CustomerFiltersEnum } from '../../../../../types/CustomerFiltersEnum';
import InputSelectAsync from '../../../../../components/InputSelectAsync';
import FormLabel from '../../../../../components/FormLabel';
import { FormControl } from '@chakra-ui/react';

interface SectionCustomersProps {
  useFormReturn: UseFormReturn<any>;
  updateSelectedValues: (args: any) => void;
}

const useUpdateSelectEffect = (
  options: SelectOption[],
  selectedValues: any,
  valueToSet: CustomerFiltersEnum,
  updateFn: (args: any) => void
) => {
  useEffect(() => {
    if (options.length > 0) {
      updateFn({
        selectedValues,
        sourcedata: options,
        valueToSet,
        optionValue: 'value',
        optionLabel: 'label',
      });
    }
  }, [options, selectedValues, updateFn, valueToSet]);
};

const SectionCustomers = ({
  useFormReturn,
  updateSelectedValues,
}: SectionCustomersProps) => {
  const { control } = useFormReturn;
  const { selectedStates, selectedCities } = useCustomerSearchParams();

  const defaultStateOptions = useSelectOptionsQuery(
    'fetchStates',
    CustomersService.listCustomerStates,
    'states'
  );

  const defaultCitiesOptions = useSelectOptionsQuery(
    'fetchCities',
    CustomersService.listCustomerCities,
    'cities'
  );

  const loadCitiesOptions = async (search: string): Promise<SelectOption[]> => {
    const { data } = await CustomersService.listCustomerCities(search)

    return data.cities.map(city => ({
      value: city,
      label: city,
    }));
  };

  useUpdateSelectEffect(defaultStateOptions, selectedStates, CustomerFiltersEnum.SELECTED_STATES, updateSelectedValues);
  useUpdateSelectEffect(defaultCitiesOptions, selectedCities, CustomerFiltersEnum.SELECTED_CITIES, updateSelectedValues);

  return (
    <AccordionItemLayout title="Clientes" icon={<IoPersonOutline size="18px" />}>
      <SelectField
        label={'É de um dos estados'}
        tooltip={'Mostrar clientes que são de um dos estados'}
        name={CustomerFiltersEnum.SELECTED_STATES}
        control={control}
        defaultOptions={defaultStateOptions}
      />
      <SelectField
        label={'É de uma das cidades'}
        tooltip={'Mostrar clientes que são de uma das cidades'}
        name={CustomerFiltersEnum.SELECTED_CITIES}
        control={control}
        isAsync={true}
        defaultOptions={defaultCitiesOptions}
        loadOptions={loadCitiesOptions}
      />
    </AccordionItemLayout>
  );
};

export default SectionCustomers;