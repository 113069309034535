import { Box, Center, Image, keyframes, Spinner, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { pulsing } from '../../animations/pulsing';
import { scrollbarStyles } from '../../styles/scrollbar.styles';

interface LoadingProps {
  isLoading: boolean;
  loadingMessage: string;
  isError: boolean;
  errorMessage: string;
  children: ReactNode;
}

const Loading = ({
  isLoading,
  loadingMessage,
  isError,
  errorMessage,
  children,
}: LoadingProps) => {
  return (
    <Box height="auto" width="100%" overflow='scroll' css={scrollbarStyles('4px')}>
      {isLoading ? (
        <Center height="100%" width="100%" flexDir={'column'}>
          <Image src="/favicon.ico" alt="loading" animation={`${pulsing} infinite 1.5s linear`}/>
          <Text>{loadingMessage}</Text>
        </Center>
      ) : isError ? (
        <Center height="100%" width="100%" flexDir={'column'}>
          <Text>{errorMessage}</Text>
        </Center>
      ) : (
        children
      )}
    </Box>
  );
};

export default Loading;
