import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import PhoneInput from './PhoneInput';
import { useMutation } from 'react-query';
import { ConversationsService, CreateConversationDto } from '../../../../services/conversations.service';

const PopoverNewConversation = () => {
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const popover = useDisclosure();

  const toast = useToast();

  function handleCreateConversation() {
    createConversation.mutateAsync({
      recipientPhoneNumberId: `${recipientPhoneNumber}`,
    });
  }

  const createConversation = useMutation(
    async (createConversationDto: CreateConversationDto) => {
      const { data } = await ConversationsService.createConverstation(createConversationDto);
      return data;
    },
    {
      onSuccess: (data) => {
        toast({
          title: 'Envie uma mensagem para iniciar a conversa',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        popover.onClose();
        setSearchParams({
          conversationId: data.id,
        });
      },
      onError: (err: any) => {
        setErrorMessage(err.response.data.message);
      },
    }
  );

  return (
    <Popover
      isOpen={popover.isOpen}
      onOpen={popover.onOpen}
      onClose={() => {
        setErrorMessage('');
        popover.onClose();
      }}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Nova Conversa"
          size="sm"
          icon={<FaEdit fontSize={16} />}
        />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverHeader fontWeight="semibold">Nova conversa</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack gap={2}>
            <FormControl isInvalid={!!errorMessage}>
              <PhoneInput resetPhoneInputData={popover.isOpen} recipientPhoneNumber={recipientPhoneNumber} setRecipientPhoneNumber={setRecipientPhoneNumber} />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Button variant="outline" onClick={popover.onClose}>
                Cancelar
              </Button>
              <Button
                onClick={handleCreateConversation}
                isLoading={createConversation.isLoading}
              >
                Avançar
              </Button>
            </ButtonGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverNewConversation;