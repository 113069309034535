import { Flex, Link, Switch, Tag, useToast } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTableServerPaginated from '../../../../components/DataTableServerPaginated';
import { apiRoutes } from '../../../../constants/api-routes';
import { appPaths } from '../../../../constants/app-paths';
import { useCustomerSearchParams } from '../../../../hooks/useCustomerSearchParams';
import {
  ConversationsService,
  CreateConversationDto,
} from '../../../../services/conversations.service';
import { CustomersService } from '../../../../services/customers.service';
import { setSelectedCustomerRows } from '../../../../state/campaignCreationSlice';
import { RootState } from '../../../../state/store';
import { ConversationWithIncludes } from '../../../../types/Conversation';
import { CustomerSql } from '../../../../types/CustomerSql';
import { TableHeaders } from '../../../../types/TableHeaders';
import { MoneyUtils } from '../../../../utils/money.utils';

const TableCustomersPaginated = () => {
  const navigate = useNavigate();
  const { showSelectCustomerRows, selectedCustomerRows, refetchKey } =
    useSelector((state: RootState) => state.campaignCreation);
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    selectedEngagementTemplateIds,
    searchQuery,
    minTotalPurchases,
    maxTotalPurchases,
    minAverageOrderValue,
    maxAverageOrderValue,
    minTotalOrders,
    maxTotalOrders,
    selectedEngagementActionTypes,
    startOrdersCreatedAt,
    endOrdersCreatedAt,
    minDaysSinceLastCampaign,
    sortBy,
    minAverageItemValue,
    maxAverageItemValue,
    selectedTags,
    minDaysSinceLastPurchase,
    maxDaysSinceLastPurchase,
    exactDaysSinceLastPurchase,
    excludedTags,
    isRemarketing,
    excludedTemplateIds,
    selectedProductIds,
    excludedProductIds,
    minProductQuantity,
    maxProductQuantity,
    minDaysSinceLastProductPurchase,
    maxDaysSinceLastProductPurchase,
    productNameContains,
    isLastProductPurchased,
    customFieldId1,
    customFieldValue1,
    isScheduledCampaignsVisible,
    platformOrderSource,
    selectedStates,
    selectedCities
  } = useCustomerSearchParams();

  const toggleOptOutCustomer = useMutation(
    (customerId: string) => CustomersService.toggleOptOutCustomer(customerId),
    {
      onSuccess: ({ data }) => {
        toast({
          title: 'Cliente Bloqueado',
          description: `O cliente ${data.name} foi bloqueado com sucesso.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Erro ao Bloquear Cliente(s)',
          status: 'error',
          description: 'Não foi possível realizar a operação de bloqueio.',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );
  const createConversation = useMutation(
    (createConversationDto: CreateConversationDto) =>
      ConversationsService.createConverstation(createConversationDto),
    {
      onSuccess: (res) => {
        const createdConversation: ConversationWithIncludes = res.data;
        navigate({
          pathname: appPaths.conversations(),
          search: `conversationId=${createdConversation.id}`,
        });
      },
    },
  );

  const handleClickRow = useCallback(
    async (row: CustomerSql) => {
      await createConversation.mutateAsync({
        recipientPhoneNumberId: row.phoneNumberId,
        recipientName: row.name,
      });
    },
    [createConversation],
  );

  function transformHeader(header: TableHeaders) {
    const column: ColumnDef<any> = {
      header: () => header.header,
      accessorKey: header.accessorKey,
      id: header.id,
      cell: (info: any) => info.getValue(),
    };
    if (header.accessorKey === 'name') {
      column.cell = (info: any) => {
        return (
          <Link
            onClick={() => handleClickRow(info.row.original as CustomerSql)}
          >
            {info.getValue()}
          </Link>
        );
      };
    }
    if (header.accessorKey === 'customerTags') {
      column.cell = (info: any) => {
        const customerTags: CustomerSql['customerTags'] = info.getValue();
        return (
          <Flex gap={1}>
            {customerTags?.map((customerTag) => (
              <Tag size="sm" key={customerTag}>
                {customerTag}
              </Tag>
            ))}
          </Flex>
        );
      };
    }
    if (
      ['createdAt', 'lastPurchaseAt', 'lastCampaignAt'].includes(
        header.accessorKey,
      )
    ) {
      column.cell = (info: any) => {
        const value = info.getValue();
        const displayValue = value
          ? format(new Date(value), 'dd/MM/yyyy')
          : null;
        return <span>{displayValue}</span>;
      };
    }
    if (header.accessorKey === 'isOptedOut') {
      column.cell = (info: any) => {
        return (
          <Switch
            size="md"
            defaultChecked={info.row.original.isOptedOut}
            colorScheme="red"
            onChange={async (event) => {
              await toggleOptOutCustomer.mutateAsync(info.row.original.id);
            }}
          />
        );
      };
    }
    if (
      ['averageOrderValue', 'averageItemValue', 'totalPurchases'].includes(
        header.accessorKey,
      )
    ) {
      column.cell = (info: any) => {
        const value = info.getValue() as number;
        const displayValue = value ? MoneyUtils.formatCurrency(value) : null;
        return <span>{displayValue}</span>;
      };
    }
    return column;
  }

  function handleChangeSelectedRows(selectedRows: Record<string, boolean>) {
    dispatch(setSelectedCustomerRows(selectedRows));
  }

  return (
    <DataTableServerPaginated
      showRowSelection={showSelectCustomerRows}
      dataUrl="/customers"
      headersUrl="/customers/table-headers"
      queryParameters={{
        selectedTemplateIds: selectedEngagementTemplateIds,
        selectedEngagementActionTypes,
        searchQuery,
        minTotalPurchases: String(Number(minTotalPurchases) * 100),
        maxTotalPurchases: String(Number(maxTotalPurchases) * 100),
        minAverageOrderValue: String(Number(minAverageOrderValue) * 100),
        maxAverageOrderValue: String(Number(maxAverageOrderValue) * 100),
        minAverageItemValue: String(Number(minAverageItemValue) * 100),
        maxAverageItemValue: String(Number(maxAverageItemValue) * 100),
        minTotalOrders: String(Number(minTotalOrders)),
        maxTotalOrders: String(Number(maxTotalOrders)),
        startOrdersCreatedAt,
        endOrdersCreatedAt,
        minDaysSinceLastCampaign: String(Number(minDaysSinceLastCampaign)),
        sortBy,
        selectedTags,
        minDaysSinceLastPurchase: String(Number(minDaysSinceLastPurchase)),
        maxDaysSinceLastPurchase: String(Number(maxDaysSinceLastPurchase)),
        exactDaysSinceLastPurchase: String(Number(exactDaysSinceLastPurchase)),
        excludedTags,
        isRemarketing,
        excludedTemplateIds,
        selectedProductIds,
        excludedProductIds,
        minProductQuantity: String(Number(minProductQuantity)),
        maxProductQuantity: String(Number(maxProductQuantity)),
        minDaysSinceLastProductPurchase: String(
          Number(minDaysSinceLastProductPurchase),
        ),
        maxDaysSinceLastProductPurchase: String(
          Number(maxDaysSinceLastProductPurchase),
        ),
        productNameContains,
        isLastProductPurchased,
        customFieldId1,
        customFieldValue1,
        isScheduledCampaignsVisible,
        isCreatingCampaign: String(showSelectCustomerRows),
        platformOrderSource,
        selectedStates,
        selectedCities,
      }}
      transformHeader={transformHeader}
      rowSelection={selectedCustomerRows}
      onRowSelectionChange={handleChangeSelectedRows}
      refetchKey={refetchKey}
    />
  );
};

export default TableCustomersPaginated;
