import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../../../../constants/api-routes';
import { TagsService } from '../../../../services/tags.service';
import { Controller, useForm } from 'react-hook-form';
import InputSelect from '../../../../components/InputSelect';
import {
  bulkAddTagsToCustomersDto,
  CustomersService,
} from '../../../../services/customers.service';
import { useNavigate } from 'react-router-dom';
import { appPaths } from '../../../../constants/app-paths';

interface AddTagModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUsers: Record<string, boolean>;
}

export const AddTagModal = ({
  isOpen,
  onClose,
  selectedUsers,
}: AddTagModalProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [tagOptions, setTagOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const selectedUserCount = Object.values(selectedUsers).filter(Boolean).length;
  const { control, setValue, getValues, reset } = useForm();

  useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    },
    {
      onSuccess: (data) => {
        setTagOptions(
          data.map((tag) => ({
            value: tag.id,
            label: tag.name,
          })),
        );
      },
    },
  );

  const bulkAddTagsToCustomers = useMutation(
    (bulkAddTagsToCustomersDto: bulkAddTagsToCustomersDto) =>
      CustomersService.bulkAddTagsToCustomers(bulkAddTagsToCustomersDto),
    {
      onSuccess: async (data, variables) => {
        toast({
          title: 'TAG(S) Adicionada aos Usuários',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        const selectedTags = variables.tagIds.join('%7C%7C');

        navigate({
          pathname: appPaths.customers.index(),
          search: `?selectedTags=${selectedTags}`,
        });
        onClose();
        reset();
      },
      onError: () => {
        toast({
          title: 'Erro ao adicionar TAG(S)',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      },
    },
  );

  const createTagMutation = useMutation(
    async (tag: { name: string }) => {
      const { data } = await TagsService.createTag(tag);
      return data;
    },
    {
      onSuccess: (data) => {
        const newTagOption = {
          value: data.id,
          label: data.name,
        };
        setTagOptions((prev) => [...prev, newTagOption]);
        setValue('customer[tags]', [
          ...(getValues('customer[tags]') || []),
          newTagOption,
        ]);
      },
    },
  );

  async function handleCreateTag(category: string) {
    await createTagMutation.mutateAsync({ name: category });
  }

  async function handleClickSubmit() {
    if (selectedUserCount < 1) {
      toast({
        title: 'Erro',
        description: 'Selecione pelo menos 1 usuário.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const tagIds = getValues('customer[tags]').map(
      (tag: { value: string }) => tag.value,
    );

    if (tagIds.length < 1) {
      toast({
        title: 'Erro',
        description: 'Selecione pelo menos 1 tag.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const addTagsDto: bulkAddTagsToCustomersDto = {
      userIds: Object.keys(selectedUsers).filter(
        (userId) => selectedUsers[userId],
      ),
      tagIds,
    };

    bulkAddTagsToCustomers.mutate(addTagsDto);
  }

  const isConfirmDisabled = selectedUserCount === 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text paddingBottom={4}>
            Selecione 1 ou mais tags abaixo para definir nos {selectedUserCount}{' '}
            {selectedUserCount === 1 ? 'usuário' : 'usuários'} selecionados.
          </Text>
          <Controller
            name="customer[tags]"
            control={control}
            render={({ field }) => (
              <InputSelect
                onCreateOption={handleCreateTag}
                placeholder="Digite o nome da tag ou crie"
                options={tagOptions}
                isMulti
                value={field.value}
                onChange={field.onChange}
                disabled={bulkAddTagsToCustomers.isLoading || createTagMutation.isLoading}
              />
            )}
          />
        </ModalBody>

        <ModalFooter>
          <Flex width="100%" justify="space-between" gap={4}>
            <Button
              variant="outline"
              onClick={onClose}
              disabled={bulkAddTagsToCustomers.isLoading || createTagMutation.isLoading}>
              Cancelar
            </Button>
            <Button
              isDisabled={isConfirmDisabled}
              onClick={handleClickSubmit}
              isLoading={
                bulkAddTagsToCustomers.isLoading || createTagMutation.isLoading
              }
              variant="primary"
            >
              Confirmar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
