import {
  DrawerBody,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { apiRoutes } from '../../../../../../../constants/api-routes';
import { colors } from '../../../../../../../constants/colors';
import { ConversationCategoriesService } from '../../../../../../../services/conversation-categories.service';
import { MoveConversationToCategoryNodeData } from '../../../../../../../types/ReactFlowNode';
import { ConversationCategoriesUtils } from '../../../../../../../utils/conversation-categories.utils';

const schema = yup
  .object({
    targetConversationCategoryId: yup.string(),
  })
  .required();

export interface MoveConversationToCategoryEditorProps {
  data: MoveConversationToCategoryNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const MoveConversationToCategoryEditor = ({
  data,
  formId,
  onSaveNode,
}: MoveConversationToCategoryEditorProps) => {
  const { targetConversationCategoryId } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      targetConversationCategoryId,
    },
  });
  const { data: conversationCategories } = useQuery(
    apiRoutes.listConversationCategories(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategories();
      return data;
    },
  );

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(onSaveNode)} id={formId}>
        <Flex flexDir={'column'} gap={3}>
          <FormControl>
            <FormLabel>Mover conversa para</FormLabel>
            <Select
              placeholder="Selecione uma opção"
              {...register('targetConversationCategoryId')}
            >
              {ConversationCategoriesUtils.formatAndSortCategories(conversationCategories ?? [])?.map(
                (category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ),
              )}
            </Select>
            <Text color={colors.danger} fontSize="xs">
              {errors.targetConversationCategoryId?.message}
            </Text>
          </FormControl>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default MoveConversationToCategoryEditor;
