import { Box, Button, Divider, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../constants/app-paths";
import TableAutomations from "./components/TableAutomations";
import GridAutomations from "./components/GridAutomations";

const BackgroundAutomationsPage = () => {
  const navigate = useNavigate();

  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="space-between">
        <Heading mb={5}>Envios automatizados</Heading>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <Tabs>
        <TabList>
          <Tab>Campanhas Automatizadas </Tab>
          <Tab>Automações de Integração</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
        <TableAutomations />
        </TabPanel>
        <TabPanel>
        <GridAutomations />
        </TabPanel>
      </TabPanels>
      </Tabs>
    </Box>
  );
};

export default BackgroundAutomationsPage;
