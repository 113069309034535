import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Switch,
  Textarea,
  Tabs,
  TabPanel,
  TabPanels,
  useId,
  useToast,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiRoutes } from '../../constants/api-routes';
import { ConversationCategoriesService } from '../../services/conversation-categories.service';
import {
  ConversationsService,
  UpdateConversationDto,
} from '../../services/conversations.service';
import { TagsService } from '../../services/tags.service';
import InputSelect from '../InputSelect';
import { DeleteCustomerModal } from '../../pages/CustomersPage/components/DeleteCustomerModal';
import { appPaths } from '../../constants/app-paths';
import { ConversationCategoriesUtils } from '../../utils/conversation-categories.utils';

interface DrawerEditConversationProps {
  conversationId: string;
  onClose: () => void;
}

const DrawerEditConversation = ({
  conversationId,
  onClose,
}: DrawerEditConversationProps) => {
  const toast = useToast();
  const formId = useId();
  const [tagOptions, setTagOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const { register, handleSubmit, setValue, control, getValues } = useForm();
  const deleteCustomerModal = useDisclosure();

  useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    },
    {
      onSuccess: (data) => {
        setTagOptions(
          data.map((tag) => ({
            value: tag.id,
            label: tag.name,
          })),
        );
      },
    },
  );

  const { data: conversation } = useQuery(
    apiRoutes.showConversation(conversationId!),
    async () => {
      const { data } = await ConversationsService.showConversation(
        conversationId!,
      );
      return data;
    },
    {
      enabled: !!conversationId,
    },
  );

  const { data: conversationCategories } = useQuery(
    apiRoutes.listConversationCategories(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategories();
      return data;
    },
  );

  const queryClient = useQueryClient();
  const updateConversation = useMutation(
    async (updateConversationDto: UpdateConversationDto) => {
      const { data } = await ConversationsService.updateConversation(
        updateConversationDto,
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          apiRoutes.showConversation(conversationId!),
        );
        toast({
          title: 'Conversa salva com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      },
    },
  );
  const createTagMutation = useMutation(
    async (tag: { name: string }) => {
      const { data } = await TagsService.createTag(tag);
      return data;
    },
    {
      onSuccess: (data) => {
        const newTagOption = {
          value: data.id,
          label: data.name,
        };
        setTagOptions((prev) => [...prev, newTagOption]);
        setValue('customer[tags]', [
          ...getValues('customer[tags]'),
          newTagOption,
        ]);
      },
    },
  );

  useEffect(() => {
    const setFormValues = () => {
      if (!conversation) return;
      const customerTags =
        conversation?.customer?.customerTags.map((customerTag) => ({
          value: customerTag.tag.id,
          label: customerTag.tag.name,
        })) || [];

      const formValues = {
        'conversation[recipientPhoneNumberId]':
          conversation?.recipientPhoneNumberId || '',
        'conversation[recipientName]': conversation?.recipientName || '',
        'conversation[categoryId]': conversation?.categoryId || '',
        'customer[tags]': customerTags,
        'customer[email]': conversation?.customer.email || '',
        'customer[isOptedOut]': conversation?.customer.isOptedOut || false,
        'customer[notes]': conversation?.customer.notes || '',
      };

      Object.entries(formValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    };

    setFormValues();
  }, [conversation, setValue]);

  async function onSubmit(data: any) {
    const { conversation, customer } = data;
    const { tags, ...updateCustomerData } = customer;

    await updateConversation.mutateAsync({
      conversationId: conversationId!,
      conversation,
      customer: {
        ...updateCustomerData,
        tagIds: tags.map((tag: any) => tag.value),
      },
    });
  }

  async function handleCreateTag(category: string) {
    await createTagMutation.mutate({ name: category });
  }

  const selectedUser: Record<string, boolean> = {
    [conversation?.customer?.id!]: true,
  };

  return (
    <Tabs>
      <TabPanels>
        <TabPanel>
          <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
            justifyContent="space-between"
          >
            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
              <Stack spacing={5} flex="1" overflowY="auto">
                <FormControl>
                  <FormLabel>Nome</FormLabel>
                  <Input
                    placeholder="Nome"
                    {...register('conversation[recipientName]')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Categoria da conversa</FormLabel>
                  <Select
                    placeholder="Selecione a categoria"
                    {...register('conversation[categoryId]')}
                    mb={3}
                  >
                    {ConversationCategoriesUtils.formatAndSortCategories(conversationCategories ?? [])?.map(
                      (category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ),
                    )}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <Controller
                    name="customer[tags]"
                    control={control}
                    render={({ field }) => (
                      <InputSelect
                        onCreateOption={handleCreateTag}
                        placeholder="Adicionar tags"
                        options={tagOptions}
                        isMulti
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Anotações</FormLabel>
                  <Textarea
                    placeholder="Anotações"
                    {...register('customer[notes]')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Bloqueado?</FormLabel>
                  <Switch
                    {...register('customer[isOptedOut]')}
                    colorScheme="red"
                    defaultChecked={conversation?.customer?.isOptedOut ?? false}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Telefone</FormLabel>
                  <Input
                    placeholder="Telefone"
                    disabled={true}
                    {...register('conversation[recipientPhoneNumberId]')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Email" {...register('customer[email]')} />
                </FormControl>

                <FormControl>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={deleteCustomerModal.onOpen}
                    width="full"
                    mt={4}
                  >
                    Deletar Cliente
                  </Button>
                </FormControl>
              </Stack>
            </form>

            <Box
              padding="20px"
              bg="white"
              position="sticky"
              bottom="0"
              width="100%"
            >
              <Flex justify="space-between">
                <Button variant="outline" onClick={onClose} minWidth="120px">
                  Cancelar
                </Button>
                <Button
                  bg="black"
                  color="white"
                  form={formId}
                  type="submit"
                  isLoading={updateConversation.isLoading}
                  minWidth="120px"
                >
                  Salvar
                </Button>
              </Flex>
            </Box>
          </Box>
        </TabPanel>
      </TabPanels>
      <DeleteCustomerModal
        isOpen={deleteCustomerModal.isOpen}
        onClose={deleteCustomerModal.onClose}
        selectedUsers={selectedUser}
        redirectTo={appPaths.conversations()}
      />
    </Tabs>
  );
};

export default DrawerEditConversation;
