import {
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import {
  AutomationFormValues,
  AutomationsService,
  UpdateAutomationDto,
} from "../../../../services/automations.service";
import { Automation } from "../../../../types/Automation";
import { CronUtils } from "../../../../utils/cron.utils";
import AutomationForm from "../components/AutomationForm";
import { ListMessageTemplateItem, MessageTemplatesService } from "../../../../services/message-templates.service";
import LoadingScreen from "../../../../components/LoadingScreen";

const EditBackgroundAutomationPage = () => {
  const { automationId } = useParams();
  const [automationValues, setAutomationValues] = useState<AutomationFormValues | null>(null);


  function mapAutomation(automation: Automation): AutomationFormValues | null {
    const cronValues = CronUtils.getValuesFromCronExpression(automation.cronExpression);
    if (!cronValues) return null;

    return {
      name: automation.name,
      automationType: automation.automationType,
      isActive: automation.isActive,
      messageTemplateId: automation.messageTemplateId,
      filterId: automation.filterId!,
      dailyMessageLimitOnWhatsapp: automation.dailyMessageLimitOnWhatsapp,
      isAutomationRepetitionAllowed: automation.isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign: automation.minDaysSinceLastCampaign,
      action: automation.action,
      flowId: automation.flowId,
      ...cronValues,
    };
  }

  function processData(data: any) {
    if (data.templateArgs) {
      setTemplateArgs(data.templateArgs);
    }

    return setAutomationValues(mapAutomation(data));
  }

  useQuery(
    apiRoutes.showAutomation(automationId!),
    async () => {
      const { data } = await AutomationsService.showAutomation(automationId!);
      return data;
    },
    {
      onSuccess: (data) => {
        processData(data);
      },
    }
  )

  const navigate = useNavigate();
  const toast = useToast();

  const updateAutomation = useMutation(
    (createAutomationDto: UpdateAutomationDto) =>
      AutomationsService.updateAutomation(automationId!, createAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Automação atualizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      }
    }
  );

  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  const { data: templates, isFetching: isFetchingMessageTemplates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => template.status === 'approved',
        );
      },
    },
  );

  async function onSubmit(data: any) {
    const {
      name,
      isActive,
      messageTemplateId,
      filterId,
      dailyMessageLimitOnWhatsapp,
      frequency,
      minutesOfTheDay,
      daysOfWeek,
      daysOfMonth,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
      action,
      flowId,
    } = data;
    const hours = Math.floor(Number(minutesOfTheDay) / 60);
    const minutes = Number(minutesOfTheDay) % 60;

    await updateAutomation.mutateAsync({
      name,
      daysOfMonth: daysOfMonth?.filter(Boolean).map(Number) || [],
      daysOfWeek: daysOfWeek?.filter(Boolean).map(Number) || [],
      filterId,
      frequency,
      isActive,
      messageTemplateId,
      dailyMessageLimitOnWhatsapp,
      hours,
      minutes,
      templateArgs,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
      action,
      flowId,
    });
  }

  return (
    <>
      {automationValues && (
        <LoadingScreen isLoading={isFetchingMessageTemplates}>
        <AutomationForm
          onSubmit={onSubmit}
          templateArgs={templateArgs}
          setTemplateArgs={setTemplateArgs}
          automation={automationValues}
          templates={templates!}
        />
        </LoadingScreen>
      )}
    </>
  );
};

export default EditBackgroundAutomationPage;
