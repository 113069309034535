import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  Textarea,
  VStack,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { MessageTemplate } from '../../types/MessageTemplate';
import ImageDropzone from '../../components/ImageDropzone';
import { useEffect, useMemo, useRef, useState } from 'react';
import useFileDropzone from '../useFileDropzone';
import FileInputItem from '../../components/FileInputItem/FileInputItem';
import {
  areFilesSameMainType,
  getFileMainType,
} from '../../utils/file-validation.utils';

interface DirectDeliveryFormProps {
  selectedTemplate: MessageTemplate;
  templateParams: string[];
  templateArgs: { [key: string]: string | undefined };
  handleChangeTemplateParameter: (parameter: string, value: string) => void;
  dropzonePreview: ReturnType<typeof useFileDropzone>['preview'];
  dropzone: ReturnType<typeof useFileDropzone>['dropzone'];
  onCustomMediaChange: (File: File) => void;
  media: File | null;
  mediaType: string | null;
  isCustomMedia: boolean;
  resetMedia: () => void;
  isUploadingMedia?: boolean;
}

export function DirectDeliveryForm({
  selectedTemplate,
  templateParams,
  templateArgs,
  handleChangeTemplateParameter,
  dropzonePreview,
  dropzone,
  onCustomMediaChange,
  media,
  mediaType,
  isCustomMedia,
  resetMedia,
  isUploadingMedia = false,
}: DirectDeliveryFormProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function handleChange(param: string, e: any) {
    let value = e.target.value;
    if (value.includes('\n')) {
      value = value.replace(/[\r\n]+/gm, ' ');
    }

    value = value.replace(/^\s+/, '');

    handleChangeTemplateParameter(param, value);
  }

  const mainMediaType = useMemo(() => {
    if (!mediaType) return '';
    return getFileMainType(mediaType);
  }, [mediaType]);

  useEffect(() => {
    if (!isCustomMedia && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [isCustomMedia]);

  return (
    <VStack gap={2}>
      {selectedTemplate!.mediaUrl && (
        <FormControl>
          <FormLabel>Mídia</FormLabel>
          {mediaType &&
            (areFilesSameMainType(mediaType, 'image') ? (
              <>
                <ImageDropzone
                  dropzone={dropzone}
                  preview={
                    dropzonePreview
                      ? (dropzonePreview as string)
                      : selectedTemplate.mediaUrl
                  }
                />
                {isCustomMedia && <Link onClick={resetMedia}>Desfazer</Link>}
              </>
            ) : (
              <Flex flexDirection="column">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => onCustomMediaChange(e.target.files![0])}
                  accept={mainMediaType}
                />
                {isUploadingMedia ? (
                  <Box pt={2}>
                    <Spinner size="xs" />
                  </Box>
                ) : (
                  media && (
                    <FileInputItem
                      file={media}
                      onRemove={isCustomMedia ? resetMedia : undefined}
                    />
                  )
                )}
              </Flex>
            ))}
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Mensagem</FormLabel>
        <Card>
          <CardHeader pb="1">
            <Text fontWeight={'bold'}>{selectedTemplate.name}</Text>
          </CardHeader>
          <CardBody pt="1">{selectedTemplate.templateText}</CardBody>
        </Card>
      </FormControl>
      <VStack w="100%">
        {templateParams.map((param) => (
          <FormControl isRequired key={param}>
            <FormLabel>
              {param.replaceAll('[', '').replaceAll(']', '')}
            </FormLabel>
            {param.includes('texto personalizado') ? (
              <Textarea
                onChange={(e) => handleChange(param, e)}
                value={templateArgs[param] || ''}
                required
              />
            ) : (
              <Input
                onChange={(e) => handleChange(param, e)}
                value={templateArgs[param] || ''}
                required
              />
            )}
          </FormControl>
        ))}
      </VStack>
    </VStack>
  );
}
