import {
  Button,
  Flex,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { apiRoutes } from "../../../../../constants/api-routes";
import { appPaths } from "../../../../../constants/app-paths";
import { colors } from "../../../../../constants/colors";
import { AutomationsService, ListAutomationItem } from "../../../../../services/automations.service";
import { CronUtils } from "../../../../../utils/cron.utils";

const TableAutomations = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: automations } = useQuery(
    apiRoutes.listAutomations(),
    async () => {
      const { data } = await AutomationsService.listAutomations();
      return data.filter((automation: ListAutomationItem) => automation.automationType?.slug === "custom");
    }
  );
  const toggleAutomationMutation = useMutation(
    async (automationId: string) => {
      const { data } = await AutomationsService.toggleAutomation(automationId);
      return data;
    },
    {
      onSuccess: (data) => {
        toast({
          title: "Automação atualizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        queryClient.setQueryData(apiRoutes.listAutomations(), (old: any) => {
          return old?.map((automation: any) => {
            if (automation.id === data.id) {
              automation.isActive = data.isActive;
            }
            return automation;
          });
        });
      },
    }
  );

  return (
    <TableContainer>
      <Flex justify="end" paddingBottom={4}>
      <Button
        variant="primary"
        onClick={() =>
          navigate(
            appPaths.automations.backgroundAutomations.createAutomation()
          )
        }>
        + criar automação
      </Button>
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Template / Fluxo</Th>
            <Th>Filtro</Th>
            <Th>Frequência</Th>
            <Th>Limite diário</Th>
            <Th>Ativa?</Th>
            <Th textAlign={"center"}>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {automations?.map((automation, index) => {
            const cronLabels = CronUtils.getLabeledValuesFromCronExpression(
              automation.cronExpression
            );
            return (
              <Tr key={automation.id}>
                <Td fontWeight="bold">{automation.name}</Td>
                <Td>{automation?.messageTemplate?.name || automation?.flow?.title}</Td>
                <Td>{automation.filter?.name}</Td>
                <Td>
                  {cronLabels && (
                    <Text noOfLines={1} maxW={"300px"} display="block">
                      <b>Frequência: </b>
                      {cronLabels.frequency}
                      <br />
                      {cronLabels.frequency === "semanal" && (
                        <div>
                          <b>Dias da semana: </b>
                          {cronLabels.daysOfWeek}
                          <br />
                        </div>
                      )}
                      {cronLabels.frequency === "mensal" && (
                        <div>
                          <b>Dias do mês: </b>
                          {cronLabels.daysOfMonth}
                          <br />
                        </div>
                      )}
                      <b>Hora: </b>
                      {cronLabels.hours}:{cronLabels.minutes}
                    </Text>
                  )}
                </Td>
                <Td>{automation.dailyMessageLimitOnWhatsapp}</Td>
                <Td>
                  <Switch
                    isChecked={automation.isActive}
                    colorScheme="green"
                    onChange={async (event) => {
                      await toggleAutomationMutation.mutateAsync(automation.id);
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <ButtonIcon
                    tooltipLabel="Ver resultados"
                    icon={
                      <MdOutlineRemoveRedEye
                        color={colors.primaryLight}
                        size={20}
                      />
                    }
                    onClick={() =>
                      navigate(
                        appPaths.automations.backgroundAutomations.details(
                          automation.id
                        )
                      )
                    }
                  />
                  <ButtonIcon
                    tooltipLabel="Editar"
                    icon={
                      <FaEdit
                        color={colors.primaryLight}
                        size={18}
                      />
                    }
                    onClick={() =>
                      navigate(
                        appPaths.automations.backgroundAutomations.editAutomation(
                          automation.id
                        )
                      )
                    }
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableAutomations;
