import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../../../../constants/api-routes';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { colors } from '../../../../constants/colors';
import {
  IntegrationsService,
  SaveLinxCommerceCredentialsDto,
} from '../../../../services/integrations.service';

const schema = yup.object({
  linxCommerceAccessToken: yup
    .string()
    .required("'Access Token' é obrigatório"),
});

const LinxCommerceIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const { data: linxCommerceConfig, refetch: refetchLinxCommerceConfig } =
  //   useQuery(apiRoutes.getLinxCommerceConfig(), async () => {
  //     const { data } = await IntegrationsService.getLinxCommerceConfig();
  //     return data;
  //   });

  const saveLinxCommerceCredentials = useMutation(
    (data: SaveLinxCommerceCredentialsDto) =>
      IntegrationsService.saveLinxCommerceCredentials(data),
    {
      onSuccess: () => {
        toast({
          title: 'Configurações salvas com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  async function onSubmit(data: any) {
    await saveLinxCommerceCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Access Token</FormLabel>
            <Input
              placeholder="******************"
              type="password"
              {...register('linxCommerceAccessToken')}
              isInvalid={errors.linxCommerceAccessToken?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.linxCommerceAccessToken?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveLinxCommerceCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default LinxCommerceIntegrationPage;
