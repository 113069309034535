import { PaginatedResponse } from './../types/PaginatedResponse.d';
import { AxiosResponse } from 'axios';
import { apiRoutes } from '../constants/api-routes';
import { request } from '../constants/request';
import { CustomerWithIncludes } from '../types/Customer';
import { EngagementActionsEnum } from '../types/EngagementActionsEnum';
import { FormDataUtils } from '../utils/form-data.utils';
import { TableHeaders } from '../types/TableHeaders';
import { Order } from '../types/Order';

export interface UploadCustomersDto {
  file: File;
  mappedHeaders: {
    name?: string;
    birthDate?: string;
    email?: string;
    phoneNumberId?: string;
    tags?: string;
  };
}

const uploadCustomers = async (uploadCustomersDto: UploadCustomersDto) => {
  const { file, mappedHeaders } = uploadCustomersDto;
  const data = FormDataUtils.convertJsonToFormData({
    file,
    ...mappedHeaders,
  });

  return request.post(apiRoutes.uploadCustomers(), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 600000,
  });
};

export interface bulkAddTagsToCustomersDto {
  userIds: string[];
  tagIds: string[];
}

const bulkAddTagsToCustomers = async (
  bulkAddTagsToCustomersDto: bulkAddTagsToCustomersDto,
) => {
  return request.post(
    apiRoutes.bulkAddTagsToCustomers(),
    bulkAddTagsToCustomersDto,
  );
};

export interface ListCustomersParams {
  excludedTemplateIds?: string | null;
  selectedTemplateId?: string | null;
  selectedEngagementActionTypes?: EngagementActionsEnum | null | '';
  page?: number | null;
  perPage?: number | null;
}

export interface ListCustomersStates {
  states: string[];
}

export interface DeleteAndBlockCustomers {
  customerIds: string[];
  blockCustomers: boolean;
}

const listCustomers = async (
  listCustomerParams: ListCustomersParams,
): Promise<AxiosResponse<PaginatedResponse<CustomerWithIncludes>>> => {
  return request.get(apiRoutes.listCustomers(listCustomerParams), {
    timeout: 600000,
  });
};

const toggleOptOutCustomer = async (customerId: string) => {
  return request.put(apiRoutes.toggleOptOutCustomer(customerId));
};

const deleteCustomers = async (
  deleteAndBlockCustomers: DeleteAndBlockCustomers,
) => {
  return request.post(apiRoutes.deleteCustomers(), deleteAndBlockCustomers);
};

const listCustomerTableHeaders = async (): Promise<
  AxiosResponse<TableHeaders[]>
> => {
  return request.get(apiRoutes.listCustomerTableHeaders());
};

const listOptedOutCustomers = async (): Promise<
  AxiosResponse<{ customerIds: string[] }>
> => {
  return request.get(apiRoutes.listOptedOutCustomers());
};

const listCustomerStates = async (): Promise<
  AxiosResponse<ListCustomersStates>
> => {
  return request.get(apiRoutes.listCustomerStates());
};

const listCustomerCities = async (
  search?: string,
): Promise<AxiosResponse<{ cities: string[] }>> => {
  return request.get(apiRoutes.listCustomerCities(search));
};

const listOrdersHistoryByCustomer = async (
  customerId: string,
): Promise<AxiosResponse<Order[]>> => {
  return request.get(apiRoutes.listOrdersHistoryByCustomer(customerId));
};

export const CustomersService = {
  uploadCustomers,
  bulkAddTagsToCustomers,
  listCustomers,
  toggleOptOutCustomer,
  deleteCustomers,
  listCustomerTableHeaders,
  listOptedOutCustomers,
  listCustomerStates,
  listCustomerCities,
  listOrdersHistoryByCustomer,
};
