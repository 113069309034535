import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { IoCartOutline } from "react-icons/io5";
import { RiNodeTree } from "react-icons/ri";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../../../constants/app-paths";
import { colors } from "../../../../../constants/colors";
import { FlowsService } from "../../../../../services/flows.service";

interface createFlowModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateFlowModal = ({ isOpen, onClose }: createFlowModalProps) => {
  const navigate = useNavigate();

  const flowTypes = [
    {
      id: 1,
      title: 'Padrão',
      description: 'Personalize a jornada de atendimento do seu cliente',
      onClick: () => createFlowMutation.mutateAsync('default'),
      icon: <RiNodeTree size={48} color={colors.primaryMedium}/>,
    },
    {
      id: 2,
      title: 'Carrinho Abandonado',
      description: 'Personalize a jornada de carrinho abandonado',
      onClick: () => createFlowMutation.mutateAsync('abandoned_cart'),
      icon: <IoCartOutline size={48} color={colors.chartColors.lightGreen.primary}/>
    },
  ]

  const createFlowMutation = useMutation(
    async (type: 'default' | 'abandoned_cart') => {
      const { data } = await FlowsService.createFlow({
        title: "Sem título",
        type: type,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        navigate(appPaths.automations.messageFlows.editMessageFlow(data.id));
      },
    }
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Criação de Fluxo
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {flowTypes.map((block) => (
            <Box
              key={block.id}
              as="button"
              onClick={block.onClick}
              width="100%"
              minHeight={110}
              textAlign="left"
              p={4}
              mb={4}
              borderRadius="md"
              boxShadow="sm"
              _hover={{ bg: "gray.100" }}
            >
              <Flex>
                <Flex mr={4} alignItems="center">
                  {block.icon}
                </Flex>
                <Flex direction="column" justifyContent="center" flex="1">
                  <Text fontWeight="bold" fontSize="lg">
                    {block.title}
                  </Text>
                  <Text mt={2} fontSize="sm" color="gray.600">
                    {block.description}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
